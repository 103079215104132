import {FC, useEffect, useState} from "react";

import './style.scss';
import SelectSearch, {ISelectSearchList} from "../../ui/SelectSearch";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {clearNewTags, newTags, setNewTags, Tag, tags} from "../../../redux/slices/tags";
import {trans} from "../../../_locales";
import {isValueObjectInArray} from "../../../utils";
import iconClose from '../../../assets/icons/icon-close.svg';
import Input from "../../../ui/Input";
import Btn from "../../../ui/Btn";
import InputValuesAdd from "./Add";


interface IInputValues {
    name: string,
    value: string[],
    label?: string,
    tooltip?: string,
    isRequired?: boolean,
    errorLabel?: string,
    beta?: boolean,
    placeholder?: string
    isInvalid?:boolean
    campaignId?:number,
    cb: (data: string[]) => void,
    isValidValue?: (value: string) => boolean,
}

const InputValues:FC<IInputValues> = ({
    name,
    value,
    label,
    tooltip,
    placeholder,
    isInvalid,
    isRequired,
    errorLabel,
    beta,
    campaignId,
    isValidValue = (value: string) => true,
    cb = (res: string[]) => null
}) => {

    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);
    // const reduxTags = useAppSelector(tags);
    // const reduxActiveTags = useAppSelector(newTags);

    useEffect(()=> {
        if(Array.isArray(value)) {
            // dispatch(setNewTags(value))
            setWrapper(value);
        }
    }, [value])

    const [wrapper, setWrapper] = useState<string[]>([])

    // useEffect(()=> {
    //     setWrapper(reduxTags)
    // }, [reduxTags])

    // useEffect(()=> {
    //     if(reduxActiveTags.length) {
    //         setWrapperActive(reduxActiveTags)
    //         dispatch(clearNewTags())
    //     }
    // }, [reduxActiveTags])

    const addTag = (name:string, id?:number) => {
        if(name && isValidValue(name)) {
            if(wrapper.includes(name)) return;
            const temp = [...wrapper];
            temp.push(name)
            cb(temp);
            return;
        }
    }

    const removeActiveTag = (name:string) => {
        const temp = [...wrapper];
        const item = temp.find((tag) => tag  === name);
        const index = item ? temp.indexOf(item) : -1;
        if(index > -1) {
            temp.splice(index, 1);
            cb(temp);
            // setWrapper(temp);
        }
    }


    return(
        <div className='input-values'>
            <InputValuesAdd
                name={name}
                value={''}
                label={label}
                tooltip={tooltip}
                placeholder={placeholder}
                isInvalid={isInvalid}
                required={isRequired}
                errorLabel={errorLabel}
                wrapper={wrapper}
                beta={beta}
                actionBtn={{
                    onClick: addTag,
                    text: trans('Add', language) + ' - '
                }}
                isValidValue={isValidValue}
                maxWrapperCount={5}
            />
            {wrapper.length ? <ul className="input-values__list">
                {
                    wrapper.map((item, index) => {
                        return (
                            <li key={`${item}_${index}`}>
                                <span>{item}</span>
                                <button onClick={() => removeActiveTag(item)} type='button'>
                                    <img src={iconClose} alt="Close"/>
                                </button>
                            </li>
                        )
                    })
                }
            </ul> : ''}
        </div>
    )
}

export default InputValues;

/*
* Добавить разрядность (сек)
* Максимальное число
* Добыть мета инфу видео в js
* Максимальное кол-во других трекеров в каждом собьтиые <= 5
* Доделать js viewability (Добавить метрики такие же как для видео, кастомный вьабилити
* Расширить возможность кастомного viewability
*   Процент от проигрывания видео
*
*
* */