import {FC, useState} from "react";
import {Btn} from "../../../ui";
import {trans} from "../../../_locales";
import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {ReactComponent as UploadIcon} from '../../../assets/icons/upload.svg';
import iconDocument from "../../../assets/icons/document.svg";
import iconClose from "../../../assets/icons/icon-close.svg";
import './style.scss';
import cx from "classnames";


interface IUploadFile {
    className?: string,
    title?: string,
    text?: string,
    textPreview?: string,
    isDisabled?: boolean,
    isMultiple?: boolean,
    maxFileSizeInBytes?: number,
    onChange?: (file:any)=>void,
    onRemove?: ()=>void,
    uploadedFilename?: string,
    accept?: string,
    fileType: 'image' | 'video' | 'txt',
    isInvalid?: boolean,
    errorText?: string
}

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;


const UploadFile:FC<IUploadFile> = ({
    className,
    title,
    text,
    textPreview,
    isDisabled,
    maxFileSizeInBytes=DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    isMultiple,
    onChange=(file: any)=>null,
    onRemove=()=>null,
    uploadedFilename,
    fileType,
    isInvalid,
    errorText
}) => {
    const language = useAppSelector(currentUserLang);
    const [files, setFiles] = useState<any>({});
    const [file, setFile] = useState<any>();

    const mainClassName = cx(
        'upload-file',
        className,
        {
            'upload-file--invalid': isInvalid
        }
    );

    const accepts = {
        'csv': '.csv',
        'xlsx': 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image': 'image/*',
        'video': 'video/mp4',
        'txt': 'text/plain'
    }

    const addNewFiles = (newFiles:any) => {
        for (const file of newFiles) {
          if (file.size <= maxFileSizeInBytes) {
            if (!isMultiple) {
              return { file };
            }
            files[file.name] = file;
          }
        }
        return { ...files };
    };

    const convertNestedObjectToArray = (nestedObj:any) =>
        Object.keys(nestedObj).map((key) => nestedObj[key]);

    const callUpdateFilesCb = (files:any) => {
        const filesAsArray = convertNestedObjectToArray(files);
        setFile(filesAsArray);
    };

    const handleNewFileUpload = (e:any) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
          const updatedFiles = addNewFiles(newFiles);
          setFiles(updatedFiles);
          callUpdateFilesCb(updatedFiles);
          onChange(updatedFiles);
        }
    };

    return (
        <div className={mainClassName}>
            {(!uploadedFilename) ? <div className='upload-file-drag'>
                {title && <p className='upload-file-drag__title'>{title}</p>}
                <Btn
                    text={text}
                    icon={{
                        Component: UploadIcon,
                        width: 12,
                        height: 12,
                    }}
                    color='light-blue'
                    size='md'
                    isDownloadBtn={true}
                    onFileChosen={handleNewFileUpload}
                    disabled={isDisabled}
                    fileType={fileType}
                />
                <span className='upload-file-drag__text'>{trans('Or drag it to this area', language)}</span>
                <input
                    className='upload-file-drag__formUpload'
                    type="file"
                    accept={accepts[fileType]}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    multiple
                    disabled={isDisabled}
                />
            </div>
            :
            <div className='upload-file-preview'>
                {textPreview && <p>{textPreview}</p>}
                <div className='upload-file-preview__block'>
                    <img src={iconDocument} alt=""/>
                    <span>{(file && file[0] && file[0].name) || uploadedFilename}</span>
                    <button onClick={() => {
                        setFile(undefined);
                        setFiles({});
                        onRemove();
                    }}><img src={iconClose} alt=""/></button>
                </div>
            </div>}
            {isInvalid && errorText && <p className={'upload-file-error'}>{errorText}</p>}
        </div>
    )
}

export default UploadFile;