export const ru = {
    //Header
    'Go to the main page': 'Переход на главную',
    //Breadcrumbs
    'Create project': 'Новый проект',
    'All projects': 'Все проекты',
    'Campaigns': 'Кампании',

    //Side menu
    'Home': 'Домашняя проекта',
    'Media campaigns': 'Медийные кампании',
    'Placements': 'Креативы',
    'Reports': 'Отчетность',
    'Data upload': 'Загрузка данных',
    'Events': 'Целевые события',
    'Settings': 'Настройки',
    'Profile': 'Профиль',
    'Projects': 'Проекты',

    //Profile
    'General information':'Основная информация',
    'Name': 'Имя',
    'Change name': 'Изменить имя',

    'Change E-mail': 'Изменить E-mail',
    'New E-mail': 'Новый E-mail',

    'Timezone': 'Часовой пояс',
    'Change timezone': 'Изменить часовой пояс',

    'Language': 'Язык',
    'Change language': 'Изменить язык',

    'Security': 'Безопасность',
    'Password': 'Пароль',
    'Change password': 'Изменить пароль',
    'Current password': 'Текущий пароль',
    'Your password': 'Ваш пароль',
    'New password': 'Новый пароль',
    'Repeat password': 'Подтвердите пароль',
    'Forgot password?': 'Забыли пароль?',

    'Last activity': 'Последняя активность',
    'Terminate all other session': 'Завершить все другие сеансы',

    'Timezone has been updated': 'Часовой пояс успешно обновлен',
    'Language has been updated': 'Язык успешно обновлен',
    //Buttons
    'Cancel':'Отменить',
    'Clear':'Очистить',
    'Reset':'Сбросить',
    'Apply':'Применить',
    'Save':'Сохранить',
    'Invite':'Пригласить',
    'Add':'Добавить',
    'Delete':'Удалить',
    'Leave':'Покинуть',
    'Exit':'Выйти',
    'Copy':'Копировать',
    'Copied':'Скопировано',
    'Create':'Создать',
    'Update':'Обновить',
    'Next step':'Следующий шаг',
    'Next':'Далее',
    'Check':'Проверить',
    'Skip':'Пропустить',
    'Done':'Завершить',
    'Change':'Изменить',
    'Download':'Скачать',
    'Download data': 'Выгрузить',
    'Download pixels':'Скачать пиксели',
    'Download template':'Скачать шаблон',
    'Download Templates':'Скачать шаблоны',
    'Ok':'Ок',
    'Save changes':'Сохранить изменения',
    'Upload your file':'Загрузите ваш файл',
    'Retry':'Повторить',
    'Upload Excel': 'Загрузить Excel',
    'Download in CSV': 'Скачать в формате CSV',
    'Download in Excel': 'Скачать в формате Excel',
    'Upload': 'Загрузить',
    'Select all': 'Выбрать все',
    "Doesn't contain": 'Не содержит',
    "Doesn't contain empty": 'Не содержит пустыx',
    "Clear filters": 'Очистить фильтры',
    'Confirm': 'Подтвердить',
    'Back': 'Назад',
    'Create tag': 'Создать тег',
    'Create new token': 'Создать новый токен',
    'Create new role': 'Создать новую роль',
    'Delete project': 'Удалить проект',
    'Active the project': 'Активировать проект',
    'Archive the project': 'Архивировать проект',
    'Minimize': "Свернуть",
    'Fullscreen': "Развернуть",
    'Change status': 'Изменить статус',

    //Errors
    'Empty password': 'Введите пароль',
    'Enter the password from 6 to 50 characters': 'Введите пароль от 6 до 50 знаков',
    'Enter the correct password': 'Введите правильный пароль',
    'Enter the correct E-mail': 'Введите корректный адрес почты',
    'Enter the correct url': 'Введите корректную ссылку',
    'Enter the correct date': 'Введите корректную дату',
    'Enter the correct domain': 'Введите корректный домен',
    'The field is required': 'Поле обязательно',
    'User already exists': 'Пользователь существует',
    'E-mail or password is invalid': 'Неправильно введен E-mail или пароль',
    'We do not know such E-mail': 'Такого E-mail мы не знаем',
    'Api tokens was not found': 'Токены аутентификации не были найдены',
    'This token already exist in this project': 'Этот токен уже существует в этом проекте',
    'Specified token was not found': 'Указанный токен не найден',
    "Can`t connect": 'Не удается подключиться',
    'Token already exist': 'Токен с таким именем уже существует',
    'The token was created': 'Токен успешно создан',
    'The token was updated': 'Токен обновлен',
    'The token was deleted': 'Токен удален',
    'Role already exists': 'Роль с таким именем уже существует',
    'The role was created': 'Роль успешно создана',
    'The role was updated': 'Роль обновлена',
    'The role was deleted': 'Роль удалена',
    'Incorrect placement id': 'Некорректный креатив',
    'Confirm the deletion of the creatives': 'Подтвердить удаление креативов',
    'Confirm the deletion of the campaigns': 'Подтвердить удаление кампаний',
    'Confirm archiving of the project': 'Подтвердите архивацию проекта',
    'Confirm the activation of the project': 'Подтвердите активацию проекта',
    'Something went wrong': 'Что-то пошло не так',
    'Cannot delete campaign as it has dependant records': 'Не удалось удалить кампанию с креативами',
    'Cannot delete campaigns as it has dependant records. Confirmation is needed to delete campaigns with its dependencies': 'Невозможно удалить кампании, поскольку у них есть зависимые записи. Для удаления кампаний с их зависимостями требуется подтверждение',
    'Cannot delete campaigns as it has dependant records.': 'Невозможно удалить кампании, поскольку у них есть зависимые креативы.',
    'Confirmation is needed to delete campaigns': 'Для удаления кампаний требуется подтверждение',
    'Are you sure you want to delete all creatives from campaigns': 'Вы уверены, что хотите удалить кампании, у которых есть креативы? Список кампаний',
    'Are you sure you want to delete a campaign with its creatives?': 'Вы уверены, что хотите удалить кампанию вместе с ее креативами?',
    'Do you want to delete sources that are not linked to campaigns?': 'Хотите ли удалить источники, которые не привязаны к кампаниям?',
    'List of sources': 'Список источников',
    'Such campaign was not found': 'Кампания не найдена',
    'Some campaign was not found': 'Кампания не найдена',
    'Could not delete tags': 'Не удалось удалить теги',
    'Could not DELETE landing page url': 'Не удалось удалить посадочную страницу',
    'If the value is left empty, then multiple changes will not be applied to this field': 'Если значение оставить пустым, то к этому полю не будет применено множественное изменение',
    'Project not found': 'Проект не найден',
    'Error to delete source': 'Ошибка при удалении источника',
    'The source has dependent campaigns': 'У источника есть зависимые кампании',
    'Confirmation of leaving the page': 'Подтверждение ухода со страницы',

    //Naming
    'Naming': 'Название',

    //Source modal
    'Domain': 'Домен',
    'Domains': 'Домены',
    'The name of the source will be displayed in reports and filters, for convenient grouping of campaigns and comparison among themselves': 'Название источника будет отображаться в отчетах и фильтрах, для удобной группировки кампаний и сравнения между собой',
    'Parameters per click': 'Параметры на клик',
    'Parameters per impression': 'Параметры на показ',
    'Dynamic parameters, in the format param={value}&param2={value2}, which will be inserted into all click links from this source. Macros from advertising systems are added to this field': 'Динамические параметры, в формате param=value&param2=value2, которые будут подставляться во все ссылки на клик у этого источника. В это поле добавляются макросы из рекламных систем',
    'Dynamic parameters, in the format param={value}&param2={value2}, which will be substituted in all pixels for impression at this source. Macros from advertising systems are added to this field': 'Динамические параметры, в формате param=value&param2=value2, которые будут подставляться во все пиксели на показ у этого источника. В это поле добавляются макросы из рекламных систем',

    //Project
    'Website address': 'Адрес сайта',
    'Product Name': 'Название продукта',

    //Campaign
    'Campaign name': 'Название кампании',
    'Create new campaign': 'Создать кампанию',
    'Source': 'Источник',
    'Campaign ID in the advertising system': 'ID кампании в рекламной системе',
    'Your advertising source, for example, Yandex, myTarget or Hybrid': 'Ваш рекламный источник, например, Яндекс, MyTarget или Hybrid',
    'Select the source': 'Выберите источник',
    'Campaign type': 'Тип кампании',
    'Select the type': 'Выберите тип',
    'Without preset': 'Без пресета',
    'Start date': 'Дата Старта',
    'It is necessary exclusively for reports, does not affect the functionality': 'Нужно исключительно для отчетов, не влияет на функционал',
    'End date': 'Дата Окончания',
    'Purchase type': 'Тип закупки',
    'Purchase tooltip': 'CPA - это оплата за действия. \n' + 'CPC - оплата за клики. \n' + 'CPV - оплата за просмотры. \n' + 'FIX - фиксированная оплата.',
    'Description': 'Описание',
    'Planned indicators': 'Плановые показатели',
    'Impressions': 'Показы',
    'Clicks': 'Клики',
    'Budget': 'Бюджет',
    'The source is an advertising system, which is convenient for grouping reports and looking at intersections between each other. The source can also add default macros to pixels.': 'Источник - рекламная система, по которой удобно группировать отчеты и смотреть пересечения между собой. Также источник может добавить дефолтные макросы к пикселям',
    'If your advertising system is present in the list of integrations and you have enabled it, then add the campaign ID to this field and expenses will be raised automatically': 'Если ваша рекламная система присутствует в списке интеграций и вы ее подключили, то добавьте в это поле ID кампании и расходы будут подтягивать автоматически',
    'This page will be redirected after clicking on the clickable pixel': 'На эту страницу будет редирект после клика по кликовому пикселю',
    // 'PV lead plan': 'PV заявок',
    'Number of target events': 'Количество целевых действий',
    'Focusing target action': 'Фокусное целевое действие',
    'When choosing a target action in the statistics, you will see the amount of exactly these post view target actions': 'Выбирая целевое действие в статистике, вы будете видеть кол-во именно этих postview целевых действий',
    'Tags': 'Теги',
    'A tag is a free text field that can be used to identify belonging to a group. By this field, you can separate campaigns in the reporting': 'Тег - это свободное текстовое поле, по которому можно идентифицировать принадлежность к какой-либо группе. По этому полю, можно отделять кампании в отчетности',

    //Placement
    'Placement name': 'Название креатива',
    'Create new placement': 'Создать новый креатив',
    'Onboarding url': 'Посадочная страница',
    'Tracking pixel': 'Пиксель отслеживания',
    'Adserving video': 'Видео креатив',
    'Adserving static': 'Статичный креатив',
    'Pixel type': 'Тип пикселя',
    'Select the pixel type': 'Выберите тип пикселя',
    'Upload your video': 'Загрузите ваше видео',
    'Upload your image': 'Загрузите изображение',
    'Tracking Settings': 'Сторонние трекеры',
    'Custom viewability': 'Кастомный viewability',
    'Black list': 'Черный список',
    'Advertiser': 'Рекламодатель',
    'Advertiser name': 'Название организации',
    'Address': 'Юридический адресс',
    'ERID': 'ERID',
    'INN': 'ИНН',
    'OGRN': 'ОГРН',
    'Advertiser url': 'Ссылка рекламодателя',
    'Advertiser delay': 'Задержка отображения маркера "Реклама" (миллисекунд)',
    'Skip time': 'Время пропуска (сек)',
    'Not clickable': 'Отключить кликабельность видео',
    'CV time': 'Время в видимой зоне (сек)',
    'CV area': 'Процент в видимой зоне (%)',
    'CV psc': 'Процент рекламного блока относительно экрана (%)',
    'CV pcsv': 'Процент видимой области рекламного блока относительно экрана (%)',
    'CV cht': 'Кол-во секунд наведения курсора на рекламу (сек)',
    'CV ums': 'Кол-во секунд просмотра видео со звуком (сек)',
    'Upload your blacklist of domains': 'Загрузите список доменов',
    'Add pixel': 'Добавить пиксель',
    'Format': 'Формат',
    'Select the format': 'Выберите формат',
    'Banner': 'Баннер',
    'Video': 'Видео',
    'Other': 'Другое',
    'Geo': 'Гео',
    'Targeting': 'Таргетинги',
    'Creative': 'Креатив',
    'Creative size': 'Размер креатива',
    'Video duration': 'Длительность видео в секундах',
    'Video orientation': 'Ориентация видео',
    'Select the orientation of the video': 'Выберите ориентацию видео',
    'ID in the advertising system': 'ID в рекламной системе',
    'Open the uploaded file': 'Открыть загруженный файл',
    'The file is more than 50 MB': 'Файл больше 50мб',

    //Modals titles
    'New project': 'Новый проект',
    'New campaign': 'Новая кампания',
    'New placement': 'Новый креатив',
    'New source': 'Новый источник',
    'Edit source': 'Редактировать источник',
    'Edit campaign': 'Редактирование кампании',
    'Edit name': 'Редактирование названия',
    'Edit placement': 'Редактирование креатива',
    'Add users': 'Добавить пользователей',
    'New event': 'Новое событие',
    'Edit user': 'Редактирование пользователя',
    'Add integration': 'Подключение интеграции',
    'Update integration': 'Обновление интеграции',
    'Uploading': 'Загрузка',
    'Connecting ADRIVER': 'Подключение ADRIVER',
    'Confirmation of deletion': 'Подтверждение удаления',
    'Confirmation of activation': 'Подтверждение активации',
    'Confirmation of archiving': 'Подтверждение архивации',
    'Confirmation of the change': 'Подтверждение изменения',
    'Edit': 'Редактировать',
    'Advanced Settings': 'Расширенные настройки',
    'Edit multi campaigns': 'Массовое изменение кампаний',
    'Edit multi placements': 'Массовое изменение креативов',
    //AddEmailModal
    'View': 'Просмотр',
    'Admin': 'Администратор',
    'Specialist': 'Cпециалист',
    'Creator': 'Создатель',

    'Include': 'Включить',
    'Exclude': 'Исключить',

    "Enter the user's email": 'Введите почту пользователя',
    'The user is not registered': 'Пользователь не зарегистрирован',
    'The invitation will be sent by email': 'Приглашение отправится на указанную почту',
    'Role': 'Роль',
    'Traffic limit': 'Ограничение по трафику',
    'No limit': 'Ограничений нет',
    'Access expiration date': 'Дата истечения доступа',
    'Unlimited': 'Бессрочный',
    'Adding a user to the project': 'Добавление юзера в проект',

    //LimitTrafficModal
    'Channel': 'Канал',
    'Campaign': 'Кампания',

    //AddIntegrationModal
    'Folder name': 'Название папки',
    'User': 'Пользователь',

    //ConfirmExitModal
    'You have unsaved changes.': 'У вас есть несохраненные изменения.',
    'You have unsaved changes. Save as a draft?': 'У вас есть несохраненные изменения. Сохранить как черновик?',
    'Leave the page?': 'Покинуть страницу?',
    'Revoke access': 'Отозвать доступ',

    //DeleteAccessModal
    "Confirmation of the user's removal from the project": 'Подтверждение удаления пользователя из проекта',

    //DocsCounter
    'Counter documentation': 'Документация счетчика',
    'Documentation on connecting the tracking counter on the website': 'Документация, по подключению счетчика отслеживания на сайте',
    'Basic counter initialization': 'Базовая инициализация счетчика',
    'Paste the following code as close to the top of the page as possible, preferably placed in the tag': 'Вставьте следующий код как можно ближе к началу страницы, желательно разместить в теге',
    'project id': 'id проекта',
    'your project id in the TargetADS system': 'ваш id проекта в системе TargetADS',
    'With this code, you send a page view event to the server. After installing the code on the site, you will already be able to collect basic metrics from the site, such as page views, unique users and the number of sessions by traffic sources.': 'Данным кодом вы отправляете на сервер событие о просмотре страницы. После установки кода на сайте вы уже сможете собирать базовые метрики с сайта, такие как просмотры страниц, уникальные пользователи и количество сессий по источникам трафика.',
    'Transmitting custom parameters': 'Передача кастомных параметров',
    'The sending function accepts two parameters: the event type (string) and the object describing this event. For the page_view event, you can pass user parameters (user) and event parameters (event_params) to the object describing the event.': 'Функция отправки принимает два параметра: тип события (string) и объект, описывающий это событие. Для событий page_view (просмотр страницы) и event (передача целевых событий) в объект, описывающий событие, можно передать параметры события (event_params).',
    "Entity parameters are also objects describing this entity, where the key is the name of the parameter, and the value available by it is the value of the parameter. The exception is the 'uid' key of the user object, the userId value is passed to it": 'Параметры сущностей также представляют собой объекты описывающие эту сущность, где ключ является названием параметра, а значение, доступное по нему, значением параметра. Исключение составляет ключ ‘uid’, в него передается значение userId.',
    'For example, in user parameters you can pass the user segment, user loyalty, the date of the previous order and much more, it all depends on the type of your business and the parameters by which you want to group and filter users. Up to 5 parameters of each type can be transmitted as much as possible.': 'Например, в параметры вы можете передать сегмент пользователя, лояльность пользователя, дату предыдущего заказа и многое другое, все зависит от типа вашего бизнеса и тех параметров, по которым вы хотите группировать и фильтровать пользователей. Максимально можно передавать до 5 параметров.',
    'user parameter': 'параметр пользователя',
    'parameter value': 'значение параметра',
    'event parameter': 'параметр события',
    "the name of the object describing the user's parameters.": 'имя объекта описывающее параметры пользователя.',
    'your internal user ID. If this parameter is not passed, then the other user parameters passed may not work correctly.': 'ваш внутренний id пользователя. Если не передать данный параметр, то остальные переданные параметры пользователя в event_params могут работать некорректно.',
    'the name of the event parameter.': 'название параметра события.',
    'the name of the user parameter.': 'название параметра пользователя.',
    'the parameter value corresponding to the type and name of the parameter by which it is available.': 'значение параметра, соответствующее типу и названию параметра, по которому оно доступно.',
    'Transmitting target events': 'Передача целевых событий',
    'In order to pass the target event, when the target action is triggered, you need to call the following method:': 'Для того, чтобы передать целевое событие, при срабатывании целевого действия, вам необходимо вызвать следующий метод:',
    'event type': 'тип события',
    'event category': 'категория события',
    'event name': 'название события',
    'event value': 'ценность события',
    'value': 'значение',
    'The method accepts an object with the following values:': 'Метод принимает объект со следующими значениями:',
    'event type values, can be any value, preferably in Latin letters': 'значения типа события, может быть произвольное значение, предпочтительно латинскими буквами',
    'the value of the event category can be an arbitrary value, preferably in Latin letters. Required parameter.': 'значение категории события, может быть произвольное значение, предпочтительно латинскими буквами. Обязательный параметр.',
    'the value of the event name can be any value, preferably in Latin letters. Required': 'значение названия события, может быть произвольное значение, предпочтительно латинскими буквами. Обязательный параметр.',
    'the value of the event value, takes floating-point values. Displayed in the interface as the value of the action.': 'значение ценности события, принимает значения с плавающей точкой. Отображаться в интерфейсе в качестве ценности действия.',
    'A unique combination of the type, category and name of the event will identify the target action and calculate attribution models based on it.': 'Уникальная комбинация типа, категории и названия события - будет идентифицировать целевое действие и рассчитывать по нему атрибуционные модели.',
    'Up to 5 event parameters can be passed.': 'Можно передавать до 5 параметров события.',
    'E-commerce': 'Электронная коммерция',
    'The e-commerce module can collect data about the addition of a specific product to the cart and collect data about the purchase and the goods in this purchase': 'Модуль электронной коммерции может собирать данные о добавлении конкретного товара в корзину и собирать данные о покупке и товарах в этой покупке',
    'Adding an item to the cart': 'Добавление товара в корзину',
    "To call the 'added to cart' event, use the following method:": 'Для вызова события “добавил в корзину”, используйте следующий метод:',
    'product id': 'id товара',
    'product name': 'название продукта',
    'product category 1': 'категория 1 продукта',
    'product category 2': 'категория 2 продукта',
    'product brand': 'бренд товара',
    'product variant': 'вариант товара',
    'the cost of product': 'стоимость товара',
    'Either the ID or the product name must be present, all other parameters are optional.': 'Обязательно должен присутствовать либо ID, либо название товара, все остальные параметры опциональны.',
    'Purchase': 'Покупка',
    "To call an event, use a function with the 'purchase' event type and pass an object describing the purchase to it:": 'Для вызова события используйте функцию с типом события “purchase” и передайте в нее объект описывающий покупку:',
    'purchase id': 'id покупки',
    'the number of products in the basket': 'количество товаров в корзине',
    'purchase amount': 'сумма покупки',
    'amount of product': 'количество товара',
    'purchase ID': 'Id покупки',
    'required parameter. Unique identifier of the sale.': 'обязательный параметр. Уникальный идентификатор продажи.',
    'the total number of items in the basket.': 'общее количество товаров в корзине.',
    'the total amount of the purchase.': 'общая сумма покупки.',
    'information about the purchased goods. The array cannot be empty. And also, each product must have either an ID or its name.': 'информация о купленных товарах. Массив не может быть пустой. А также в каждом товаре обязательно должен присутствовать либо ID, либо его название.',

    //MessageModal
    'Loaded': 'Загруженно',
    'campaigns': 'кампаний',
    'placements': 'креативов',
    'sources': 'источников',
    'Updated': 'Обновлено',
    'Errors': 'Ошибки',
    'Errors in file': 'Ошибки валидации',
    'Warnings': 'Предупреждения',

    //FilterDashboards
    'Filter': 'Фильтр',
    'Filter by values': 'Фильтр по значениям',
    'Filter by media campaigns': 'Фильтр по медийным кампаниям',
    'Custom filter': 'Фильтр кастомных параметров',
    'Ecommerce filter': 'Ecommerce фильтр',
    'Value': 'Значение',
    'Condition': 'Условие',
    'Not selected': 'Не выбрано',

    //DropdownList
    'Add source': 'Добавить источник',

    //Projects
    'the first project': 'первый проект',
    "You don't have any projects yet": 'У вас еще нет проектов',

    //SearchForm
    'Search': 'Поиск',

    //Filter
    'Actives': 'Активные',
    'Hold': 'Холд',
    'Archived': 'Архивные',
    'Date range': 'Диапазон дат',
    'Undefined': 'Неопределенный',

    //HeaderTable
    'Choice': 'Выбор',
    'Cost': 'Расход',
    'Target events': 'Целевых действий',
    'Cost of target events': 'Стоимость целевых действий',
    'Creation date': 'Дата создания',
    'Status': 'Статус',
    'Sending status': 'Статус отправки',
    'Reach': 'Охват',
    'Spent': 'Расходы',

    //TableRow
    'Confirm the deletion of the project': 'Подтвердите удаление проекта',
    'Confirm the changing status of the campaign': 'Подтвердите изменение статуса кампании',
    'Activate': 'Активировать',
    'Archive': 'Архивировать',
    'Confirm the deletion of the campaign': 'Подтвердите удаление Кампании',
    'Confirm the deletion of the placement': 'Подтвердите удаление креатива',
    'Add a creative pixel': 'Добавить пиксель креатива',

    //Create project
    'Create new project': 'Создание проекта',//Create
    'Counter code': 'Код счетчика',
    'Configuring Integrations': 'Настройка интеграций',
    'Configuring Attribution': 'Настройка атрибуции',
    'Complete acquaintance': 'Завершить знакомство',
    'You start the process of creating a project in the TargetADS system, it will take 4 simple steps. To begin with, fill in the basic parameters of the project': 'Вы начинаете процесс, создания проекта в системе TargetADS, он займет 4 простых шага. Для начала, заполним базовые параметры проекта',
    'Project name': 'Название проекта',
    "The name of the project will be displayed in the menu, in the reports sent and in the settings. If you have only one project, you don't have to think about the name for a long time": 'Название проекта будет отображаться в меню, в присылаемых отчетах и в настройках.\n Если у вас будет только один проект, над названием можно долго не думать',
    'The site address is needed to receive data only from your site, so that no one could distort your data. The address can be entered in the format https://site.com': 'Адрес сайта нужен для приема данных только с вашего сайта, что бы никто не смог исказить ваши данные. Адрес можно вводить в формате https://site.com',
    'Project currency': 'Валюта проекта',
    'The currency is needed for the visual display of monetary indicators in reports, we do not convert currencies': 'Валюта нужна для визуального отображения денежных показателей в отчетах, мы не конвертируем валюты',
    //step2
    'Installing a counter': 'Установка счетчика',
    'Copy the pixel code': 'Скопируйте код пикселя',
    'Set the pixel on the site': 'Установите пиксель на сайт',
    'Add the counter code to the HTML code of all the pages of the site. The code must be placed within the <head> </head> or <body> </body> tags as close to the top of the page as possible: this way it will load earlier and will be able to send browsing data to TargetAds, even if the visitor closes the page almost immediately.': 'Добавьте код счетчика в HTML-код всех страниц сайта. Код нужно разместить в пределах тегов <head> </head> или <body> </body> как можно ближе к началу страницы: так он будет раньше загружаться и сможет отправить данные о просмотре в TargetAds, даже если посетитель почти сразу же закроет страницу.',
    'Check the installation of the counter': 'Проверьте установку счетчика',
    'Checking the counter': 'Проверяем счетчик',
    'The result of the check will appear here': 'Здесь появится результат проверки',
    'The counter is successfully connected!': 'Счетчик успешно подключен!',
    'The counter is not installed': 'Счетчик не установлен',
    //step3
    'Connected integrations': 'Подключенные интеграции',
    'Integrations are not connected yet': 'Интеграции пока не подключены',
    "You can add data from third-party systems to your project. For example, CRM systems, for importing sales data or Advertising systems, for importing expense data. You can connect the integration after, in the project settings. If you already know what you can connect, then feel free to click on the 'Add' button": 'В ваш проект можно добавить данные из сторонних систем. Например CRM систем, для импорта данных о продажах или Рекламных систем, для импорта данных о расходах. Подключить интеграции можно и после, в настройках проекта. Если вы уже знаете, что можно подключить, то смело нажимайте на кнопку “Добавить”',
    'Search integration': 'Поиск интеграции',
    'Enter the name of the service...': 'Введите название сервиса...',
    'Nothing found': 'Ничего не нашли',
    //TODO: modal
    //step4
    'Default attribution window': 'Окно атрибуции по умолчанию',
    'Default attribution model': 'Модель атрибуции по умолчанию',
    'Target event KPI': 'KPI целевого действия',

    //Currency
    'USD': 'Доллары',
    'EUR': 'Евро',
    'RUB': 'Рубли',

    //model
    'fl': 'Linear',
    'fli': 'Last Interaction',
    'ffi': 'First interaction',
    'ftd': 'TimeDecay',
    'ftdm': 'TargetADS Model',
    'ml': 'Linear (media)',
    'mli': 'Last Impression',
    'mfi': 'First Impression',
    'mtd': 'TimeDecay (media)',
    'flnd': 'Last non direct',
    'fw': 'Position Based',
    'mw': 'Position Based (media)',

    //Campaigns
    'Campaignies': 'Кампаний',
    'not found': 'не найдено',
    'Download selected': 'Скачать выбранные',
    'Download active': 'Скачать активные',
    'Download all': 'Скачать все',

    //Placements
    'Placementies': 'Креативов',

    //Pagination
    'Lines per page': 'Строк на странице',

    //Setting
    'General': 'Общие настройки',
    'Attributions': 'Атрибуция и цели',
    'Counter': 'Счетчик',
    'Users management': 'Управление пользователями',
    'Integrations': 'Интеграции',

    //Status
    'Active': 'Активный',
    'Inactive': 'Неактивный',
    'Archival': 'Архивный',
    'Deleted': 'Удален',
    'active': 'Активный',
    'inactive': 'Неактивный',
    'archive': 'Архивный',
    'DELETED': 'Удален',
    'completed': 'Выполнен',
    'draft': 'Черновик',
    'notsend': 'Без отправки',
    'pause': 'Пауза',
    'error': 'Ошибка',
    //Timezones
    "UTC": "Западноевропейское время: Великобритания (UTC)",
    "UTC1": "Центральноевропейское время: Австрия, Германия (UTC+1)",
    "UTC2": "Восточноевропейское время: Украина, Греция, Кипр (UTC+2)",
    "UTC3": "восточноафриканское время: Россия, Турция  (UTC+3)",
    "UTC4": "Россия, Грузия, Армения (UTC+4)",
    "UTC5": "Россия, Казахстан, Узбекистан (UTC+5)",
    "UTC6": "Россия, Казахстан, Киргизия, Бутан, Бангладеш (UTC+6)",
    "UTC7": "Россия, Монголия, Таиланд, Лаос, Камбоджа (UTC+7)",
    "UTC8": "Россия, Монголия, Китай (UTC+8)",
    "UTC9": "Россия, Республика Корея, Япония(UTC+9)",
    "UTC10": "Россия, Австралия (UTC+10)",
    "UTC11": "Россия, Соломоновы Острова, Новая Каледония (UTC+11)",
    "UTC12": "Россия, Фиджи, Новая Зеландия (UTC+12)",
    "UTC-1": "Португалия (Азорские острова), Кабо-Верде (UTC-1)",
    "UTC-2": "Среднеатлантическое время (UTC-2)",
    "UTC-3": "Южноамериканское восточное время: Дания(Гренландия), Бразилия, Аргентина (UTC-3)",
    "UTC-4": "Атлантическое время: Канада, Пуэрто-Рико, Венесуэла, Бразилия (UTC-4)",
    "UTC-5": "Североамериканское восточное время: Канада, США, Мексика, Куба (UTC-5)",
    "UTC-6": "Центральноамериканское время: Канада, США, Мексика, Гватемала (UTC-6)",
    "UTC-7": "Горное время: Канада, США, Мексика \t(UTC-7)",
    "UTC-8": "Cевероамериканское тихоокеанское время: Канада, США, Мексика (UTC-8)",
    "UTC-9": "США (Аляска) (UTC-9)",
    "UTC-10": "США (Гавайи, Алеутские острова) (UTC-10)",
    "UTC-11": "Американское Самоа, Ниуэ (UTC-11)",
    "UTC-12": "Линия перемены даты (UTC-12)",

    //PlainSettings
    'Input new name': 'Введите новое название',
    'Input new email': 'Введите новый email',
    'Project status': 'Статус проекта',
    'Accept data only from this domain and subdomain': 'Принимать данные только с этого домена и поддомена',
    'Mail for administrative documents': 'Почта для административных документов',
    'Currency': 'Валюта',
    'Project timezone': 'Часовой пояс проекта',

    //Attributions
    'days': 'дней',
    'This year': 'Этот год',
    'Today': 'Сегодня',
    'Yesterday': 'Вчера',
    'Last seven days': 'Последние 7 дней',
    'An average of 7 days': 'Среднее за 7 дней',
    'This month': 'Этот месяц',
    'Previous month': 'Предыдущий месяц',
    'This week': 'Эта неделя',
    'Previous week': 'Предыдущая неделя',
    'Attribution': 'Атрибуция',
    'Select the attribution window': 'Выберите окно атрибуции',
    'Select the attribution model': 'Выберите модель атрибуции',
    'Select the attribution period': 'Выберите временный интервал',
    'Default relative time interval': 'Относительный временный интервал по умолчанию',
    'Plan fact': 'План факт',
    'Show progress in reports': 'Показывать выполнение в отчетах',

    //UserControl
    'Access expires': 'Доступ истекает',
    'Last visit': 'Последний визит',
    'Name hidden': 'Имя скрыто',
    'Mail hidden': 'Email скрыт',
    'User data is hidden in the project view mode': 'Данные о пользователях скрыты в режиме просмотра проекта',

    //Integrations
    'All': 'Все',

    //Events list
    'Event targets': 'Целевые события',
    'not founds': 'не найдены',
    'Event target name': 'Название цели',
    'Target cost': 'Целевая стоимость',
    'Event type': 'Тип события',
    'Event category': 'Категория события',
    'Event name': 'Название события',
    'Event value': 'Ценность события',
    'Number of events': 'Кол-во событий',
    'Total': 'Всего',
    //EventsCreate
    'Event editing': 'Редактирование события',
    'Event creation': 'Создание события',
    "The name will be displayed only in the interface. We advise you to give simple names, for example, 'Application for consultation'": "Название будет отображаться только в интерфейсе. Советуем давать простые названия, например \"Заявка на консультацию\"",
    'Add the target cost of your event (KPI) and in some reports we will show you ineffective campaigns.': 'Добавьте целевую стоимость вашего события (KPI) и в некоторых отчетах мы будем показывать вам неэффективные кампании.',
    'Calculate attribution models': 'Рассчитывать атрибуционные модели',
    'Whether to calculate attribution models for this event.': 'Рассчитывать ли атрибуционные модели для данного события.',
    'Input event type': 'Введите тип события',
    'Input event category': 'Введите категорию события',
    'Input event name': 'Введите название события',
    'Input event value': 'Введите ценность события',
    'Update event': 'Обновить событие',
    'Create event': 'Создать событие',
    'Add the pixel code to the HTML code in all the places where the event is triggered when interacting with.': 'Добавьте код пикселя в HTML-код во все, места, при взаимодействии с которыми срабатывает событие.',
    'All events': 'Все события',

    //Upload
    'Data loading is not available in view mode': 'Загрузка данных недоступна в режиме просмотра',
    'Media campaign expenses': 'Расходы на медийные кампании',
    'Campaign performance expenses': 'Расходы на перформанс кампании',
    'Planned values for media campaigns': 'Плановые значения для медийных кампаний',
    'Planned values for performance campaigns': 'Плановые значения для перфоманс кампаний',
    'Upload data into a project': 'Загрузка данных в проект',
    //MediaCampaignExpenxes
    'Upload expenses for media campaigns': 'Загрузка расходов на медийные кампании',
    'If you import expenses from advertising systems that are in the list of integrations, we recommend setting up automatic import. For everything else there is a CSV :)': 'Если вы импортируете расходы из рекламных систем которые есть в списке интеграций, то рекомендуем настроить автоматический импорт. Для всего остального есть Excel :)',
    'The file must contain the following fields': 'Файл обязательно должен содержать следующие  поля',
    'Date (we upload expenses by day)': 'Дата (мы загружаем расходы по дням)',
    'Campaign name (must match the name of the created or imported campaigns)': 'Название кампании (должно совпадать с названием созданных или импортированных кампаний)',
    'The name of the creative (must match the name of the created or imported creatives)': 'Название креатива (должно совпадать с названием созданных или импортированных креативов)',
    'Cost with VAT': 'Стоимость с НДС',
    'Upload campaign performance expenses': 'Загрузка расходов на перформанс кампании',
    'Optional fields': 'Необязательные поля',
    'This is a type of expenses for utm tags not from advertising systems, for which the expense is not automatically transferred, for example, CPA, paid mailings, in general, all paid placements that users can click on, except for media placements, they have their own section': 'Это тип расходов по utm меткам не из рекламных систем, по которым расход автоматически не передается, например CPA, платные рассылки, вообщем все платные размещения по которым могут перейти пользователи, кроме медийных размещений, для них есть свой раздел',
    'Upload planned values for media campaigns': 'Загрузка плановых значений для медийных кампаний',
    "After downloading the planned indicators, the dashboard plan/fact and the indicators '% of plan completion' and 'Run Rate' will be available to you. It is possible to upload retrospectively, the data is automatically pulled up for past periods": 'Загрузив плановые показатели , вам будут доступны дашборд план/факт и  показатели “% выполнения плана” и “Run Rate”. Можно загружать ретроспективно, данные автоматически подтянуться за прошлые периоды',
    'Month (in the format YYYY-MM)': 'Месяц (в формате 2022-12)',
    'The plan for cost': 'План по расходам',
    'The plan for impressions': 'План по показам',
    'The plan for reach': 'План по охвату',
    'The plan for clicks': 'План по кликам',
    'Planned CPM': 'Плановый CPM',
    'Planned CPUU': 'Плановый CPUU',
    'Planned CPC': 'Плановый CPC',
    'Planned CPA': 'Плановый CPA',
    'Planned CPP': 'Плановый CPP',
    'The plan for event target': 'План по целевым действиям',
    'Upload planned values for performance campaigns': 'Загрузка плановых значений для перформанс кампаний',

    //UploadContent
    'Download an example': 'Скачать пример загрузочного файла',
    'Upload daily expense': 'Загрузить ежедневный расход',
    'Upload monthly expense': 'Загрузить месячный расход',
    'Upload daily targets': 'Загрузить ежедневные плановые показатели',
    'Upload monthly targets': 'Загрузить месячные плановые показатели',
    'Or drag it to this area': 'Или перетяните его в эту область',

    //Dashboards
    'Project reporting': 'Отчетность проекта',
    'Effectiveness of media campaigns': 'Эффективность медийных кампаний',
    'E-Commerce Reports': 'Отчеты по электронной коммерции',
    'User behavior analysis': 'Анализ поведения пользователей',
    'Comparison of attribution models': 'Сравнение моделей атрибуции',
    'Evaluation of performance channels': 'Оценка перформанс каналов',
    'Media plan fact': 'Медийный план факт',
    'General indicators': 'Общие показатели',
    'Soon': 'Скоро',

    //upload.ts
    'Upload timeout limit exceeded': 'Превышен лимит времени ожидания загрузки',
    'Error': 'Ошибка',
    'Great': 'Отлично',

    //Project
    'Select the period': 'Выбор периода',
    'Day': 'День',
    'Week': 'Неделя',
    'Month': 'Месяц',
    'Quarter': 'Квартал',
    'Year': 'Год',
    'Attribution window': 'Окно атрибуции',
    'Attribution model': 'Модель атрибуции',
    'Scale': 'Масштаб',
    'day': 'День',
    'week': 'Неделя',
    'month': 'Месяц',
    'quarter': 'Квартал',
    'year': 'Год',

    //CampaignEfficiency
    'All event targets': 'Все целевые действия',

    //Names charts, tables
    'main_index_uniq_user': 'Уникальных пользователей',
    'main_index_cr_user_to_lead': 'Конверсия в целевое действие',
    'main_index_lead': 'Целевых действий',
    'main_index_cost': 'Расход',
    'main_index_cpa': 'Стоимость целевого действия',
    'main_index_media_reach': 'Медийный охват',
    'main_chart_lead_cpa': 'Динамика целевых действий и стоимости',
    'main_chart_cost': 'Динамика расхода',
    'main_chart_uniq_user': 'График уникальных пользователей',
    'main_chart_cr_user_to_lead': 'Конверсия',
    'main_chart_lead': 'Динамика целевых действий',
    'main_chart_distr_lead_source': 'Эффективность рекламных источников',
    'main_table_full_stat': 'Показатели привлечения',
    'main_table_model_attr_compare': 'Сравнение эффективности по разным моделям атрибуции',
    'main_table_lead_ptd': 'Сравнение динамики целевых действий, за относительные прошлые периоды',
    'media_index_cost': 'Расходы на медийные кампании',
    'media_index_impression': 'Показы',
    'media_index_reach': 'Охват уникальных пользователей',
    'media_index_frequency': 'Частота показов рекламы на пользователя',
    'media_index_click': 'Кликов',
    'media_index_lead': 'Целевых действий',
    'media_index_consumed_lead': 'Реальная ценность рекламы',
    'media_index_cpm': 'Стоимость за 1 тыщ. показов',
    'media_index_cpr': 'Стоимость охвата уникального пользователя',
    'media_index_ctr': 'Кликабельность объявлений',
    'media_index_cpc': 'Стоимость клика',
    'media_index_cpa': 'Стоимость целевого действия',
    'media_chart_lead_cpa': 'Динамика стоимости целевого действия',
    'media_chart_impression': 'Динамика просмотров',
    'media_chart_cost': 'График расходов по медийным кампаниям',
    'media_chart_cr_impression_click': 'Динамика CTR',
    'media_chart_click': 'График кликов по медийным кампаниям',
    'media_chart_clicks_and_ctr': 'Динамика кликов и CTR',
    'media_table_adv_cost_plan_fact': 'План-факт по медийной рекламе',
    'media_table_full_stat': 'Показатели привлечения медийной рекламы',
    'media_chart_adv_cohort_efficiency': 'Эффективность медийных кампаний с накопленным итогом',
    'media_table_reach_crossing': 'Кросс пересечение охватов по',
    'media_table_media_to_target_event': 'PostView целевые действия (Last Impression)',
    'By campaign': 'Кампаниям',
    'By domain': 'Доменам',
    'By source': 'Источникам',
    'By ssp': 'SSP',
    'media_chart_distr_impression_user': 'Распределение кол-ва показов на пользователя',
    'media_chart_flight_duration': 'Длительность эффекта медийной рекламы',
    'media_table_domain': 'Медиа домены',
    'media_table_adv_impact_to_product': 'Влияние кампаний на продукты',
    'media_table_not_see_adv': 'Влияние медийных кампаний на конверсию',
    'media_table_conversion_lift_media_camp_on_source': 'Влияние медийных кампаний на рекламные источники (детально)',
    'media_table_conversion_lift_media_on_source': 'Влияние медийных кампаний на рекламные источники',
    'media_table_conversion_lift_media_on_utm_camp': 'Влияние медийных кампаний на utm campaign',
    'media_table_video_action': 'Досмотры видео кампаний',
    'media_table_viewability': 'Показатели медийного размещения',
    'ecom_index_income': 'Сумма покупок',
    'ecom_index_purchase': 'Колличество покупок',
    'ecom_index_avg_check': 'Средний чек',
    'ecom_index_uniq_user': 'Уникальных пользователей',
    'ecom_index_cr_user_to_purchase': 'Конверсия в покупку',
    'ecom_index_model_drr': 'drr',
    'ecom_chart_purchase_avg_check': 'Динамика среднего чека и колличества покупок',
    'ecom_chart_uniq_user': 'Динамика уникальных пользователей на сайте',
    'ecom_chart_cr_user_to_purchase': 'Динамика конверсии в покупку',
    'ecom_chart_income': 'Динамика суммы покупок',
    'ecom_chart_distr_purchase_weekday': 'График продаж по дням недели',
    'ecom_chart_distr_item_purchase_amount': 'График популярных товаров',
    'ecom_table_item_purchase_popular': 'Популярные товары',
    'ecom_table_item_purchase_popular_consumed': 'Реальное влияние медийки на товары',
    'ecom_table_page_popular': 'Эффективные страницы',
    'ecom_table_model_adsource_purchase': 'Эффективность рекламных источников',
    'ecom_table_model_adsource_item': 'ecom_table_model_adsource_item',
    'ecom_table_model_drr_cost_income': 'ecom_table_model_drr_cost_income',
    'ecom_table_model_campaign_purchase': 'ecom_table_model_campaign_purchase',
    'ecom_table_model_campaign_item': 'ecom_table_model_campaign_item',
    'ecom_table_model_campaign_roi': 'Эффективность вложенных средств по кампаниям',
    'ecom_table_adv_impact_to_item': 'ecom_table_adv_impact_to_item',
    'behavior_index_uniq_user': 'uniq_user',
    'behavior_index_cr_returning_user': 'cr_returning_user',
    'behavior_index_returning_user': 'returning_user_amt',
    'behavior_index_lead': 'lead',
    'behavior_index_avg_time': 'avg_time_spent',
    'behavior_index_avg_page_amt': 'avg_page_amt',
    'behavior_table_ad_source': 'behavior_table_ad_source',
    'behavior_chart_distr_device': 'behavior_chart_distr_device',
    'behavior_chart_os_distr': 'behavior_chart_os_distr',
    'behavior_chart_distr_browser': 'behavior_chart_distr_browser',
    'behavior_table_chain_popularity': 'behavior_table_chain_popularity',
    'behavior_chart_distr_time_between': 'Распределение времени между взаимодействиями',
    'behavior_chart_distr_chain_number': 'behavior_chart_distr_chain_number',
    'getintent_fraud_report': 'Отчет по медийным показателям и фроду',
    'getintent_fraud_bu_event_id': 'Детализированный отчет по фроду',

    //Charts
    'missing': 'отсутсвует',

    //TablePagination
    'Select aggregation': 'Выберите агрегацию',
    'Ascending': 'По возрастанию',
    'ascending': 'По возрастанию',
    'Descending': 'По убыванию',
    'descending': 'По убыванию',
    'Default': 'По умолчанию',

    //TimeFilter
    'Last N days': 'Последние N дней',
    'Select a range': 'Выбрать диапазон',
    //Select
    'Last': 'Последние',

    //Conditions
    'More': 'Больше',
    'Less': 'Меньше',
    'Equal': 'Равно',
    'Not equal': 'Не равно',
    'Contain': 'Содержит',
    'Not contain': 'Не содержит',

    //Custom reports
    'Custom reports': 'Кастомные отчеты',
    'Custom report': 'Кастомный отчет',
    'Period': 'Период',
    'Author': 'Автор',
    'Create based on': 'Создать на основе',
    'Stop': 'Остановить',
    'Run': 'Запустить',

    //Create report
    'New report': 'Новый отчет',
    'Edit report': 'Редактировать отчет',
    'Create a report': 'Создание отчета',
    'To create a report, fill in the fields below': 'Для создания отчета заполните поля ниже',
    'Report type': 'Тип отчета',
    'Report name': 'Название отчета',
    'Input name': 'Введите название',
    'Group': 'Группировка',
    'Select the group': 'Выберите группировку',
    'Select Target Events': 'Выберите целевые действия',
    'Fields': 'Поля',
    'Select the field': 'Выберите поле',
    'Filter fields': 'Фильтр полей',
    'Metrics': 'Метрики',
    'Select the metric': 'Выберите метрику',
    'Select the event target': 'Выберите целевое действие',
    'Filter metrics': 'Фильтр метрик',
    'Target events settings': 'Настройки целевых действий',
    'Send settings': 'Настройки отправки',
    'Add field': 'Добавить поле',
    'Add metric': 'Добавить метрику',
    'Frequency': 'Периодичность',
    'Select the frequency': 'Выберите переодичность',
    'Send time': 'Время отправки',
    'Select the time': 'Выберите время',
    'Method of sending': 'Метод отправки',
    'Select the method': 'Выберите метод',
    'Data format': 'Формат данных',
    'Recipients': 'Адресаты отправки',
    'Enter mail': 'Введите почту',
    'Create report': 'Создать отчет',
    'Update report': 'Обновить отчет',
    'A preview of your report will appear here': 'Здесь появится превью вашего отчета',
    'Select at least one field and one metric, and a preview of your report will be displayed here': 'Выберите хотя бы одно поле и одну метрику, и здесь появится превью вашего отчета.',
    'Select at least one field from the list': 'Выберите хотя бы одно поле из списка',
    'and a preview of your report will appear here': 'и здесь появится превью вашего отчета',
    'One-time': 'Разово',
    'Every day': 'Каждый день',
    'Every week': 'Каждую неделю',
    'Every month': 'Каждый месяц',
    'day_numbers': 'числа',
    'Period of interaction': 'Период взаимодействия',
    'Period of impression': 'Период показа',
    'Period of event target': 'Период целевого действия',
    'Interaction type': 'Тип взаимодействия',

    'Enter a number from 1 to': 'Введите число от 1 до',

    'The report was created': 'Отчет успешно создан',
    'The report was updated': 'Отчет обновлен',
    'csv': 'Csv',
    'xlsx': 'Excel',

    'mo': 'пн',
    'tu': 'вт',
    'we': 'ср',
    'th': 'чт',
    'fr': 'пт',
    'st': 'сб',
    'su': 'вс',

    'media_campaign_statistics': 'Статистика по медийным кампаниям',
    'user_paths_conversion': 'Пути пользователя до конверсии',
    'general_custom_table': 'Общий отчет',
    'custom_table_ecom': 'Ecommerce отчет по товарам',
    'media_campaign_effectivity': 'Отчет по эффективности медийных кампаний',
    'custom_table_row': 'Отчет по эффективности медийных кампаний',
    'custom_table_row_event': 'Отчет по эффективности медийных кампаний',
    'custom_table_row_media': 'Отчет по эффективности медийных кампаний',

    //Errors
    'Incorrect value': 'Некорректное значение',
    'Tag already exist': 'Тег уже существует',
    'Event already exist': 'Такое событие уже существует',
    'This name already exist': 'Такое название уже существует',
    'Event with this params already exist': 'Событие с такими параметрами уже существует',
    'Could not create tag for campaign': 'Не смогли создать тег для кампании',
    'Error with onboarding url': 'Ошибка с посадочной страницей',
    'Could not update campaign': 'Не смогли обновить кампанию',
    'Cannot delete campaign as it has dependant records. Confirmation is needed to delete campaign with its dependencies': 'Не удается удалить кампанию, поскольку у нее есть зависимые записи. Для удаления кампании с ее зависимостями требуется подтверждение',
    'Project with such placement not found': 'Проект с таким креативом не найден',
    'Not authenticated': 'Не авторизованный',
    'Date is accepted in format that coerces to month or days. So the date will be always first day of month or the usual with days': 'Дата принимается в формате, который соответствует месяцу или дням. Таким образом, датой всегда будет первый день месяца или обычное число с днями',
    'File must be in CSV or EXCEL format': 'Файл должен быть в формате CSV или EXCEL',
    "Couldn't read the file": 'Не удалось прочитать файл',
    'value is not a valid float': 'не является допустимым значением с плавающей точкой',
    'value is not a valid integer': 'не является допустимым значением целого числа',
    'Field is required': 'Поле является обязательным',
    'field required': 'Поле является обязательным',
    'Only the first 5 tags were updated. Campaign can have a maximum of 5 tags': 'Были обновлены только первые 5 тегов. В кампании может быть не более 5 тегов',
    "time data '' does not match format '%Y-%m-%d'": 'Дата не соответсвует формату ГГГГ-ММ-ДД',
    'You can select a maximum of': 'Можно выбрать максимум',
    'You can select a minimum of': 'Можно выбрать минимум',
    'Invalid reset token': 'Некорректный токен',
    'Yandex Direct: Some database error occurred': 'Yandex Direct: Что-то пошло не так',
    'Your username is not connected to Yandex.Direct': 'Ваш аккаунт не подключен к Yandex.Direct',
    'Confirmation is needed to delete custom report with its dependencies': 'Для удаления кастомного отчета с его зависимостями требуется подтверждение',
    'Are you sure you want to delete a custom report? The periodic sending of the report to the mail will stop': 'Вы уверены, что хотите удалить кастомный отчет? Периодическая отправка отчета на почту прекратится',
    'Custom report has been successfully deleted': 'Кастомный отчет успешно удален',
    'You dont have access to download': 'У вас нет доступа к скачиванию',
    'You dont have access to this action': 'У вас нет доступа к этому действию',
    'You dont have access to the project': 'У вас нет доступа к проекту',
    'You dont have access to sources': 'У вас нет доступа к источникам',
    'You dont have access to campaigns': 'У вас нет доступа к кампаниям',
    'You dont have access to custom_reports': 'У вас нет доступа к кастомным отчетам',
    'You dont have access to placements': 'У вас нет доступа к кретивам',
    'You dont have access to events': 'У вас нет доступа к целевым событиям',
    'You dont have access to upload': 'У вас нет доступа к загрузке данных',
    'You dont have access to dashboard_general': 'У вас нет доступа к общим показателям',
    'You dont have access to dashboard_ecommerce': 'У вас нет доступа к отчету по электронной коммерции',
    'You dont have access to dashboard_user_behavior': 'У вас нет доступа к анализу поведения пользователей',
    'You dont have access to dashboards': 'У вас нет доступа к дашбордам',
    'You dont have access to settings_general': 'У вас нет доступа к общим настройкам',
    'You dont have access to settings_attributions': 'У вас нет доступа к атрибуции',
    'You dont have access to settings_counter': 'У вас нет доступа к счетчику',
    'You dont have access to settings_user_control': 'У вас нет доступа к пользовательским настройкам',
    'You dont have access to settings_integrations': 'У вас нет доступа к интеграциям',
    'You dont have access to settings_api': 'У вас нет доступа к API',
    'You dont have access to settings_roles': 'У вас нет доступа к ролям',
    'You cannot change access mode for yourself': 'Вы не можете изменить свою роль',
    'Not found role': 'Неизвестная роль',
    'No creative created': 'У кампании нет креатива',
    'This role has dependencies': 'Роль имеет зависимости',

    //Integrations
    'Token': 'Токен',
    'Select the Advertiser': 'Выберите рекламодателя',
    'Confirm the deletion of the integration from the project': 'Подтвердите удаление интеграции из проекта',
    'Maximum text length: 20 characters': 'Максимальная длина текста: 20 символов',
    'advertising_platforms': 'Рекламные площадки',
    'Sorry, the server is not responding at the moment': 'В данный момент сервер не отвечает',
    'The name for the integration': 'Название для интеграции',

    //AuthorizationForm
    'Enter the name': 'Введите имя',

    //short name for numbers
    'thous': 'тыс.',
    'mln': 'млн',
    'bln': 'млрд',
    'trln': 'трлн',
    'quadrillion': 'квадриллион',

    //Months
    'January': 'Январь',
    'February': 'Февраль',
    'March': 'Март',
    'April': 'Апрель',
    'May': 'Май',
    'June': 'Июнь',
    'July': 'Июль',
    'August': 'Август',
    'September': 'Сентябрь',
    'October': 'Октябрь',
    'November': 'Ноябрь',
    'December': 'Декабрь',

    //Reports
    'Sorting': 'Сортировка',
    'Preview': 'Просмотр',
    'Prepreview': 'Предпросмотр',
    'Preview version': 'Предварительная версия',
    'Partial version': 'Неполная версия',
    'The data is not up to date': 'Данные не актуальны',

    //Tooltips
    'If your advertising system is present in the list of integrations, and you have connected it, then add the creative ID to this field, and expenses will be raised automatically.': 'Если ваша рекламная система присутствует в списке интеграций, и вы ее подключили, то добавьте в это поле ID креатива, и расходы будут подтягивать автоматически.',
    'The field is used to filter and group the results. It will help you better look for insights in the data.': 'Поле нужно для фильтрации и группировки результатов. Оно поможет вам лучше искать инсайты в данных.',
    'A free text field. It is needed to filter and group the results. It will help you better look for insights in the data. For example, "Moscow", "UFA", "Moscow and St. Petersburg", "regions"': 'Свободное текстовое поле. Оно нужно для фильтрации и группировки результатов. Оно поможет вам лучше искать инсайты в данных. Например, "Москва", "УФА", "Москва и Питер", "регионы"',
    'A free text field. It is needed to filter and group the results. It will help you better look for insights in the data. For example: "wealthy audience", "audience of TV series fans".': 'Свободное текстовое поле. Оно нужно для фильтрации и группировки результатов. Оно поможет вам лучше искать инсайты в данных. Например: "состоятельная аудитория", "аудитория любителей сериалов".',
    'A free text field. It is needed to filter and group the results. It will help you better look for insights in the data.': 'Свободное текстовое поле. Оно нужно для фильтрации и группировки результатов. Оно поможет вам лучше искать инсайты в данных.',
    'Describe the creative. The field is used to filter and group the results. It will help you better look for insights in the data. For example: "Creative with gold" or "Creative with UTP".': 'Опишите креатив. Поле нужно для фильтрации и группировки результатов. Оно поможет вам лучше искать инсайты в данных. Например: "Креатив с золотом" или "Креатив с УТП".',

    //Settings api
    "You haven't created any authentication tokens yet": 'Вы еще не создали никаких токенов аутентификации',
    'Create token': 'Создать токен',
    'Change token': 'Редактировать токен',

    //Settings roles
    'Roles': 'Роли',
    'Agency': 'Агентство',
    "You haven't created any roles yet": "Вы еще не создали никаких ролей",
    'Create role': 'Создать роль',
    'Change role': 'Редактировать роль',
    'Role name': 'Название роли',
    'Key': 'Ключ',
    'Select the resource': 'Выберите ресурс',
    'Add resource': 'Добавить ресурс',
    'Limitation': 'Ограничения',
    'Access': 'Доступ',
    'Not accessible': 'Недоступен',
    'Full access': 'Полный доступ',
    'In this section, you can create a custom role, restricting access only to the necessary data and functions of the application.': 'В данном разделе вы можете создать кастомную роль, ограничив доступ только к нужным данным и функциям приложения.',
    'This name already exists': 'Такое название уже существует',

    //Documentation
    'Documentation': 'Документация',
    'Instruction': 'Инструкция',
    'Instruction for create project': 'Инструкция по созданию проекта',

    //vars
    'trg_event_dt': 'Дата целевого действия',
    'event_dt': 'Дата взаимодействия',

    'Project Id': 'Id проекта',
    'Regardless of the status change, statistics will continue to be collected': 'Независимо от изменения статуса, статистика продолжит собираться'
}