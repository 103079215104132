import {DefaultObject, IField, IModelAttribution, IWindowAttribution} from '../interfaces/common.d';
import { IAccordion } from '../components/ModalForm/ModalForm.d';

import {trans} from '../_locales'
import {BaseSource} from "../redux/slices/sources";

export const MAX_NUMBER_FOR_INPUT = 999999999999;
export const REG_EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
export const ERROR_NETWORK_MESSAGE = 'В данный момент сервер не отвечает';
export const ERROR_DETAIL_MESSAGE = 'Что-то пошло не так';

export enum PopupType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export const PopupTitle = {
  [PopupType.SUCCESS]: 'Успешно',
  [PopupType.ERROR]: 'Ошибка!',
  [PopupType.WARNING]: 'Внимание!',
} as const;

export const contentJson = { headers: { 'Content-Type': 'application/json' } };

export const newProjectFields= (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'text',
    name: 'name',
    placeholder: '',
    label: trans('Naming', language),
    errorLabel: trans('The field is required', language),
    isRequired: true
  },
  {
    tag: 'input',
    type: 'text',
    name: 'domain',
    placeholder: '',
    label: trans('Website address', language),
    errorLabel: trans('Enter the correct url', language),
    isUrl: true,
    isRequired: true
  },
  {
    tag: 'input',
    type: 'text',
    name: 'company_name',
    placeholder: '',
    label: trans('Product Name', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
];

export const projectEditing= (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'text',
    name: 'name',
    placeholder: '',
    label: trans('Naming', language),
    errorLabel: trans('The field is required', language),
    isRequired: true
  }
];

export const newCampaignForm= (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'text',
    name: 'name',
    placeholder: '',
    label: trans('Campaign name', language),
    errorLabel: trans('The field is required', language),
    isRequired: true
  },
  {
    tag: 'select',
    type: 'text',
    name: 'source_id',
    placeholder: '',
    label: trans('Source', language),
    tooltip: trans('The source is an advertising system, which is convenient for grouping reports and looking at intersections between each other. The source can also add default macros to pixels.', language),
    // tooltip: trans(`Your advertising source, for example, Yandex, myTarget or Hybrid`, language),
    errorLabel: trans('Select the source', language),
    isRequired: true,
    isOpenUp: false,
    hasAddButton: true,
    options: [],
  },
  // {
  //   tag: 'select',
  //   type: 'text',
  //   name: 'ads_type',
  //   placeholder: '',
  //   label: trans('Campaign type', language),
  //   errorLabel: trans('Select the type', language),
  //   isRequired: true,
  //   isOpenUp: false,
  //   options: [
  //     {
  //       label: 'Reach',
  //       value: 'Reach'
  //     },
  //     {
  //       label: 'Brand Formance',
  //       value: 'Brand Formance'
  //     },
  //     {
  //       label: 'Lead',
  //       value: 'Lead'
  //     },
  //   ]
  // },
  // {
  //   tag: 'date',
  //   type: 'text',
  //   name: 'start_date',
  //   placeholder: '',
  //   tooltip: trans('It is necessary exclusively for reports, does not affect the functionality', language),
  //   label: trans('Start date', language),
  //   errorLabel: trans('Enter the correct date', language),
  //   isRequired: false
  // },
  // {
  //   tag: 'date',
  //   type: 'text',
  //   name: 'end_date',
  //   placeholder: '',
  //   label: trans('End date', language),
  //   errorLabel: trans('Enter the correct date', language),
  //   isRequired: false
  // },
  {
    tag: 'input',
    type: 'text',
    name: 'onboarding_url',
    placeholder: '',
    label: trans('Onboarding url', language),
    errorLabel: trans('Enter the correct url', language),
    tooltip: trans('This page will be redirected after clicking on the clickable pixel', language),
    isUrl: true,
    isRequired: false
  },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'custom_parameters',
  //   placeholder: 'Кастомные параметры',
  //   label: 'Кастомные параметры',
  //   errorLabel: trans('The field is required', language),
  //   isRequired: false
  // },
  {
    tag: 'accordion',
    title: 'Расширенные настройки',
    content: [
      {
        tag: 'input',
        type: 'text',
        name: 'campaign_adv_id',
        placeholder: '',
        label: trans('Campaign ID in the advertising system', language),
        tooltip: trans('If your advertising system is present in the list of integrations and you have enabled it, then add the campaign ID to this field and expenses will be raised automatically', language),
        tooltipSide: 'right',
        errorLabel: '',
        isRequired: false
      },
      {
        tag: 'select',
        type: 'text',
        name: 'purchase_type',
        placeholder: '',
        label: trans('Purchase type', language),
        tooltip: trans('Purchase tooltip', language),
        errorLabel: trans('Select the type', language),
        isRequired: false,
        isOpenUp: false,
        options: [
          {
            label: 'CPM',
            value: 'CPM'
          },
          {
            label: 'CPC',
            value: 'CPC'
          },
          {
            label: 'CPV',
            value: 'CPV'
          },
          {
            label: 'FIX',
            value: 'FIX'
          },
        ]
      },
      // {
      //   tag: 'select',
      //   type: 'text',
      //   name: 'event_id',
      //   placeholder: '',
      //   label: trans('Focusing target action', language),
      //   errorLabel: trans('The field is required', language),
      //   tooltip: trans('When choosing a target action in the statistics, you will see the amount of exactly these post view target actions', language),
      //   isRequired: false,
      //   beta: true,
      //   isOpenUp: false,
      //   options: [
      //     {
      //       label: trans('All event targets', language),
      //       value: ''
      //     }
      //   ]
      // },
      {
        tag: 'tags',
        type: 'text',
        name: 'tags',
        placeholder: 'Введите текст для поиска',
        label: trans('Tags', language),
        errorLabel: trans('The field is required', language),
        tooltip: trans('A tag is a free text field that can be used to identify belonging to a group. By this field, you can separate campaigns in the reporting', language),
        isRequired: false
      },
      {
        tag: 'input',
        type: 'textarea',
        name: 'description',
        placeholder: '',
        label: trans('Description', language),
        errorLabel: '',
        isRequired: false
      },
    ]
  }
  // {
  //   tag: 'accordion',
  //   title: trans('Planned indicators', language),
  //   content: [
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'impression_plan',
  //       placeholder: '',
  //       label: trans('Impressions', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'click_plan',
  //       placeholder: '',
  //       label: trans('Clicks', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'cost_plan',
  //       placeholder: '',
  //       label: trans('Budget', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'reach_plan',
  //       placeholder: '',
  //       label: trans('Reach', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'post_view_lead_plan',
  //       placeholder: '',
  //       label: trans('Target events', language),
  //       tooltip: trans('Number of target events', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //   ]
  // },
  // {
  //   tag: 'accordion',
  //   title: 'Настройки атрибуции',
  //   content: [
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'attribute_impression_window',
  //       placeholder: '',
  //       label: 'Окно атрибуции от показов',
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'attribute_click_window',
  //       placeholder: '',
  //       label: 'Окно атрибуции по кликам',
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     // {
  //     //   tag: 'select',
  //     //   type: 'text',
  //     //   name: 'attribution_model',
  //     //   placeholder: '',
  //     //   label: 'Модель атрибуции',
  //     //   errorLabel: 'Выберите модель',
  //     //   isRequired: false,
  //     //   isOpenUp: true,
  //     //   options: [
  //     //     {
  //     //       label: 'TOP',
  //     //       value: 'TOP'
  //     //     },
  //     //     {
  //     //       label: 'Linear',
  //     //       value: 'Linear'
  //     //     },
  //     //     {
  //     //       label: 'Last Impression',
  //     //       value: 'Last Impression'
  //     //     },
  //     //     {
  //     //       label: 'Time Decay',
  //     //       value: 'Time Decay'
  //     //     },
  //     //   ]
  //     // },
  //   ]
  // }
];

export const updateCampaignForm= (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'text',
    name: 'name',
    placeholder: '',
    label: trans('Campaign name', language),
    errorLabel: trans('The field is required', language),
    isRequired: true
  },
  {
    tag: 'select',
    type: 'text',
    name: 'source_id',
    placeholder: '',
    label: trans('Source', language),
    tooltip: trans('The source is an advertising system, which is convenient for grouping reports and looking at intersections between each other. The source can also add default macros to pixels.', language),
    // tooltip: trans(`Your advertising source, for example, Yandex, myTarget or Hybrid`, language),
    errorLabel: trans('Select the source', language),
    isRequired: true,
    isOpenUp: false,
    hasAddButton: true,
    options: [],
  },
  // {
  //   tag: 'select',
  //   type: 'text',
  //   name: 'ads_type',
  //   placeholder: '',
  //   label: trans('Campaign type', language),
  //   errorLabel: trans('Select the type', language),
  //   isRequired: true,
  //   isOpenUp: false,
  //   options: [
  //     {
  //       label: 'Reach',
  //       value: 'Reach'
  //     },
  //     {
  //       label: 'Brand Formance',
  //       value: 'Brand Formance'
  //     },
  //     {
  //       label: 'Lead',
  //       value: 'Lead'
  //     },
  //   ]
  // },
  // {
  //   tag: 'date',
  //   type: 'text',
  //   name: 'start_date',
  //   placeholder: '',
  //   tooltip: trans('It is necessary exclusively for reports, does not affect the functionality', language),
  //   label: trans('Start date', language),
  //   errorLabel: trans('Enter the correct date', language),
  //   isRequired: false
  // },
  // {
  //   tag: 'date',
  //   type: 'text',
  //   name: 'end_date',
  //   placeholder: '',
  //   label: trans('End date', language),
  //   errorLabel: trans('Enter the correct date', language),
  //   isRequired: false
  // },
  {
    tag: 'input',
    type: 'text',
    name: 'onboarding_url',
    placeholder: '',
    label: trans('Onboarding url', language),
    errorLabel: trans('Enter the correct url', language),
    tooltip: trans('This page will be redirected after clicking on the clickable pixel', language),
    isUrl: true,
    isRequired: false
  },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'custom_parameters',
  //   placeholder: 'Кастомные параметры',
  //   label: 'Кастомные параметры',
  //   errorLabel: trans('The field is required', language),
  //   isRequired: false
  // },
  {
    tag: 'accordion',
    title: trans('Advanced Settings', language),
    content: [
      {
        tag: 'input',
        type: 'text',
        name: 'campaign_adv_id',
        placeholder: '',
        label: trans('Campaign ID in the advertising system', language),
        tooltip: trans('If your advertising system is present in the list of integrations and you have enabled it, then add the campaign ID to this field and expenses will be raised automatically', language),
        tooltipSide: 'right',
        errorLabel: '',
        isRequired: false
      },
      {
        tag: 'select',
        type: 'text',
        name: 'purchase_type',
        placeholder: '',
        label: trans('Purchase type', language),
        tooltip: trans('Purchase tooltip', language),
        errorLabel: trans('Select the type', language),
        isRequired: false,
        isOpenUp: false,
        options: [
          {
            label: 'CPM',
            value: 'CPM'
          },
          {
            label: 'CPC',
            value: 'CPC'
          },
          {
            label: 'CPV',
            value: 'CPV'
          },
          {
            label: 'FIX',
            value: 'FIX'
          },
        ]
      },
      // {
      //   tag: 'select',
      //   type: 'text',
      //   name: 'event_id',
      //   placeholder: '',
      //   label: trans('Focusing target action', language),
      //   errorLabel: trans('The field is required', language),
      //   tooltip: trans('When choosing a target action in the statistics, you will see the amount of exactly these post view target actions', language),
      //   isRequired: false,
      //   beta: true,
      //   isOpenUp: false,
      //   options: [
      //     {
      //       label: trans('All event targets', language),
      //       value: ''
      //     }
      //   ]
      // },
      {
        tag: 'tags',
        type: 'text',
        name: 'tags',
        placeholder: 'Введите текст для поиска',
        label: trans('Tags', language),
        errorLabel: trans('The field is required', language),
        tooltip: trans('A tag is a free text field that can be used to identify belonging to a group. By this field, you can separate campaigns in the reporting', language),
        isRequired: false
      },
      {
        tag: 'input',
        type: 'textarea',
        name: 'description',
        placeholder: '',
        label: trans('Description', language),
        errorLabel: '',
        isRequired: false
      },
    ]
  }
  // {
  //   tag: 'accordion',
  //   title: trans('Planned indicators', language),
  //   content: [
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'impression_plan',
  //       placeholder: '',
  //       label: trans('Impressions', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'click_plan',
  //       placeholder: '',
  //       label: trans('Clicks', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'cost_plan',
  //       placeholder: '',
  //       label: trans('Budget', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'reach_plan',
  //       placeholder: '',
  //       label: trans('Reach', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'post_view_lead_plan',
  //       placeholder: '',
  //       label: trans('Target events', language),
  //       tooltip: trans('Number of target events', language),
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //   ]
  // },
  // {
  //   tag: 'accordion',
  //   title: 'Настройки атрибуции',
  //   content: [
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'attribute_impression_window',
  //       placeholder: '',
  //       label: 'Окно атрибуции от показов',
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     },
  //     {
  //       tag: 'input',
  //       type: 'number',
  //       name: 'attribute_click_window',
  //       placeholder: '',
  //       label: 'Окно атрибуции по кликам',
  //       errorLabel: trans('The field is required', language),
  //       isRequired: false
  //     }
  //   ]
  // }
];

export const newPlatformForm= (language?:string, tipGeo?:any, tipTargeting?:any): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'text',
    name: 'name',
    placeholder: '',
    label: trans('Placement name', language),
    errorLabel: trans('The field is required', language),
    isRequired: true
  },
  {
    tag: 'input',
    type: 'text',
    name: 'onboarding_url',
    placeholder: '',
    label: trans('Onboarding url', language),
    errorLabel: trans('Enter the correct url', language),
    isUrl: true,
    isRequired: true
  },
  {
    tag: 'select',
    type: 'text',
    name: 'type',
    placeholder: '',
    label: trans('Pixel type', language),
    errorLabel: trans('Select the pixel type', language),
    isRequired: true,
    isOpenUp: false,
    options: [
      {
        label: trans('Tracking pixel', language),
        value: 'tracking',
      },
      {
        label: trans('Adserving video', language),
        value: 'adserving_video',
      },
      {
        label: trans('Adserving static', language),
        value: 'adserving_static',
      }
    ]
  },
  {
    tag: 'select',
    type: 'text',
    name: 'format',
    placeholder: '',
    label: trans('Format', language),
    errorLabel: trans('Select the format', language),
    isRequired: true,
    isOpenUp: false,
    options: [
      {
        label: trans('JPEG', language),
        value: 'JPEG',
      },
      {
        label: trans('GIF', language),
        value: 'GIF',
      },
      {
        label: trans('HTML', language),
        value: 'HTML',
      },
      {
        label: trans('Video', language),
        value: 'Video',
      },
      {
        label: trans('Other', language),
        value: 'Other',
      }
    ]
  },
  {
    tag: 'input',
    type: 'text',
    name: 'geo',
    placeholder: '',
    label: trans('Geo', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    tip: typeof tipGeo === 'object' ? tipGeo : {}
  },
  {
    tag: 'input',
    type: 'text',
    name: 'targeting',
    placeholder: '',
    label: trans('Targeting', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    tip: typeof tipTargeting === 'object' ? tipTargeting : {}
  },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'creative',
  //   placeholder: '',
  //   label: trans('Creative', language),
  //   errorLabel: trans('The field is required', language),
  //   isRequired: false
  // },
  {
    tag: 'input',
    type: 'text',
    name: 'size',
    placeholder: '',
    label: trans('Creative size', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    tip: {
      '240x400px': '240 x 400 px',
      '300x250px': '300 x 250 px',
      '300x500px': '300 x 500 px',
      '300x600px': '300 x 600 px',
      '336x280px': '336 x 280 px',
      '640x100px': '640 x 100 px',
      '640x200px': '640 x 200 px',
      '640x960px': '640 x 960 px',
      '728x90px': '728 x 90 px',
      '960x640px': '960 x 640 px',
      '970x250px': '970 x 250 px',
      '160x600px': '160 x 600 px',
      '1000x120px': '1000 x 120 px',
      '200x200px': '200 x 200 px',
      '250x250px': '250 x 250 px',
      '250x360px': '250 x 360 px',
      '580x400px': '580 x 400 px',
      '930x180px': '930 x 180 px',
      '970x90px': '970 x 90 px',
      '980x120px': '980 x 120 px',
      '300x1050px': '300 x 1050 px',
      '300x50px': '300 x 50 px',
      '1200x628px': '1200 x 628 px',
      '600x600px': '600 x 600 px',
      '628x1120px': '628 х 1120 px',
      '1920x1080px': '1920 x 1080 px',
      '1080х1920рх': '1080 х 1920 рх',
      '1280х720': '1280 х 720',
      '504х283': '504 х 283',
      '1080x1080px': '1080  x 1080 рх',
      '700x500': '700 x 500',
      '1200x630': '1200 x 630',
    }
  },
  {
    tag: 'input',
    type: 'number',
    name: 'video_duration',
    placeholder: '',
    label: trans('Video duration', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    limitNumber: 10000
  },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'landscape',
  //   placeholder: '',
  //   label: 'Ориентация видео',
  //   errorLabel: trans('The field is required', language),
  //   isRequired: false
  // },
  {
    tag: 'select',
    type: 'text',
    name: 'video_orientation',
    placeholder: '',
    label: trans('Video orientation', language),
    errorLabel: trans('Select the orientation of the video', language),
    isRequired: true,
    isOpenUp: true,
    options:           [
      {
        label: 'vertical',
        value: 'vertical'
      },
      {
        label: 'horizontal',
        value: 'horizontal'
      }
    ]
  },
  {
    tag: 'file',
    type: 'file',
    name: 'video',
    placeholder: '',
    label: trans('video', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    limitNumber: 10000,
    content: ""
  },
  {
    tag: 'file',
    type: 'file',
    name: 'image',
    placeholder: '',
    label: trans('', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    limitNumber: 10000,
    content: ""
  },
  {
    tag: 'input',
    type: 'checkbox',
    name: 'not_clickable',
    placeholder: '',
    label: trans('Not clickable', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'skiptime',
    placeholder: '',
    label: trans('Skip time', language),
    errorLabel: trans('The field is required', language),
    isRequired: false,
    limitNumber: 10000
  },
  {
    tag: 'input',
    type: 'text',
    name: 'creative_view',
    placeholder: '',
    label: trans('Creative view', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'custom_view',
    placeholder: '',
    label: trans('Custom view', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'start',
    placeholder: '',
    label: trans('start', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'first_quartile',
    placeholder: '',
    label: trans('First quartile', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'midpoint',
    placeholder: '',
    label: trans('Midpoint', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'third_quartile',
    placeholder: '',
    label: trans('third_quartile', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'complete',
    placeholder: '',
    label: trans('complete', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'mute',
    placeholder: '',
    label: trans('mute', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'unmute',
    placeholder: '',
    label: trans('unmute', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'pause',
    placeholder: '',
    label: trans('pause', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'resume',
    placeholder: '',
    label: trans('resume', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'skip',
    placeholder: '',
    label: trans('skip', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'player_expand',
    placeholder: '',
    label: trans('player_expand', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'player_collapse',
    placeholder: '',
    label: trans('player_collapse', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'progress',
    placeholder: '',
    label: trans('progress', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'progress_offset',
    placeholder: '',
    label: trans('progress_offset', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'impression',
    placeholder: '',
    label: trans('impression', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'error',
    placeholder: '',
    label: trans('error', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'click',
    placeholder: '',
    label: trans('click', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'cv_delay',
    placeholder: '',
    label: trans('cv_delay', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'cv_per',
    placeholder: '',
    label: trans('cv_per', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'cv_psc',
    placeholder: '',
    label: trans('cv_psc', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'cv_pcsv',
    placeholder: '',
    label: trans('cv_pcsv', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'cv_pcsv',
    placeholder: '',
    label: trans('cv_pcsv', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'number',
    name: 'cv_ums',
    placeholder: '',
    label: trans('cv_ums', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_erid',
    placeholder: '',
    label: trans('adv_erid', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_title',
    placeholder: '',
    label: trans('adv_title', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_name',
    placeholder: '',
    label: trans('adv_name', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_address',
    placeholder: '',
    label: trans('adv_address', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_inn',
    placeholder: '',
    label: trans('adv_inn', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_ogrn',
    placeholder: '',
    label: trans('adv_ogrn', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'adv_url',
    placeholder: '',
    label: trans('adv_url', language),
    errorLabel: trans('Enter the correct url', language),
    isRequired: false,
    isUrl: true
  },
  {
    tag: 'input',
    type: 'number',
    name: 'adv_delay',
    placeholder: '',
    label: trans('adv_delay', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
  {
    tag: 'input',
    type: 'text',
    name: 'bld',
    placeholder: '',
    label: trans('Domain', language),
    errorLabel: trans('The field is required', language),
    isRequired: false
  },
];

export const newSourceModal= (language?:string, baseSources?: BaseSource[], edit?:boolean): (IField | IAccordion)[] => {
  const temp:(IField | IAccordion)[] = [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      placeholder: '',
      label: trans('Naming', language),
      tooltip: trans('The name of the source will be displayed in reports and filters, for convenient grouping of campaigns and comparison among themselves', language),
      errorLabel: trans('The field is required', language),
      isRequired: true
    },
    // {
    //   tag: 'input',
    //   type: 'text',
    //   name: 'domain',
    //   placeholder: '',
    //   label: trans('Domain', language),
    //   errorLabel: trans('Enter the correct url', language),
    //   isUrl: true,
    //   isRequired: true
    // },
    {
      tag: 'input',
      type: 'text',
      name: 'click_params',
      placeholder: '',
      label: trans('Parameters per click', language),
      tooltip: trans('Dynamic parameters, in the format param={value}&param2={value2}, which will be inserted into all click links from this source. Macros from advertising systems are added to this field', language),
      errorLabel: trans('Incorrect value', language),
      isRequired: false
    },
    {
      tag: 'input',
      type: 'text',
      name: 'impression_params',
      placeholder: '',
      label: trans('Parameters per impression', language),
      tooltip: trans('Dynamic parameters, in the format param={value}&param2={value2}, which will be substituted in all pixels for impression at this source. Macros from advertising systems are added to this field', language),
      errorLabel: trans('Incorrect value', language),
      isRequired: false
    },
  ];
  if(Array.isArray(baseSources) && baseSources.length > 0) {
    const baseSourceDataset:DefaultObject<string> = {};
    const baseSourceInsertInOtherField:DefaultObject<any> = {
      '0': {
        'click_params': '',
        'impression_params': ''
      }
    };
    baseSources.map((item)=> {
      baseSourceDataset[`${item.id}`] = item.name;
      baseSourceInsertInOtherField[`${item.id}`] = {
        'click_params': item.click_params,
        'impression_params': item.impression_params
      };
    })
    temp.splice(1,0,{
      tag: 'select_new',
      type: 'text',
      name: 'base_source_id',
      placeholder: trans('Without preset', language),
      label: trans('Create based on', language),
      tooltip: '',
      errorLabel: '',
      isRequired: false,
      isOpenUp: false,
      isSearch: true,
      dataset: baseSourceDataset,
      insertInOtherField: baseSourceInsertInOtherField,
      resetBtn: true,
      isDisabled: edit
    })
  }
  return temp;
}

// export const campaignEditingModal= (language?:string): (IField | IAccordion)[] => [
//   {
//     tag: 'input',
//     type: 'text',
//     name: 'name',
//     placeholder: '',
//     label: trans('Naming', language),
//     errorLabel: trans('The field is required', language),
//     isRequired: true
//   },
//   {
//     tag: 'date',
//     type: 'text',
//     name: 'end_date',
//     placeholder: '',
//     label: 'Дата Окончания',
//     errorLabel: 'Введите корректную дату',
//     isRequired: true
//   },
// ];

export const nameEditingFields= (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'text',
    name: 'first_name',
    placeholder: trans('Name', language),
    label: trans('Name', language),
    errorLabel: trans('The field is required', language),
    isRequired: true
  },
  // {
  //   tag: 'input',
  //   type: 'text',
  //   name: 'last_name',
  //   placeholder: 'Фамилия',
  //   label: 'Фамилия',
  //   errorLabel: trans('The field is required', language),
  //   isRequired: false
  // },
];

export const emailEditingFields = (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'email',
    name: 'email',
    placeholder: trans('New E-mail', language),
    label: trans('New E-mail', language),
    errorLabel: trans('Enter the correct E-mail', language),
    isRequired: true
  },
  {
    tag: 'input',
    type: 'password',
    name: 'password',
    placeholder: trans('Your password', language),
    label: trans('Password', language),
    errorLabel: trans('Empty password', language),
    isRequired: true,
    isPasswordSwitch: true
  }
];

export const passwordEditingFields = (language?:string): (IField | IAccordion)[] => [
  {
    tag: 'input',
    type: 'password',
    name: 'current_password',
    placeholder: trans('Your password', language),
    label: trans('Current password', language),
    errorLabel: trans('Empty password', language),
    isRequired: true,
    isPasswordSwitch: true,
    subBtn: {
      text: trans('Forgot password?', language)
    }
  },
  {
    tag: 'input',
    type: 'password',
    name: 'password',
    placeholder: trans('New password', language),
    label: trans('New password', language),
    errorLabel: trans('Enter the password from 6 to 50 characters', language),
    isRequired: true,
    isPasswordSwitch: true
  },
  {
    tag: 'input',
    type: 'password',
    name: 'repeat_password',
    placeholder: trans('Repeat password', language),
    label: trans('Repeat password', language),
    errorLabel: trans('Enter the correct password', language),
    isRequired: true,
    isPasswordSwitch: true
  }
];

export const TIMEZONES = {
      "Africa/Abidjan": "Africa/Abidjan",
      "Africa/Accra": "Africa/Accra",
      "Africa/Addis_Ababa": "Africa/Addis_Ababa",
      "Africa/Algiers": "Africa/Algiers",
      "Africa/Asmara": "Africa/Asmara",
      "Africa/Asmera": "Africa/Asmera",
      "Africa/Bamako": "Africa/Bamako",
      "Africa/Bangui": "Africa/Bangui",
      "Africa/Banjul": "Africa/Banjul",
      "Africa/Bissau": "Africa/Bissau",
      "Africa/Blantyre": "Africa/Blantyre",
      "Africa/Brazzaville": "Africa/Brazzaville",
      "Africa/Bujumbura": "Africa/Bujumbura",
      "Africa/Cairo": "Africa/Cairo",
      "Africa/Casablanca": "Africa/Casablanca",
      "Africa/Ceuta": "Africa/Ceuta",
      "Africa/Conakry": "Africa/Conakry",
      "Africa/Dakar": "Africa/Dakar",
      "Africa/Dar_es_Salaam": "Africa/Dar_es_Salaam",
      "Africa/Djibouti": "Africa/Djibouti",
      "Africa/Douala": "Africa/Douala",
      "Africa/El_Aaiun": "Africa/El_Aaiun",
      "Africa/Freetown": "Africa/Freetown",
      "Africa/Gaborone": "Africa/Gaborone",
      "Africa/Harare": "Africa/Harare",
      "Africa/Johannesburg": "Africa/Johannesburg",
      "Africa/Juba": "Africa/Juba",
      "Africa/Kampala": "Africa/Kampala",
      "Africa/Khartoum": "Africa/Khartoum",
      "Africa/Kigali": "Africa/Kigali",
      "Africa/Kinshasa": "Africa/Kinshasa",
      "Africa/Lagos": "Africa/Lagos",
      "Africa/Libreville": "Africa/Libreville",
      "Africa/Lome": "Africa/Lome",
      "Africa/Luanda": "Africa/Luanda",
      "Africa/Lubumbashi": "Africa/Lubumbashi",
      "Africa/Lusaka": "Africa/Lusaka",
      "Africa/Malabo": "Africa/Malabo",
      "Africa/Maputo": "Africa/Maputo",
      "Africa/Maseru": "Africa/Maseru",
      "Africa/Mbabane": "Africa/Mbabane",
      "Africa/Mogadishu": "Africa/Mogadishu",
      "Africa/Monrovia": "Africa/Monrovia",
      "Africa/Nairobi": "Africa/Nairobi",
      "Africa/Ndjamena": "Africa/Ndjamena",
      "Africa/Niamey": "Africa/Niamey",
      "Africa/Nouakchott": "Africa/Nouakchott",
      "Africa/Ouagadougou": "Africa/Ouagadougou",
      "Africa/Porto-Novo": "Africa/Porto-Novo",
      "Africa/Sao_Tome": "Africa/Sao_Tome",
      "Africa/Timbuktu": "Africa/Timbuktu",
      "Africa/Tripoli": "Africa/Tripoli",
      "Africa/Tunis": "Africa/Tunis",
      "Africa/Windhoek": "Africa/Windhoek",
      "America/Adak": "America/Adak",
      "America/Anchorage": "America/Anchorage",
      "America/Anguilla": "America/Anguilla",
      "America/Antigua": "America/Antigua",
      "America/Araguaina": "America/Araguaina",
      "America/Argentina/Buenos_Aires": "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca": "America/Argentina/Catamarca",
      "America/Argentina/ComodRivadavia": "America/Argentina/ComodRivadavia",
      "America/Argentina/Cordoba": "America/Argentina/Cordoba",
      "America/Argentina/Jujuy": "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja": "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza": "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos": "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta": "America/Argentina/Salta",
      "America/Argentina/San_Juan": "America/Argentina/San_Juan",
      "America/Argentina/San_Luis": "America/Argentina/San_Luis",
      "America/Argentina/Tucuman": "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia": "America/Argentina/Ushuaia",
      "America/Aruba": "America/Aruba",
      "America/Asuncion": "America/Asuncion",
      "America/Atikokan": "America/Atikokan",
      "America/Atka": "America/Atka",
      "America/Bahia": "America/Bahia",
      "America/Bahia_Banderas": "America/Bahia_Banderas",
      "America/Barbados": "America/Barbados",
      "America/Belem": "America/Belem",
      "America/Belize": "America/Belize",
      "America/Blanc-Sablon": "America/Blanc-Sablon",
      "America/Boa_Vista": "America/Boa_Vista",
      "America/Bogota": "America/Bogota",
      "America/Boise": "America/Boise",
      "America/Buenos_Aires": "America/Buenos_Aires",
      "America/Cambridge_Bay": "America/Cambridge_Bay",
      "America/Campo_Grande": "America/Campo_Grande",
      "America/Cancun": "America/Cancun",
      "America/Caracas": "America/Caracas",
      "America/Catamarca": "America/Catamarca",
      "America/Cayenne": "America/Cayenne",
      "America/Cayman": "America/Cayman",
      "America/Chicago": "America/Chicago",
      "America/Chihuahua": "America/Chihuahua",
      "America/Coral_Harbour": "America/Coral_Harbour",
      "America/Cordoba": "America/Cordoba",
      "America/Costa_Rica": "America/Costa_Rica",
      "America/Creston": "America/Creston",
      "America/Cuiaba": "America/Cuiaba",
      "America/Curacao": "America/Curacao",
      "America/Danmarkshavn": "America/Danmarkshavn",
      "America/Dawson": "America/Dawson",
      "America/Dawson_Creek": "America/Dawson_Creek",
      "America/Denver": "America/Denver",
      "America/Detroit": "America/Detroit",
      "America/Dominica": "America/Dominica",
      "America/Edmonton": "America/Edmonton",
      "America/Eirunepe": "America/Eirunepe",
      "America/El_Salvador": "America/El_Salvador",
      "America/Ensenada": "America/Ensenada",
      "America/Fort_Nelson": "America/Fort_Nelson",
      "America/Fort_Wayne": "America/Fort_Wayne",
      "America/Fortaleza": "America/Fortaleza",
      "America/Glace_Bay": "America/Glace_Bay",
      "America/Godthab": "America/Godthab",
      "America/Goose_Bay": "America/Goose_Bay",
      "America/Grand_Turk": "America/Grand_Turk",
      "America/Grenada": "America/Grenada",
      "America/Guadeloupe": "America/Guadeloupe",
      "America/Guatemala": "America/Guatemala",
      "America/Guayaquil": "America/Guayaquil",
      "America/Guyana": "America/Guyana",
      "America/Halifax": "America/Halifax",
      "America/Havana": "America/Havana",
      "America/Hermosillo": "America/Hermosillo",
      "America/Indiana/Indianapolis": "America/Indiana/Indianapolis",
      "America/Indiana/Knox": "America/Indiana/Knox",
      "America/Indiana/Marengo": "America/Indiana/Marengo",
      "America/Indiana/Petersburg": "America/Indiana/Petersburg",
      "America/Indiana/Tell_City": "America/Indiana/Tell_City",
      "America/Indiana/Vevay": "America/Indiana/Vevay",
      "America/Indiana/Vincennes": "America/Indiana/Vincennes",
      "America/Indiana/Winamac": "America/Indiana/Winamac",
      "America/Indianapolis": "America/Indianapolis",
      "America/Inuvik": "America/Inuvik",
      "America/Iqaluit": "America/Iqaluit",
      "America/Jamaica": "America/Jamaica",
      "America/Jujuy": "America/Jujuy",
      "America/Juneau": "America/Juneau",
      "America/Kentucky/Louisville": "America/Kentucky/Louisville",
      "America/Kentucky/Monticello": "America/Kentucky/Monticello",
      "America/Knox_IN": "America/Knox_IN",
      "America/Kralendijk": "America/Kralendijk",
      "America/La_Paz": "America/La_Paz",
      "America/Lima": "America/Lima",
      "America/Los_Angeles": "America/Los_Angeles",
      "America/Louisville": "America/Louisville",
      "America/Lower_Princes": "America/Lower_Princes",
      "America/Maceio": "America/Maceio",
      "America/Managua": "America/Managua",
      "America/Manaus": "America/Manaus",
      "America/Marigot": "America/Marigot",
      "America/Martinique": "America/Martinique",
      "America/Matamoros": "America/Matamoros",
      "America/Mazatlan": "America/Mazatlan",
      "America/Mendoza": "America/Mendoza",
      "America/Menominee": "America/Menominee",
      "America/Merida": "America/Merida",
      "America/Metlakatla": "America/Metlakatla",
      "America/Mexico_City": "America/Mexico_City",
      "America/Miquelon": "America/Miquelon",
      "America/Moncton": "America/Moncton",
      "America/Monterrey": "America/Monterrey",
      "America/Montevideo": "America/Montevideo",
      "America/Montreal": "America/Montreal",
      "America/Montserrat": "America/Montserrat",
      "America/Nassau": "America/Nassau",
      "America/New_York": "America/New_York",
      "America/Nipigon": "America/Nipigon",
      "America/Nome": "America/Nome",
      "America/Noronha": "America/Noronha",
      "America/North_Dakota/Beulah": "America/North_Dakota/Beulah",
      "America/North_Dakota/Center": "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem": "America/North_Dakota/New_Salem",
      "America/Nuuk": "America/Nuuk",
      "America/Ojinaga": "America/Ojinaga",
      "America/Panama": "America/Panama",
      "America/Pangnirtung": "America/Pangnirtung",
      "America/Paramaribo": "America/Paramaribo",
      "America/Phoenix": "America/Phoenix",
      "America/Port-au-Prince": "America/Port-au-Prince",
      "America/Port_of_Spain": "America/Port_of_Spain",
      "America/Porto_Acre": "America/Porto_Acre",
      "America/Porto_Velho": "America/Porto_Velho",
      "America/Puerto_Rico": "America/Puerto_Rico",
      "America/Punta_Arenas": "America/Punta_Arenas",
      "America/Rainy_River": "America/Rainy_River",
      "America/Rankin_Inlet": "America/Rankin_Inlet",
      "America/Recife": "America/Recife",
      "America/Regina": "America/Regina",
      "America/Resolute": "America/Resolute",
      "America/Rio_Branco": "America/Rio_Branco",
      "America/Rosario": "America/Rosario",
      "America/Santa_Isabel": "America/Santa_Isabel",
      "America/Santarem": "America/Santarem",
      "America/Santiago": "America/Santiago",
      "America/Santo_Domingo": "America/Santo_Domingo",
      "America/Sao_Paulo": "America/Sao_Paulo",
      "America/Scoresbysund": "America/Scoresbysund",
      "America/Shiprock": "America/Shiprock",
      "America/Sitka": "America/Sitka",
      "America/St_Barthelemy": "America/St_Barthelemy",
      "America/St_Johns": "America/St_Johns",
      "America/St_Kitts": "America/St_Kitts",
      "America/St_Lucia": "America/St_Lucia",
      "America/St_Thomas": "America/St_Thomas",
      "America/St_Vincent": "America/St_Vincent",
      "America/Swift_Current": "America/Swift_Current",
      "America/Tegucigalpa": "America/Tegucigalpa",
      "America/Thule": "America/Thule",
      "America/Thunder_Bay": "America/Thunder_Bay",
      "America/Tijuana": "America/Tijuana",
      "America/Toronto": "America/Toronto",
      "America/Tortola": "America/Tortola",
      "America/Vancouver": "America/Vancouver",
      "America/Virgin": "America/Virgin",
      "America/Whitehorse": "America/Whitehorse",
      "America/Winnipeg": "America/Winnipeg",
      "America/Yakutat": "America/Yakutat",
      "America/Yellowknife": "America/Yellowknife",
      "Antarctica/Casey": "Antarctica/Casey",
      "Antarctica/Davis": "Antarctica/Davis",
      "Antarctica/DumontDUrville": "Antarctica/DumontDUrville",
      "Antarctica/Macquarie": "Antarctica/Macquarie",
      "Antarctica/Mawson": "Antarctica/Mawson",
      "Antarctica/McMurdo": "Antarctica/McMurdo",
      "Antarctica/Palmer": "Antarctica/Palmer",
      "Antarctica/Rothera": "Antarctica/Rothera",
      "Antarctica/South_Pole": "Antarctica/South_Pole",
      "Antarctica/Syowa": "Antarctica/Syowa",
      "Antarctica/Troll": "Antarctica/Troll",
      "Antarctica/Vostok": "Antarctica/Vostok",
      "Arctic/Longyearbyen": "Arctic/Longyearbyen",
      "Asia/Aden": "Asia/Aden",
      "Asia/Almaty": "Asia/Almaty",
      "Asia/Amman": "Asia/Amman",
      "Asia/Anadyr": "Asia/Anadyr",
      "Asia/Aqtau": "Asia/Aqtau",
      "Asia/Aqtobe": "Asia/Aqtobe",
      "Asia/Ashgabat": "Asia/Ashgabat",
      "Asia/Ashkhabad": "Asia/Ashkhabad",
      "Asia/Atyrau": "Asia/Atyrau",
      "Asia/Baghdad": "Asia/Baghdad",
      "Asia/Bahrain": "Asia/Bahrain",
      "Asia/Baku": "Asia/Baku",
      "Asia/Bangkok": "Asia/Bangkok",
      "Asia/Barnaul": "Asia/Barnaul",
      "Asia/Beirut": "Asia/Beirut",
      "Asia/Bishkek": "Asia/Bishkek",
      "Asia/Brunei": "Asia/Brunei",
      "Asia/Calcutta": "Asia/Calcutta",
      "Asia/Chita": "Asia/Chita",
      "Asia/Choibalsan": "Asia/Choibalsan",
      "Asia/Chongqing": "Asia/Chongqing",
      "Asia/Chungking": "Asia/Chungking",
      "Asia/Colombo": "Asia/Colombo",
      "Asia/Dacca": "Asia/Dacca",
      "Asia/Damascus": "Asia/Damascus",
      "Asia/Dhaka": "Asia/Dhaka",
      "Asia/Dili": "Asia/Dili",
      "Asia/Dubai": "Asia/Dubai",
      "Asia/Dushanbe": "Asia/Dushanbe",
      "Asia/Famagusta": "Asia/Famagusta",
      "Asia/Gaza": "Asia/Gaza",
      "Asia/Harbin": "Asia/Harbin",
      "Asia/Hebron": "Asia/Hebron",
      "Asia/Ho_Chi_Minh": "Asia/Ho_Chi_Minh",
      "Asia/Hong_Kong": "Asia/Hong_Kong",
      "Asia/Hovd": "Asia/Hovd",
      "Asia/Irkutsk": "Asia/Irkutsk",
      "Asia/Istanbul": "Asia/Istanbul",
      "Asia/Jakarta": "Asia/Jakarta",
      "Asia/Jayapura": "Asia/Jayapura",
      "Asia/Jerusalem": "Asia/Jerusalem",
      "Asia/Kabul": "Asia/Kabul",
      "Asia/Kamchatka": "Asia/Kamchatka",
      "Asia/Karachi": "Asia/Karachi",
      "Asia/Kashgar": "Asia/Kashgar",
      "Asia/Kathmandu": "Asia/Kathmandu",
      "Asia/Katmandu": "Asia/Katmandu",
      "Asia/Khandyga": "Asia/Khandyga",
      "Asia/Kolkata": "Asia/Kolkata",
      "Asia/Krasnoyarsk": "Asia/Krasnoyarsk",
      "Asia/Kuala_Lumpur": "Asia/Kuala_Lumpur",
      "Asia/Kuching": "Asia/Kuching",
      "Asia/Kuwait": "Asia/Kuwait",
      "Asia/Macao": "Asia/Macao",
      "Asia/Macau": "Asia/Macau",
      "Asia/Magadan": "Asia/Magadan",
      "Asia/Makassar": "Asia/Makassar",
      "Asia/Manila": "Asia/Manila",
      "Asia/Muscat": "Asia/Muscat",
      "Asia/Nicosia": "Asia/Nicosia",
      "Asia/Novokuznetsk": "Asia/Novokuznetsk",
      "Asia/Novosibirsk": "Asia/Novosibirsk",
      "Asia/Omsk": "Asia/Omsk",
      "Asia/Oral": "Asia/Oral",
      "Asia/Phnom_Penh": "Asia/Phnom_Penh",
      "Asia/Pontianak": "Asia/Pontianak",
      "Asia/Pyongyang": "Asia/Pyongyang",
      "Asia/Qatar": "Asia/Qatar",
      "Asia/Qostanay": "Asia/Qostanay",
      "Asia/Qyzylorda": "Asia/Qyzylorda",
      "Asia/Rangoon": "Asia/Rangoon",
      "Asia/Riyadh": "Asia/Riyadh",
      "Asia/Saigon": "Asia/Saigon",
      "Asia/Sakhalin": "Asia/Sakhalin",
      "Asia/Samarkand": "Asia/Samarkand",
      "Asia/Seoul": "Asia/Seoul",
      "Asia/Shanghai": "Asia/Shanghai",
      "Asia/Singapore": "Asia/Singapore",
      "Asia/Srednekolymsk": "Asia/Srednekolymsk",
      "Asia/Taipei": "Asia/Taipei",
      "Asia/Tashkent": "Asia/Tashkent",
      "Asia/Tbilisi": "Asia/Tbilisi",
      "Asia/Tehran": "Asia/Tehran",
      "Asia/Tel_Aviv": "Asia/Tel_Aviv",
      "Asia/Thimbu": "Asia/Thimbu",
      "Asia/Thimphu": "Asia/Thimphu",
      "Asia/Tokyo": "Asia/Tokyo",
      "Asia/Tomsk": "Asia/Tomsk",
      "Asia/Ujung_Pandang": "Asia/Ujung_Pandang",
      "Asia/Ulaanbaatar": "Asia/Ulaanbaatar",
      "Asia/Ulan_Bator": "Asia/Ulan_Bator",
      "Asia/Urumqi": "Asia/Urumqi",
      "Asia/Ust-Nera": "Asia/Ust-Nera",
      "Asia/Vientiane": "Asia/Vientiane",
      "Asia/Vladivostok": "Asia/Vladivostok",
      "Asia/Yakutsk": "Asia/Yakutsk",
      "Asia/Yangon": "Asia/Yangon",
      "Asia/Yekaterinburg": "Asia/Yekaterinburg",
      "Asia/Yerevan": "Asia/Yerevan",
      "Atlantic/Azores": "Atlantic/Azores",
      "Atlantic/Bermuda": "Atlantic/Bermuda",
      "Atlantic/Canary": "Atlantic/Canary",
      "Atlantic/Cape_Verde": "Atlantic/Cape_Verde",
      "Atlantic/Faeroe": "Atlantic/Faeroe",
      "Atlantic/Faroe": "Atlantic/Faroe",
      "Atlantic/Jan_Mayen": "Atlantic/Jan_Mayen",
      "Atlantic/Madeira": "Atlantic/Madeira",
      "Atlantic/Reykjavik": "Atlantic/Reykjavik",
      "Atlantic/South_Georgia": "Atlantic/South_Georgia",
      "Atlantic/St_Helena": "Atlantic/St_Helena",
      "Atlantic/Stanley": "Atlantic/Stanley",
      "Australia/ACT": "Australia/ACT",
      "Australia/Adelaide": "Australia/Adelaide",
      "Australia/Brisbane": "Australia/Brisbane",
      "Australia/Broken_Hill": "Australia/Broken_Hill",
      "Australia/Canberra": "Australia/Canberra",
      "Australia/Currie": "Australia/Currie",
      "Australia/Darwin": "Australia/Darwin",
      "Australia/Eucla": "Australia/Eucla",
      "Australia/Hobart": "Australia/Hobart",
      "Australia/LHI": "Australia/LHI",
      "Australia/Lindeman": "Australia/Lindeman",
      "Australia/Lord_Howe": "Australia/Lord_Howe",
      "Australia/Melbourne": "Australia/Melbourne",
      "Australia/NSW": "Australia/NSW",
      "Australia/North": "Australia/North",
      "Australia/Perth": "Australia/Perth",
      "Australia/Queensland": "Australia/Queensland",
      "Australia/South": "Australia/South",
      "Australia/Sydney": "Australia/Sydney",
      "Australia/Tasmania": "Australia/Tasmania",
      "Australia/Victoria": "Australia/Victoria",
      "Australia/West": "Australia/West",
      "Australia/Yancowinna": "Australia/Yancowinna",
      "Brazil/Acre": "Brazil/Acre",
      "Brazil/DeNoronha": "Brazil/DeNoronha",
      "Brazil/East": "Brazil/East",
      "Brazil/West": "Brazil/West",
      "CET": "CET",
      "CST6CDT": "CST6CDT",
      "Canada/Atlantic": "Canada/Atlantic",
      "Canada/Central": "Canada/Central",
      "Canada/Eastern": "Canada/Eastern",
      "Canada/Mountain": "Canada/Mountain",
      "Canada/Newfoundland": "Canada/Newfoundland",
      "Canada/Pacific": "Canada/Pacific",
      "Canada/Saskatchewan": "Canada/Saskatchewan",
      "Canada/Yukon": "Canada/Yukon",
      "Chile/Continental": "Chile/Continental",
      "Chile/EasterIsland": "Chile/EasterIsland",
      "Cuba": "Cuba",
      "EET": "EET",
      "EST": "EST",
      "EST5EDT": "EST5EDT",
      "Egypt": "Egypt",
      "Eire": "Eire",
      "Etc/GMT": "Etc/GMT",
      "Etc/GMT+0": "Etc/GMT+0",
      "Etc/GMT+1": "Etc/GMT+1",
      "Etc/GMT+10": "Etc/GMT+10",
      "Etc/GMT+11": "Etc/GMT+11",
      "Etc/GMT+12": "Etc/GMT+12",
      "Etc/GMT+2": "Etc/GMT+2",
      "Etc/GMT+3": "Etc/GMT+3",
      "Etc/GMT+4": "Etc/GMT+4",
      "Etc/GMT+5": "Etc/GMT+5",
      "Etc/GMT+6": "Etc/GMT+6",
      "Etc/GMT+7": "Etc/GMT+7",
      "Etc/GMT+8": "Etc/GMT+8",
      "Etc/GMT+9": "Etc/GMT+9",
      "Etc/GMT-0": "Etc/GMT-0",
      "Etc/GMT-1": "Etc/GMT-1",
      "Etc/GMT-10": "Etc/GMT-10",
      "Etc/GMT-11": "Etc/GMT-11",
      "Etc/GMT-12": "Etc/GMT-12",
      "Etc/GMT-13": "Etc/GMT-13",
      "Etc/GMT-14": "Etc/GMT-14",
      "Etc/GMT-2": "Etc/GMT-2",
      "Etc/GMT-3": "Etc/GMT-3",
      "Etc/GMT-4": "Etc/GMT-4",
      "Etc/GMT-5": "Etc/GMT-5",
      "Etc/GMT-6": "Etc/GMT-6",
      "Etc/GMT-7": "Etc/GMT-7",
      "Etc/GMT-8": "Etc/GMT-8",
      "Etc/GMT-9": "Etc/GMT-9",
      "Etc/GMT0": "Etc/GMT0",
      "Etc/Greenwich": "Etc/Greenwich",
      "Etc/UCT": "Etc/UCT",
      "Etc/UTC": "Etc/UTC",
      "Etc/Universal": "Etc/Universal",
      "Etc/Zulu": "Etc/Zulu",
      "Europe/Amsterdam": "Europe/Amsterdam",
      "Europe/Andorra": "Europe/Andorra",
      "Europe/Astrakhan": "Europe/Astrakhan",
      "Europe/Athens": "Europe/Athens",
      "Europe/Belfast": "Europe/Belfast",
      "Europe/Belgrade": "Europe/Belgrade",
      "Europe/Berlin": "Europe/Berlin",
      "Europe/Bratislava": "Europe/Bratislava",
      "Europe/Brussels": "Europe/Brussels",
      "Europe/Bucharest": "Europe/Bucharest",
      "Europe/Budapest": "Europe/Budapest",
      "Europe/Busingen": "Europe/Busingen",
      "Europe/Chisinau": "Europe/Chisinau",
      "Europe/Copenhagen": "Europe/Copenhagen",
      "Europe/Dublin": "Europe/Dublin",
      "Europe/Gibraltar": "Europe/Gibraltar",
      "Europe/Guernsey": "Europe/Guernsey",
      "Europe/Helsinki": "Europe/Helsinki",
      "Europe/Isle_of_Man": "Europe/Isle_of_Man",
      "Europe/Istanbul": "Europe/Istanbul",
      "Europe/Jersey": "Europe/Jersey",
      "Europe/Kaliningrad": "Europe/Kaliningrad",
      "Europe/Kiev": "Europe/Kiev",
      "Europe/Kirov": "Europe/Kirov",
      "Europe/Kyiv": "Europe/Kyiv",
      "Europe/Lisbon": "Europe/Lisbon",
      "Europe/Ljubljana": "Europe/Ljubljana",
      "Europe/London": "Europe/London",
      "Europe/Luxembourg": "Europe/Luxembourg",
      "Europe/Madrid": "Europe/Madrid",
      "Europe/Malta": "Europe/Malta",
      "Europe/Mariehamn": "Europe/Mariehamn",
      "Europe/Minsk": "Europe/Minsk",
      "Europe/Monaco": "Europe/Monaco",
      "Europe/Moscow": "Europe/Moscow",
      "Europe/Nicosia": "Europe/Nicosia",
      "Europe/Oslo": "Europe/Oslo",
      "Europe/Paris": "Europe/Paris",
      "Europe/Podgorica": "Europe/Podgorica",
      "Europe/Prague": "Europe/Prague",
      "Europe/Riga": "Europe/Riga",
      "Europe/Rome": "Europe/Rome",
      "Europe/Samara": "Europe/Samara",
      "Europe/San_Marino": "Europe/San_Marino",
      "Europe/Sarajevo": "Europe/Sarajevo",
      "Europe/Saratov": "Europe/Saratov",
      "Europe/Simferopol": "Europe/Simferopol",
      "Europe/Skopje": "Europe/Skopje",
      "Europe/Sofia": "Europe/Sofia",
      "Europe/Stockholm": "Europe/Stockholm",
      "Europe/Tallinn": "Europe/Tallinn",
      "Europe/Tirane": "Europe/Tirane",
      "Europe/Tiraspol": "Europe/Tiraspol",
      "Europe/Ulyanovsk": "Europe/Ulyanovsk",
      "Europe/Uzhgorod": "Europe/Uzhgorod",
      "Europe/Vaduz": "Europe/Vaduz",
      "Europe/Vatican": "Europe/Vatican",
      "Europe/Vienna": "Europe/Vienna",
      "Europe/Vilnius": "Europe/Vilnius",
      "Europe/Volgograd": "Europe/Volgograd",
      "Europe/Warsaw": "Europe/Warsaw",
      "Europe/Zagreb": "Europe/Zagreb",
      "Europe/Zaporozhye": "Europe/Zaporozhye",
      "Europe/Zurich": "Europe/Zurich",
      "Factory": "Factory",
      "GB": "GB",
      "GB-Eire": "GB-Eire",
      "GMT": "GMT",
      "GMT+0": "GMT+0",
      "GMT-0": "GMT-0",
      "GMT0": "GMT0",
      "Greenwich": "Greenwich",
      "HST": "HST",
      "Hongkong": "Hongkong",
      "Iceland": "Iceland",
      "Indian/Antananarivo": "Indian/Antananarivo",
      "Indian/Chagos": "Indian/Chagos",
      "Indian/Christmas": "Indian/Christmas",
      "Indian/Cocos": "Indian/Cocos",
      "Indian/Comoro": "Indian/Comoro",
      "Indian/Kerguelen": "Indian/Kerguelen",
      "Indian/Mahe": "Indian/Mahe",
      "Indian/Maldives": "Indian/Maldives",
      "Indian/Mauritius": "Indian/Mauritius",
      "Indian/Mayotte": "Indian/Mayotte",
      "Indian/Reunion": "Indian/Reunion",
      "Iran": "Iran",
      "Israel": "Israel",
      "Jamaica": "Jamaica",
      "Japan": "Japan",
      "Kwajalein": "Kwajalein",
      "Libya": "Libya",
      "MET": "MET",
      "MST": "MST",
      "MST7MDT": "MST7MDT",
      "Mexico/BajaNorte": "Mexico/BajaNorte",
      "Mexico/BajaSur": "Mexico/BajaSur",
      "Mexico/General": "Mexico/General",
      "NZ": "NZ",
      "NZ-CHAT": "NZ-CHAT",
      "Navajo": "Navajo",
      "PRC": "PRC",
      "PST8PDT": "PST8PDT",
      "Pacific/Apia": "Pacific/Apia",
      "Pacific/Auckland": "Pacific/Auckland",
      "Pacific/Bougainville": "Pacific/Bougainville",
      "Pacific/Chatham": "Pacific/Chatham",
      "Pacific/Chuuk": "Pacific/Chuuk",
      "Pacific/Easter": "Pacific/Easter",
      "Pacific/Efate": "Pacific/Efate",
      "Pacific/Enderbury": "Pacific/Enderbury",
      "Pacific/Fakaofo": "Pacific/Fakaofo",
      "Pacific/Fiji": "Pacific/Fiji",
      "Pacific/Funafuti": "Pacific/Funafuti",
      "Pacific/Galapagos": "Pacific/Galapagos",
      "Pacific/Gambier": "Pacific/Gambier",
      "Pacific/Guadalcanal": "Pacific/Guadalcanal",
      "Pacific/Guam": "Pacific/Guam",
      "Pacific/Honolulu": "Pacific/Honolulu",
      "Pacific/Johnston": "Pacific/Johnston",
      "Pacific/Kanton": "Pacific/Kanton",
      "Pacific/Kiritimati": "Pacific/Kiritimati",
      "Pacific/Kosrae": "Pacific/Kosrae",
      "Pacific/Kwajalein": "Pacific/Kwajalein",
      "Pacific/Majuro": "Pacific/Majuro",
      "Pacific/Marquesas": "Pacific/Marquesas",
      "Pacific/Midway": "Pacific/Midway",
      "Pacific/Nauru": "Pacific/Nauru",
      "Pacific/Niue": "Pacific/Niue",
      "Pacific/Norfolk": "Pacific/Norfolk",
      "Pacific/Noumea": "Pacific/Noumea",
      "Pacific/Pago_Pago": "Pacific/Pago_Pago",
      "Pacific/Palau": "Pacific/Palau",
      "Pacific/Pitcairn": "Pacific/Pitcairn",
      "Pacific/Pohnpei": "Pacific/Pohnpei",
      "Pacific/Ponape": "Pacific/Ponape",
      "Pacific/Port_Moresby": "Pacific/Port_Moresby",
      "Pacific/Rarotonga": "Pacific/Rarotonga",
      "Pacific/Saipan": "Pacific/Saipan",
      "Pacific/Samoa": "Pacific/Samoa",
      "Pacific/Tahiti": "Pacific/Tahiti",
      "Pacific/Tarawa": "Pacific/Tarawa",
      "Pacific/Tongatapu": "Pacific/Tongatapu",
      "Pacific/Truk": "Pacific/Truk",
      "Pacific/Wake": "Pacific/Wake",
      "Pacific/Wallis": "Pacific/Wallis",
      "Pacific/Yap": "Pacific/Yap",
      "Poland": "Poland",
      "Portugal": "Portugal",
      "ROC": "ROC",
      "ROK": "ROK",
      "Singapore": "Singapore",
      "Turkey": "Turkey",
      "UCT": "UCT",
      "US/Alaska": "US/Alaska",
      "US/Aleutian": "US/Aleutian",
      "US/Arizona": "US/Arizona",
      "US/Central": "US/Central",
      "US/East-Indiana": "US/East-Indiana",
      "US/Eastern": "US/Eastern",
      "US/Hawaii": "US/Hawaii",
      "US/Indiana-Starke": "US/Indiana-Starke",
      "US/Michigan": "US/Michigan",
      "US/Mountain": "US/Mountain",
      "US/Pacific": "US/Pacific",
      "US/Samoa": "US/Samoa",
      "UTC": "UTC",
      "Universal": "Universal",
      "W-SU": "W-SU",
      "WET": "WET",
      "Zulu": "Zulu"
  };
export const LANGUAGES = {
  'en': 'English',
  'ru': 'Русский'
}

export const TIMES = {
  '00:00': '00:00',
  // '00:30': '00:30',
  '01:00': '01:00',
  // '01:30': '01:30',
  '02:00': '02:00',
  // '02:30': '02:30',
  '03:00': '03:00',
  // '03:30': '03:30',
  '04:00': '04:00',
  // '04:30': '04:30',
  '05:00': '05:00',
  // '05:30': '05:30',
  '06:00': '06:00',
  // '06:30': '06:30',
  '07:00': '07:00',
  // '07:30': '07:30',
  '08:00': '08:00',
  // '08:30': '08:30',
  '09:00': '09:00',
  // '09:30': '09:30',
  '10:00': '10:00',
  // '10:30': '10:30',
  '11:00': '11:00',
  // '11:30': '11:30',
  '12:00': '12:00',
  // '12:30': '12:30',
  '13:00': '13:00',
  // '13:30': '13:30',
  '14:00': '14:00',
  // '14:30': '14:30',
  '15:00': '15:00',
  // '15:30': '15:30',
  '16:00': '16:00',
  // '16:30': '16:30',
  '17:00': '17:00',
  // '17:30': '17:30',
  '18:00': '18:00',
  // '18:30': '18:30',
  '19:00': '19:00',
  // '19:30': '19:30',
  '20:00': '20:00',
  // '20:30': '20:30',
  '21:00': '21:00',
  // '21:30': '21:30',
  '22:00': '22:00',
  // '22:30': '22:30',
  '23:00': '23:00',
  // '23:30': '23:30',
}

export const Models = (language:string='ru') => {
 return {
    'fl': trans('fl', language),
    'fli': trans('fli', language),
    'ffi': trans('ffi', language),
    'ftd': trans('ftd', language),
    'ftdm': trans('ftdm', language),
    'ml': trans('ml', language),
    'mli': trans('mli', language),
    'mfi': trans('mfi', language),
    'mtd': trans('mtd', language),
    'flnd': trans('flnd', language),
    'fw': trans('fw', language),
    'mw': trans('mw', language),
  }
}

export const ModelsArr = () => ['fl', 'fli', 'ffi', 'ftd', 'ftdm', 'ml', 'mli', 'mfi', 'mtd', 'flnd', 'fw', 'mw'];

export const Windows = (language:string='ru', model?: IModelAttribution) => {
  const temp: DefaultObject<string> = {
    7: `7 ${trans('days', language)}`,
    15: `15 ${trans('days', language)}`,
    30: `30 ${trans('days', language)}`,
    60: `60 ${trans('days', language)}`,
    90: `90 ${trans('days', language)}`
  }
  if (model === 'mli') temp[1] = `1 ${trans('day', language)}`;
  return temp;
}

export const WindowsArr = (model?: IModelAttribution) => {
  let temp: IWindowAttribution[] = [7, 15, 30, 60, 90];
  if(model === 'mli') temp = [1 as IWindowAttribution].concat(temp);
  return temp;
}

export const Periods = (language:string='ru') => {
  return {
    'today': trans('Today', language),
    'yesterday': trans('Yesterday', language),
    'week': trans('This week', language),
    'previous_week': trans('Previous week', language),
    'month': trans('This month', language),
    'previous_month': trans('Previous month', language),
    'year': trans('This year', language),
  }
}

export const Groups = (language:string='ru') => {
 return {
    'day': trans('Day', language),
    'week': trans('Week', language),
    'month': trans('Month', language),
    'quarter': trans('Quarter', language),
    'year': trans('Year', language)
  }
}

export const tokenFields= (language?:string, roleDataset:DefaultObject<string> = {}): (IField | IAccordion)[] => {
  return [
    {
      tag: 'input',
      type: 'text',
      name: 'name',
      placeholder: trans('Name', language),
      label: trans('Name', language),
      errorLabel: trans('The field is required', language),
      isRequired: true
    },
    {
      tag: 'select_new',
      type: 'text',
      name: 'role',
      placeholder: '',
      label: trans('Role', language),
      tooltip: '',
      errorLabel: '',
      isRequired: true,
      isOpenUp: false,
      dataset: roleDataset
    }
  ]
}

export const keyToSkip = '8|}$$~';

export const requiresPresetSources:DefaultObject<DefaultObject<string>> = {
  '1': { //Yandex
    'rnd': '%random%',
    'domain': '{source}'
  },
  '2': { //Getintent
    'device_id': '[[RAW_ADV_ID]]'
  },
  '3': { //Hybrid
    'idfa': '{idfa}',
    'gaid': '{gaid}'
  },
  '4': { //Avito
    'device_id': '{advid}'
  },
  '5': { //Yabbi
    'idfa': '{idfa}',
    'gaid': '{gaid}',
    'domain': '{bundleid}'
  },
  '6': { //Mediadesk
    'gaid': '{gaid}',
    'idfa': '{idfa}',
    'domain': '{bundle}'
  },
  '7': { //МТС
    'domain': '${DOMAIN}',
    'device_id': '${F70B56015AF2B2F3}',
    'ssp': '${PLATFORM_ID}'
  },
  '8': { //Mobidriven
    'gaid': '{gaid}',
    'idfa': '{idfa}',
    'domain': '{bundle}'
  },
  '9': { //BYYD
    'ssp': '{EXCHANGE_NAME}',
    'domain': '{APP_ID}',
    'device_id': '{DEVICE_PLATFORM_ID}'
  },
  '10': { //Hybe
    'idfa': '{idfa}',
    'gaid': '{gaid}',
    'domain': '{id_inapp}'
  }
}
