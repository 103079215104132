import {createAsyncThunk} from '@reduxjs/toolkit'
import {closeModal, ModalName} from '../redux/slices/modals'
import {openPopup} from "../redux/slices/popup";
import {PopupType} from "../data";
import {showPopup, updateToken} from "../utils";
import {downloadProjectsData} from "./projects";
import {DefaultObject} from "../interfaces/common.d";
import {ICurrentEvents} from "../redux/slices/events";
import {v4 as uuidv4} from 'uuid';
import {clearProjectAttributions, clearProjectGeneral} from "../redux/slices/project";

interface IProjectParams {
  currentUserId?: number
  projectId: number
}

const REQUEST_TIMEOUT_TO_LOAD = 30000;

export const getAgeChart = createAsyncThunk(
  'project/getAgeChart',
  async (
    {projectId, periodStart, periodEnd} : {projectId:number, periodStart:string, periodEnd:string},
    { rejectWithValue, extra: API}: any
) => {
    const data = {
        "common_params": {
            "project_id": projectId,
            "interval_from": periodStart,
            "interval_to": periodEnd,
            "simplify_values_type_names": true
        },
      "items": [
        {
          "name_id": "impressions_boxplot_chart",
        }
      ]
    }
    try {
      const response = await API.post(`/api/dashboard/fetch/?project_id=${projectId}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getCircleChart = createAsyncThunk(
  'project/getCircleChart',
  async (
    {projectId, periodStart, periodEnd} : {projectId:number, periodStart:string, periodEnd:string},
    { rejectWithValue, extra: API}: any
) => {
    const data = {
        "common_params": {
            "project_id": projectId,
            "interval_from": periodStart,
            "interval_to": periodEnd,
            "simplify_values_type_names": true
        },
      "items": [
        {
          "name_id": "impressions_relation_pie_chart",
        }
      ]
    }
    try {
      const response = await API.post(`/api/dashboard/fetch/?project_id=${projectId}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getLinearChart = createAsyncThunk(
  'project/getLinearChart',
  async (
    // { userId, refresh }: {userId: number, refresh?: boolean},
    {projectId, periodStart, periodEnd, categorize, window, model} : {projectId:number, periodStart:string, periodEnd:string, categorize:string, window:number, model:string},
    { rejectWithValue, extra: API}: any
) => {
    const data = {
      "common_params": {
        "project_id": projectId,
        "interval_from": periodStart,
        "interval_to": periodEnd,
        "model": model,
        "window": window,
        "simplify_values_type_names": true
      },
      "items": [
        {
          "name_id": "events_linear_chart",
          "date_grouping": categorize
        }
      ]
    }
    try {
      const response = await API.post(`/api/dashboard/fetch/?project_id=${projectId}`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getCustomDashboards = createAsyncThunk(
  'project/getCustomDashboards',
  async (
    {
        projectId,
        periodStart,
        periodEnd,
        names,
        categorize,
        model,
        window,
        filter,
        targetEvents,
        aggregation,
        sort,
        filter_fields,
        limit,
        offset,
        defaultReq,
        downloadType,
        metrics,
        filter_metrics,
        metrics_include,
        type,
        interaction_type,
        event_dt,
        filter_ecom,
        filter_trg,
        trgPeriodStart,
        trgPeriodEnd,
        only_media=false,
        only_event=false
    } : {projectId:number,
        periodStart:string,
        periodEnd:string,
        names:string[],
        categorize?:string,
        model?:string,
        window?:number,
        filter?:Array<any>,
        targetEvents?:Array<ICurrentEvents>,
        aggregation?:Array<string>,
        sort?:any,
        filter_fields?:Array<any>,
        limit?:number,
        offset?:number,
        defaultReq?:boolean,
        downloadType?:'csv'|'xlsx',
        metrics?:Array<string>,
        filter_metrics?:Array<any>,
        metrics_include?:boolean,
        type?: 'media_campaign_statistics' | 'general_custom_table',
        interaction_type?: Array<string>,
        event_dt?: Array<string>,
        filter_ecom?: Array<any>,
        filter_trg?: Array<any>,
        trgPeriodStart?:string,
        trgPeriodEnd?:string,
        only_media?: boolean
        only_event?: boolean
    },
    { rejectWithValue, extra: API}: any
) => {
    const data:any = {
      "common_params": {
        "interval_from": periodStart,
        "interval_to": periodEnd,
      },
      "items": []
    }
    if(trgPeriodStart && trgPeriodEnd) {
        data['common_params']['trg_event_dt_from'] = trgPeriodStart;
        data['common_params']['trg_event_dt_to'] = trgPeriodEnd;
    }
    for(const item of names) {
        const temp:any = {
            "name_id": item,
            "type": type
        }
        if(categorize) {
            temp["date_grouping"] = categorize
            temp["scale"] = categorize
        }
        if (aggregation && aggregation.length > 0) {
            temp["aggregation"] = aggregation;
        }
        if (sort && Object.values(sort).length > 0) {
            temp["sort"] = sort;
        }
        temp["filter_custom"] = [];
        if (filter_fields && filter_fields.length > 0) {
            const tempFilter:any = [];
            filter_fields.map((item:any)=> {
                if(typeof item.value === 'object' && item.value.length) {
                    item.value.map((v:string|number)=> {
                        tempFilter.push({
                            'name': item.name,
                            'condition': item.condition,
                            'value': v,
                        })
                    })
                } else {
                    tempFilter.push(item)
                }
            })
            temp["filter_custom"] = tempFilter;
        }
        if (metrics && metrics.length > 0) {
            temp["metrics"] = metrics;
        }
        if (filter_metrics && filter_metrics.length > 0) {
            const tempFilter:any = [];
            filter_metrics.map((item:any)=> {
                if(typeof item.value === 'object' && item.value.length) {
                    item.value.map((v:string|number)=> {
                        tempFilter.push({
                            'name': item.name,
                            'condition': item.condition,
                            'value': v,
                        })
                    })
                } else {
                    tempFilter.push(item)
                }
            })
            temp["filter_custom"] = temp["filter_custom"].concat(tempFilter);
        }
        if(limit) {
            temp['limit'] = limit;
        }
        if(offset) {
            temp['offset'] = offset;
        }
        if(metrics_include) {
            temp['metrics_include'] = metrics_include;
        }
        temp['custom'] = {}
        if(interaction_type) {
            temp['custom']['interaction_type'] = interaction_type
        }
        if(event_dt) {
            temp['custom']['event_dt'] = event_dt
        }
        if(filter_ecom && filter_ecom.length > 0) {
            temp['custom']['filter_ecom'] = filter_ecom;
        }
        if(filter_trg && filter_trg.length > 0) {
            const tempFilter:any = [];
            filter_trg.map((item:any)=> {
                if(typeof item.value === 'object' && item.value.length) {
                    item.value.map((v:string|number)=> {
                        tempFilter.push({
                            'name': item.name,
                            'condition': item.condition,
                            'value': v,
                        })
                    })
                } else {
                    tempFilter.push(item)
                }
            })
            temp['custom']['filter_trg'] = tempFilter;
        }
        if(trgPeriodStart && trgPeriodEnd) {
            temp['custom']['trg_event_dt_from'] = trgPeriodStart;
            temp['custom']['trg_event_dt_to'] = trgPeriodEnd;
        }
        if(only_media) {
            temp['only_media'] = true;
        }
        if(only_event) {
            temp['only_event'] = true;
        }
        data.items.push(temp);
    }
    if(model) {
        data.common_params.model = model;
    }
    if(window) {
        data.common_params.window = window;
    }
    if(filter) {
        data.filter = filter;
    }
    if(targetEvents && Array.isArray(targetEvents)) {
        const tempTrg:any[] = [];
        targetEvents.map((item)=> {
            if(Object.values(item).length > 0) {
                tempTrg.push(item);
            }
        })
        data.target_events = tempTrg;
    }
    if(defaultReq) {
        data['default'] = 1
    }
    if(downloadType)
        data['download'] = downloadType

    try {
      const response = await API.post(
          `/api/projects/${projectId}/custom-reports/table/`,
          data,
          {timeout: REQUEST_TIMEOUT_TO_LOAD}
      )
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response.data.detail);
    }
  }
);


export const getLimitsCustomDashboards = createAsyncThunk(
  'project/getLimitsCustomDashboards',
  async (
    {
        projectId
    } : {projectId:number },
    { rejectWithValue, extra: API}: any
) => {
    try {
      const response = await API.get(
          `/api/projects/${projectId}/custom-reports/table/lim/`,
          {timeout: REQUEST_TIMEOUT_TO_LOAD}
      )
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response.data.detail);
    }
  }
);


export const createLinearChart = createAsyncThunk(
  'project/createLinearChart',
  async (
    {projectId, infoEvent, typeEvent, dateEvent} : {projectId:number, infoEvent:string, typeEvent:string, dateEvent:string},
    { dispatch, rejectWithValue, extra: API}: any
) => {
    try {
      const response = await API.post(`/api/dashboard/tool/user_interactions_stats/linear/event/?project_id=${projectId}&info_event=${infoEvent}&type_event=${typeEvent}&date_event=${dateEvent}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.ADD_EVENT));
      dispatch(openPopup({ type: PopupType.SUCCESS, description: 'Событие добавлено' }))
      return response.data;
    } catch (e: any) {
        if (typeof e.response.data.detail === 'string')
            dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getDashboards = createAsyncThunk(
  'project/getDashboards',
  async (
    // { userId, refresh }: {userId: number, refresh?: boolean},
    {projectId, periodStart, periodEnd, names, categorize,model,window,filter,targetEvents,retry} : {projectId:number, periodStart:string, periodEnd:string, names:string[],categorize?:string,model?:string,window?:number, filter?:Array<any>, targetEvents?:any, retry?:boolean},
    { rejectWithValue, extra: API}: any
) => {
    const data:any = {
      "common_params": {
        "interval_from": periodStart,
        "interval_to": periodEnd,
        "simplify_values_type_names": true
      },
      "items": []
    }
    for(const item of names) {
        const temp: DefaultObject<string|boolean> = {"name_id": item};
        if(categorize) {
            temp["date_grouping"] = categorize;
            temp["scale"] = categorize;
        }
        data.items.push(temp);
    }
    if(model) {
        data.common_params.model = model;
    }
    if(window) {
        data.common_params.window = window;
    }
    if(filter) {
        data.filter = filter;
    }
    if(targetEvents && Object.values(targetEvents).length > 0) {
        data.target_events = targetEvents;
    }
    if(retry) data.retry = retry;
    try {
        const uuid = uuidv4();
        const response = await API.post(
            `/api/dashboard/fetch/?project_id=${projectId}`,
            data,
            {
                timeout: REQUEST_TIMEOUT_TO_LOAD,
                headers: {'Correlation-ID': uuid}
            }
        )
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        return response.data;
    } catch (e: any) {
        if(e.response && e.response.data && typeof e.response.data.detail === 'string')
            return rejectWithValue(e.response.data.detail);
        if(typeof e.message === 'string')
            return rejectWithValue(e.message);
        return rejectWithValue('');
    }
  }
);

export const getCampaigns = createAsyncThunk(
  'project/getCampaigns',
  async (
    // { userId, refresh }: {userId: number, refresh?: boolean},
    {projectId, periodStart, periodEnd, window, pageOffset, limit, sort, sortField, sortFilterField, searchText} : {projectId:number, periodStart:string, periodEnd:string, window:number, pageOffset:number, limit:number, sort:boolean, sortField:string, sortFilterField:string, searchText:string},
    { rejectWithValue, extra: API}: any
) => {
    const data = {
      "common_params": {
        "project_id": projectId,
        "interval_from": periodStart,
        "interval_to": periodEnd,
        "window": window,
        "simplify_values_type_names": true
      },
      "items": [
        {
            "name_id": "campaign_sources_table_data",
            "pagination": {
                "limit_per_page": limit,
                "page": pageOffset - 1,
                "sort_by_field": sortField,
                "sort_ascending": sort
            },
            "field_filter": {
                "by_field": sortFilterField,
                "value": searchText
            },
            // "sorting": {
            //     "sort_by": sortField,
            //     "sort_asc": sort
            // }
        }
      ]
    }
    try {
        const url = `/api/dashboard/fetch/?project_id=${projectId}`;
        const response = await API.post(url, data);
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        return response.data;
    } catch (e: any) {
        return rejectWithValue(e.response.data.detail);
    }
  }
);

export const getProjectGeneral = createAsyncThunk(
  'project/getProjectGeneral',
  async ({projectId}: IProjectParams , { dispatch, rejectWithValue, extra: API}: any) => {
      try {
          dispatch(clearProjectGeneral());
          const response = await API.get(`/api/projects/${projectId}/settings/general/`);
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          return response.data;
      } catch (e: any) {
          return rejectWithValue(e.response.data.detail);
      }
  }
);

export const getProjectAttributions = createAsyncThunk(
  'project/getProjectAttributions',
  async ({projectId}: IProjectParams , { dispatch, rejectWithValue, extra: API}: any) => {
      try {
          dispatch(clearProjectAttributions());
          const response = await API.get(`/api/projects/${projectId}/settings/attribution/`);
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          return response.data;
      } catch (e: any) {
          return rejectWithValue(e.response.data.detail);
      }
  }
);
export const updateProjectAttributions = createAsyncThunk(
  'project/updateProjectAttributions',
  async (
    { data, projectId }: { data: JSON | any, projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.post(`/api/projects/${projectId}/settings/attribution/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.message as string);
      dispatch(downloadProjectsData());
      dispatch(getProjectAttributions({projectId: Number(projectId)}))
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Изменения сохранены', icon: 'setting' }))
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);
export const updateProjectEvents = createAsyncThunk(
  'project/updateProjectEvents',
  async (
    { data, projectId }: { data: JSON | any, projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
      try {
          const response = await API.post(`/api/projects/${projectId}/settings/events/`, data);
          const new_token = response.headers["x-new-token"];
          updateToken(new_token);
          showPopup(dispatch, response.data.message as string);
          dispatch(downloadProjectsData());
          dispatch(openPopup({type: PopupType.SUCCESS, title: 'Целевое действие успешно создано'}))
          return response.data.data;
      } catch (e: any) {
          let error = e.message;
          if (e.response.data.error && e.response.data.error.message) {
              error = e.response.data.error.message;
          }
          if (e.response.data.detail)
              error = e.response.data.detail;
          showPopup(dispatch, error, PopupType.ERROR);
          return rejectWithValue(error);
      }
  }
);

export const downloadProjectIntegration = createAsyncThunk(
  'project/downloadProjectIntegration',
  async ({projectId}: any , { rejectWithValue, extra: API}: any) => {
    try {
      const response = await API.get(`/api/projects/${projectId}/integrations/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const addProjectIntegration = createAsyncThunk(
  'project/addProjectIntegration',
  async (
    { data, projectId }: { data: JSON | any, projectId: any },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.post(`/api/projects/${projectId}/integrations/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.detail as string);
      dispatch(downloadProjectIntegration({projectId: projectId}));
      dispatch(closeModal(ModalName.ADD_INTEGRATION));
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Интеграция добавлена в ваш проект', icon: 'setting' }))
      return response.data;
    } catch (e: any) {
        dispatch(openPopup({ type: PopupType.ERROR, description: 'Проверьте введённые данные' }))
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateProjectIntegration = createAsyncThunk(
  'project/updateProjectIntegration',
  async (
    { data, projectId }: { data: JSON | any, projectId: number },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.put(`/api/projects/${projectId}/integrations/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.detail as string);
      dispatch(downloadProjectIntegration({projectId: projectId}));
      dispatch(closeModal(ModalName.UPDATE_INTEGRATION));
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Интеграция обновлена', icon: 'setting' }))
      return response.data;
    } catch (e: any) {
        dispatch(openPopup({ type: PopupType.ERROR, description: 'Проверьте введённые данные' }))
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const removeProjectIntegration = createAsyncThunk(
  'project/removeProjectIntegration',
  async (
    { projectId, integrationId }: { projectId: number, integrationId:number },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.delete(`/api/projects/${projectId}/integrations/?integration_id=${integrationId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      showPopup(dispatch, response.data.detail as string);
      dispatch(downloadProjectIntegration({projectId: projectId}));
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Интеграция удалена из вашего проекта', icon: 'setting' }))
      return response.data;
    } catch (e: any) {
        if (typeof e.response.data.detail === 'string')
            dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const downloadIntegrations = createAsyncThunk(
  'project/downloadIntegrations',
  async (_ , { rejectWithValue, extra: API}: any) => {
    try {
      const response = await API.get(`/api/system/integration/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const authorizeYandexDirect = createAsyncThunk(
  'project/authorizeYandexDirect',
  async (
    { projectId, code, userId, name }: { projectId: number, code:string, userId:number, name?:string },
    { dispatch, rejectWithValue, extra: API}: any) => {
      try {
        let url = `/api/services/ydirect/auth/?code=${code}&project_id=${projectId}&user_id=${userId}`;
        if(name)
            url += `&name=${name}`;
        const response = await API.post(url);
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        return response.data;
      } catch (e: any) {
        let error = typeof e.message === 'string' ? e.message : 'Something went wrong';
        if(e.response.data.detail && typeof e.response.data.detail === 'string')
            error = e.response.data.detail;
        if(e.response.data.error && e.response.data.error.message && typeof e.response.data.error.message === 'string') {
            error = e.response.data.error.message;
        }
        dispatch(openPopup({ type: PopupType.ERROR, description: error }))
        return rejectWithValue(error);
      }
    }
);

export const authorizeGetIntent = createAsyncThunk(
  'project/authorizeGetIntent',
  async (
    { projectId, token, userId, name }: { projectId: number, token:string, userId:number, name?:string },
    { dispatch, rejectWithValue, extra: API}: any) => {
      try {
        let url = `/api/services/getintent/auth/?project_id=${projectId}&token=${token}&user_id=${userId}`;
        if(name)
            url += `&name=${name}`
        const response = await API.post(url);
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        dispatch(downloadProjectIntegration({projectId}))
        return response.data;
      } catch (e: any) {
        let error = e.message;
        if(e.response.data.error && e.response.data.error.message) {
            error = e.response.data.error.message;
        }
        if(e.response.data.detail)
            error = e.response.data.detail;
        return rejectWithValue(error);
      }
    }
);

export const authorizeHybrid = createAsyncThunk(
  'project/authorizeHybrid',
  async (
    { projectId, data, userId }: { projectId: number, data:any, userId:number },
    { dispatch, rejectWithValue, extra: API}: any) => {
      try {
        const response = await API.post(`/api/services/hybrid/auth/?project_id=${projectId}&user_id=${userId}`, data);
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        if(response.data === 'Ok')
            dispatch(downloadProjectIntegration({projectId}));
        return response.data;
      } catch (e: any) {
        let error = e.message;
        if(e.response.data.error && e.response.data.error.message) {
            error = e.response.data.error.message;
        }
        if(e.response.data.detail)
            error = e.response.data.detail;
        return rejectWithValue(error);
      }
    }
);

export const selectClientForHybrid = createAsyncThunk(
  'project/selectClientForHybrid',
  async (
    { projectId, clientId, integrationId, projectIntegrationId }: { projectId: number, clientId: string, integrationId:number, projectIntegrationId:number },
    { dispatch, rejectWithValue, extra: API}: any) => {
      try {
        const response = await API.post(`/api/services/hybrid/client/select/?project_id=${projectId}&advertiser_id=${clientId}&integration_id=${integrationId}&project_integration_id=${projectIntegrationId}`);
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        if(response.data === 'Ok')
            dispatch(downloadProjectIntegration({projectId}))
        return response.data;
      } catch (e: any) {
        let error = e.message;
        if(e.response.data.error && e.response.data.error.message) {
            error = e.response.data.error.message;
        }
        if(e.response.data.detail)
            error = e.response.data.detail;
        return rejectWithValue(error);
      }
    }
);

export const selectClientForAuthYandexDirect = createAsyncThunk(
  'project/selectClientForAuthYandexDirect',
  async (
    { projectId, login, integrationId, projectIntegrationId }: { projectId: number, login:string, integrationId:number, projectIntegrationId:number },
    { dispatch, rejectWithValue, extra: API }: any

  ) => {
    try {
      const response = await API.post(`/api/services/ydirect/agency/client_login/select/?project_id=${projectId}&agency_client_login=${login}&integration_id=${integrationId}&project_integration_id=${projectIntegrationId}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const updateIntegrationName = createAsyncThunk(
  'project/updateIntegrationName',
  async (
    { projectId, piid, name }: { projectId: number, piid:number, name:string },
    { dispatch, rejectWithValue, extra: API}: any) => {
      try {
        const response = await API.put(`/api/projects/${projectId}/integrations/${piid}/name/?name=${name}`);
        const new_token = response.headers["x-new-token"];
        updateToken(new_token);
        dispatch(downloadProjectIntegration({projectId: projectId}));
        dispatch(closeModal(ModalName.UPDATE_INTEGRATION));
        dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Интеграция обновлена', icon: 'setting' }))
        return response.data;
      } catch (e: any) {
        let error = e.message;
        if(e.response.data.error && e.response.data.error.message) {
            error = e.response.data.error.message;
        }
        if(e.response.data.detail)
            error = e.response.data.detail;
        return rejectWithValue(error);
      }
    }
);
