import {FC, useEffect, useState} from "react";
import Input from "../../../../ui/Input";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import Btn from "../../../../ui/Btn";
import {IBtn} from "../../../../ui/Btn/Btn.d";
import Tooltip from "../../../Tooltip";

import '../style.scss';
import {isValueObjectInArray} from "../../../../utils";

export interface ISelectSearchList {
    id: number,
    name: string
}

interface IInputValuesAdd {
    name: string,
    value: string,
    label?: string,
    tooltip?: string,
    required?: boolean,
    errorLabel?: string,
    beta?: boolean,
    placeholder?: string
    isInvalid?:boolean
    wrapper?:string[],
    actionBtn?:IBtn,
    isValidValue?: (value: string) => boolean,
    maxWrapperCount?: number
}

const InputValuesAdd: FC<IInputValuesAdd> = ({
    name,
    value='',
    label,
    tooltip,
    placeholder,
    required=false,
    errorLabel,
    beta=false,
    wrapper=[],
    actionBtn,
    isValidValue = (value: string) => true,
    maxWrapperCount
}) => {
    const [search, setSearch] = useState(value);
    const [showList, setShowList] = useState(false);
    const [list, setList] = useState<string[]>(wrapper);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    useEffect(()=> {
        setList(wrapper);
    }, [wrapper])

    const handleSearch = (data:any) => {
        setIsInvalid(false);
        setSearch(data[name])
        if(!data[name]) {
            setShowList(false);
            return;
        }
        if(!isValidValue(data[name])) {
            setShowList(false);
            setIsInvalid(true);
            return;
        }
        let isCheck = false;
        list.map((item)=> {
            if(item.toLowerCase() === data[name].toLowerCase()) {
                setShowList(false);
                isCheck = true;
                return;
            }
        })
        if(isCheck) return;
        setShowList(true);
    }

    return(
        <div className={`select-search`}>
            {label && <span className="select-search__label">{label}{required && <span className='red'>*</span>}{tooltip && <Tooltip text={tooltip}/>}{beta && <span className='beta'>beta</span>}</span>}
            {(!maxWrapperCount || wrapper.length < maxWrapperCount) && <div className={'select-search__block'}>
                <Input
                    name={name}
                    className='select-search__input'
                    type='text'
                    value={search}
                    onChange={handleSearch}
                    placeholder={placeholder}
                    errorLabel={errorLabel}
                    autoComplete={false}
                    isInvalid={isInvalid}
                />
            </div>}
            <div className={`select-search__list ${showList ? 'active' : ''}`}>
                {
                    actionBtn && search && isValidValue(search) && !(list.includes(search)) && <Btn
                        {...actionBtn}
                        className={`select-search__action`}
                        onClick={()=>{
                            setShowList(false);
                            setSearch('');
                            actionBtn.onClick && actionBtn.onClick(search)
                        }}
                        text={`${actionBtn.text}${search}`}
                        color={'blue'}
                    />
                }
            </div>
        </div>
    )
}

export default InputValuesAdd;