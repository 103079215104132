import {FC, useState} from 'react'
import Modal from '../../ui/Modal/index'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  addAnimation,
  closeModal,
  dataForModal,
  modalForAnimation,
  ModalName,
  modalStack
} from '../../redux/slices/modals'
import {currentUserLang, userId} from '../../redux/slices/user'
import {Components, Content, DefaultObject, IField} from '../../interfaces/common.d'
import './Modals.scss'
import {
  newCampaignForm,
  updateCampaignForm,
  newPlatformForm,
  newProjectFields,
  newSourceModal,
  projectEditing,
  nameEditingFields,
  emailEditingFields,
  passwordEditingFields, tokenFields, requiresPresetSources
} from '../../data'
import { creatProject, updateProjectById } from '../../actions/projects'
import {creatSource, updateSource} from '../../actions/source'
import { clearNewSource, newSource as newSourceSelector, sources, baseSources } from '../../redux/slices/sources'
import { IAccordion } from '../ModalForm/ModalForm.d'
import {createCampaign, getCampaignsGenerals, updateCampaignById, updateCampaignsMulti} from '../../actions/media'
import {creatPlacement, updatePlacementById, updatePlacementsMulti} from '../../actions/placements'
import { AppDispatch } from '../../redux'
import { format, parseISO } from 'date-fns'
import {formatDate, isNumber, updateSources, validateQueryParams} from '../../utils'
import LimitTrafficModal from "./LimitTrafficModal";
import DeleteAccessModal from "./DeleteAccessModal";
import EditUserModal from "./EditUserModal";
import AccessTimeModal from "./AccessTimeModal";
import LastActivityModal from "./LastActivityModal";
import AddEventChartModal from "./AddEventChartModal";
import AddIntegrationModal from "./AddIntegrationModal";
import UpdateIntegrationModal from "./UpdateIntegrationModal";
import AddAccessModal from './AddAccessModal'
import MessageModal from './MessageModal'
import {ModalForm} from '../index'
import InfoModal from './InfoModal'
import AddEmailModal from './AddEmailModal'
import ConfirmExitModal from "./ConfirmExitModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import FilterDashboards from "./FilterDashboards";
import EditSelectModal from "./EditSelect";
import {trans} from "../../_locales";
import NewPlacement from "./NewPlacement";
import {eventsData} from "../../redux/slices/events";
import EditMultipleActions from "./EditMultipleActions";
import DocsCreateProject from "../Docs/CreateProject";
import DocsCreateCampaignFull from "../Docs/CreateCampaignFull";
import DocsCreateCustomDashboard from "../Docs/CreateCustomDashboard";
import DashboardFullscreen from "./DashboardFullscreen";
import ConfirmDeleteSources from "./ConfirmDeleteSources"
import {IModalWrapper} from "../../ui/Modal/Modal.d";
import AddSettingsRole from "./AddSettingsRole";

interface ModalOptions {
  title: string
  className: string
  cb: () => any
  // Only for DASHBOARD_FULLSCREEN
  filters?: IModalWrapper["filters"]
  projectId?:number
}

const getOptions = (dispatch: AppDispatch, language?:string): Components<ModalName, ModalOptions> => {
  return  {
    [ModalName.NEW_PROJECT]: {
      title: trans('New project', language),
      className: '',
      cb: () => null
    },
    [ModalName.NEW_CAMPAIGN]: {
      title: trans('New campaign', language),
      className: 'modal--new-campaign',
      cb: () =>  dispatch(clearNewSource())
    },
    [ModalName.NEW_PLATFORM]: {
      title: trans('New placement', language),
      className: 'modal--new-platform',
      cb: () => null
    },
    [ModalName.NEW_SOURCE]: {
      title: trans('New source', language),
      className: 'modal--new-source',
      cb: () => null
    },
    [ModalName.EDIT_SOURCE]: {
      title: trans('Edit source', language),
      className: 'modal--new-source',
      cb: () => null
    },
    [ModalName.CAMPAIGN_EDITING]: {
      title: trans('Edit campaign', language),
      className: 'modal--new-campaign',
      cb: () => null
    },
    [ModalName.CAMPAIGN_BASED]: {
      title: trans('Create based on', language),
      className: 'modal--new-campaign',
      cb: () => null
    },
    [ModalName.PROJECT_EDITING]: {
      title: trans('Edit name', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.PLATFORM_EDITING]: {
      title: trans('Edit placement', language),
      className: 'modal--new-platform',
      cb: () => null
    },
    [ModalName.PLATFORM_BASED]: {
      title: trans('Create based on', language),
      className: 'modal--new-platform',
      cb: () => null
    },
    [ModalName.ADD_ACCESS]: {
      title: trans('Add users', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.ADD_EMAIL]: {
      title: trans('Adding a user to the project', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.ADD_EVENT]: {
      title: trans('New event', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.LIMIT_TRAFFIC]: {
      title: trans('Traffic limit', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.DELETE_USER]: {
      title: trans('Revoke access', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.EDIT_USER]: {
      title: trans('Edit user', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.ACCESS_TIME]: {
      title: trans('Access expiration date', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.CONFIRM_EXIT]: {
      title: trans('Revoke access', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.LAST_ACTIVITY]: {
      title: trans('Last activity', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.ADD_INTEGRATION]: {
      title: trans('Add integration', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.UPDATE_INTEGRATION]: {
      title: trans('Update integration', language),
      className: 'modal--edit-project',
      cb: () => null
    },
    [ModalName.MESSAGE]: {
      title: trans('Uploading', language),
      className: 'modal--title-center',
      cb: () => null
    },
    [ModalName.INFORMATION]: {
      title: '',
      className: 'modal--info',
      cb: () => null
    },
    [ModalName.NAME_EDITING]: {
      title: trans('Change name', language),
      className: '',
      cb: () => null
    },
    [ModalName.EMAIL_EDITING]: {
      title: trans('Change E-mail', language),
      className: '',
      cb: () => null
    },
    [ModalName.PASSWORD_EDITING]: {
      title: trans('Change password', language),
      className: '',
      cb: () => null
    },
    [ModalName.TOKEN_CREATE]: {
      title: trans('Create token', language),
      className: '',
      cb: () => null
    },
    [ModalName.TOKEN_EDIT]: {
      title: trans('Change token', language),
      className: '',
      cb: () => null
    },
    [ModalName.CONNECT_ADRIVER]: {
      title: trans('Connecting ADRIVER', language),
      className: '',
      cb: () => null
    },
    [ModalName.CONFIRM_DELETE]: {
      title: trans('Confirmation of deletion', language),
      className: 'modal--height-auto',
      cb: () => null
    },
    [ModalName.FILTER]: {
      title: trans('Filter', language),
      className: 'right',
      cb: () => null
    },
    [ModalName.EDIT_SELECT]: {
      title: trans('Edit', language),
      className: '',
      cb: () => null
    },
    [ModalName.EDIT_MULTI_CAMPAIGNS]: {
      title: trans('Edit multi campaigns', language),
      className: 'modal--edit-multi',
      cb: () => null
    },
    [ModalName.EDIT_MULTI_PLACEMENTS]: {
      title: trans('Edit multi placements', language),
      className: 'modal--edit-multi',
      cb: () => null
    },
    [ModalName.DOCS_CREATE_PROJECT]: {
      title: trans('Instruction for create project', language),
      className: 'modal--big-size modal--docs-create-project',
      cb: () => null
    },
    [ModalName.DOCS_CREATE_CAMPAIGN_FULL]: {
      title: trans('Instruction', language),
      className: 'modal--big-size modal--docs-create-campaign',
      cb: () => null
    },
    [ModalName.DOCS_CREATE_CUSTOM_DASHBOARD]: {
      title: trans('Instruction', language),
      className: 'modal--big-size modal--docs-create-custom-dashboard',
      cb: () => null
    },
    [ModalName.DASHBOARD_FULLSCREEN]: {
      title: trans('Instruction', language),
      className: 'modal--full-size modal--full-body modal--dashboard-fullscreen',
      cb: () => null
    },
    [ModalName.CONFIRM_DELETE_SOURCES]: {
      title: trans('Confirmation of deletion', language),
      className: 'modal--title-center',
      cb: () => null
    },
    [ModalName.SETTINGS_ROLE_CREATE]: {
      title: trans('Create role', language),
      className: 'modal--big-size',
      cb: () => null
    },
    [ModalName.SETTINGS_ROLE_EDIT]: {
      title: trans('Change role', language),
      className: 'modal--big-size',
      cb: () => null
    },
  };
};

const Modals: FC = () => {
  const dispatch = useAppDispatch();

  const modals = useAppSelector(modalStack);
  const animModal = useAppSelector(modalForAnimation);

  const reduxUserId = useAppSelector(userId);
  const reduxSources = useAppSelector(sources);
  const reduxBaseSources = useAppSelector(baseSources);
  const newSource = useAppSelector(newSourceSelector);
  const reduxEvents = useAppSelector(eventsData);
  const modalData = useAppSelector(dataForModal);
  const language = useAppSelector(currentUserLang);

  const options = getOptions(dispatch, language);

  const handleModalClose = (modal: ModalName) => {
    dispatch(addAnimation(modal))

    setTimeout(() => {
      dispatch(closeModal(modal))
    }, 400);
  }

  const modalComponents: Components<ModalName, (arg?:any) => Content> = {
    [ModalName.NEW_PROJECT]: () =>
      <ModalForm
        key={ModalName.NEW_PROJECT}
        fields={newProjectFields(language)}
        cb={(data: JSON) => {
          dispatch(creatProject({data, userId: reduxUserId}));
        }}
      />,
    [ModalName.NEW_CAMPAIGN]: () => {
      const currentFields = updateSources(
        newCampaignForm(language),
        reduxSources,
        newSource
      );
      currentFields.map((item)=> {
        if (item && item.tag === 'accordion') {
          item.content.map(field => {
            if (field.name === 'event_id') {
              if (reduxEvents && reduxEvents.length) {
                const temp: any = [{
                  'value': '',
                  'label': trans('All event targets', language)
                }];
                reduxEvents.map((item: any) => {
                  temp.push({
                    'value': item.id,
                    'label': item.event_target_name
                  })
                });
                field.options = temp;
              }
            }
          })
        }
      })

      return (
        <ModalForm
          key={ModalName.NEW_CAMPAIGN}
          hasScrollBlock
          fields={currentFields as (IField | IAccordion)[]}
          projectId={modalData[ModalName.NEW_CAMPAIGN]?.projectId}
          cb={(data: JSON) => {
            dispatch(createCampaign({
              data,
              userId: modalData[ModalName.NEW_CAMPAIGN]?.userId,
              projectId: modalData[ModalName.NEW_CAMPAIGN]?.projectId
            }));
          }}
        />
      );
    },
    [ModalName.NEW_PLATFORM]: () => {
      return (
          <NewPlacement
              key={ModalName.NEW_PLATFORM}
              projectId={modalData[ModalName.NEW_PLATFORM]?.projectId}
              cb={(data: FormData) => {
                // let currentData = JSON.parse(data.toString());
                // currentData = Object.assign(
                //   {},
                //   currentData,
                //   {
                //     source_id: modalData[ModalName.NEW_PLATFORM]?.sourceId
                //   }
                // );
                // currentData = JSON.stringify(currentData);
                data.append('source_id', modalData[ModalName.NEW_PLATFORM]?.sourceId);

                dispatch(creatPlacement({
                  data: data,
                  userId: modalData[ModalName.NEW_PLATFORM]?.userId,
                  projectId: modalData[ModalName.NEW_PLATFORM]?.projectId,
                  campaignId: modalData[ModalName.NEW_PLATFORM]?.campaignId
                }));
              }}
              type={'create'}
          />
      )
    },
    [ModalName.PLATFORM_EDITING]: () => {
      const fields = { ...modalData[ModalName.PLATFORM_EDITING]?.fields };
      delete fields['id'];
      delete fields['stat'];
      delete fields['campaign_id'];
      delete fields['created'];
      delete fields['project_id'];
      delete fields['source_id'];
      return(
          <NewPlacement
              key={ModalName.PLATFORM_EDITING}
              projectId={modalData[ModalName.PLATFORM_EDITING]?.projectId}
              initialValues={fields}
              type={'edit'}
              cb={(data: FormData) => {
                // let currentData = JSON.parse(data.toString());
                // currentData = Object.assign(
                //   {},
                //   currentData,
                //   {
                //     source_id: modalData[ModalName.PLATFORM_EDITING]?.sourceId
                //   }
                // );
                // currentData = JSON.stringify(currentData);
                data.append('source_id', modalData[ModalName.PLATFORM_EDITING]?.sourceId);

                dispatch(updatePlacementById({
                  data: data,
                  userId: modalData[ModalName.PLATFORM_EDITING]?.userId,
                  projectId: modalData[ModalName.PLATFORM_EDITING]?.projectId,
                  campaignId: modalData[ModalName.PLATFORM_EDITING]?.campaignId,
                  placementId: modalData[ModalName.PLATFORM_EDITING]?.placementId
                }));
              }}
          />
      )
    },
    [ModalName.PLATFORM_BASED]: () => {
      const fields = { ...modalData[ModalName.PLATFORM_BASED]?.fields };
      delete fields['id'];
      delete fields['stat'];
      delete fields['campaign_id'];
      delete fields['created'];
      delete fields['project_id'];
      delete fields['source_id'];
      fields['name'] = '';
      fields['adv_id'] = '';
      return(
          <NewPlacement
              key={ModalName.PLATFORM_BASED}
              projectId={modalData[ModalName.PLATFORM_BASED]?.projectId}
              initialValues={fields}
              type={'create'}
              cb={(data: FormData) => {
                // let currentData = JSON.parse(data.toString());
                // currentData = Object.assign(
                //   {},
                //   currentData,
                //   {
                //     source_id: modalData[ModalName.PLATFORM_BASED]?.sourceId
                //   }
                // );
                // currentData = JSON.stringify(currentData);
                data.append('source_id', modalData[ModalName.PLATFORM_BASED]?.sourceId);
                dispatch(creatPlacement({
                  data: data,
                  userId: modalData[ModalName.PLATFORM_BASED]?.userId,
                  projectId: modalData[ModalName.PLATFORM_BASED]?.projectId,
                  campaignId: modalData[ModalName.PLATFORM_BASED]?.campaignId
                })).then(()=>handleModalClose(ModalName.PLATFORM_BASED));
              }}
          />
      )
    },
    [ModalName.NEW_SOURCE]: () =>
      <ModalForm
        key={ModalName.NEW_SOURCE}
        fields={newSourceModal(language, reduxBaseSources)}
        resetText={trans('Cancel', language)}
        hasJsonFormat
        onReset={() => handleModalClose(ModalName.NEW_SOURCE)}
        cb={(data: DefaultObject<any>) => {
          const temp = data;
          if(temp.click_params) {
            const {query} = validateQueryParams(temp.click_params);
            temp.click_params = query;
          }
          if(temp.impression_params) {
            let required = {};
            if (requiresPresetSources[`${temp.base_source_id}`]) {
              required = requiresPresetSources[`${temp.base_source_id}`];
            }
            const {query} = validateQueryParams(temp.impression_params, required);
            temp.impression_params = query;
          }
          dispatch(creatSource({
            data: temp,
            userId: modalData[ModalName.NEW_SOURCE]?.userId,
            projectId: modalData[ModalName.NEW_SOURCE]?.projectId
          }));
          dispatch(closeModal(ModalName.NEW_SOURCE));
        }}
      />,
    [ModalName.EDIT_SOURCE]: () => {
      const initialValues = { ...modalData[ModalName.EDIT_SOURCE]?.initialValues };
      if(initialValues.click_params) {
        const {query} = validateQueryParams(initialValues.click_params);
        initialValues.click_params = query;
      }
      if(initialValues.impression_params) {
        let required = {};
        if (requiresPresetSources[`${initialValues.base_source_id}`]) {
          required = requiresPresetSources[`${initialValues.base_source_id}`];
        }
        const {query} = validateQueryParams(initialValues.impression_params, required);
        initialValues.impression_params = query;
      }
      return <ModalForm
        key={ModalName.EDIT_SOURCE}
        fields={newSourceModal(language, reduxBaseSources, true)}
        submitText={trans('Update', language)}
        resetText={trans('Cancel', language)}
        hasJsonFormat={true}
        initialValues={initialValues}
        onReset={() => handleModalClose(ModalName.EDIT_SOURCE)}
        cb={(data: DefaultObject<any>) => {
          const temp = data;
          if(temp.click_params) {
            const {query} = validateQueryParams(temp.click_params);
            temp.click_params = query;
          }
          if(temp.impression_params) {
            let required = {};
            if (requiresPresetSources[`${temp.base_source_id}`]) {
              required = requiresPresetSources[`${temp.base_source_id}`];
            }
            const {query} = validateQueryParams(temp.impression_params, required);
            temp.impression_params = query;
          }
          dispatch(updateSource({
            data: temp,
            userId: modalData[ModalName.EDIT_SOURCE]?.userId,
            projectId: modalData[ModalName.EDIT_SOURCE]?.projectId,
            sourceId: modalData[ModalName.EDIT_SOURCE]?.sourceId
          }));
          dispatch(closeModal(ModalName.EDIT_SOURCE));
        }}
      />},
    [ModalName.CAMPAIGN_EDITING]: () => {
      const currentValues = { ...modalData[ModalName.CAMPAIGN_EDITING]?.campaign };
      delete currentValues.id;
      delete currentValues.project_id;
      delete currentValues.product;

      currentValues.start_date = currentValues.start_date ?
        formatDate(parseISO(currentValues.start_date)) : undefined;
      currentValues.end_date = currentValues.end_date ?
        formatDate(parseISO(currentValues.end_date)) : undefined;

      const currentFields = updateSources(
        updateCampaignForm(language),
        reduxSources,
        newSource
      );

      currentFields.map((item)=> {
        if (item && item.tag === 'accordion') {
          item.content.map(field => {
            if (field.name === 'event_id') {
              if (reduxEvents && reduxEvents.length) {
                const temp: any = [{
                  'value': '',
                  'label': trans('All event targets', language)
                }];
                reduxEvents.map((item: any) => {
                  temp.push({
                    'value': item.id,
                    'label': item.event_target_name
                  })
                });
                field.options = temp;
              }
            }
          })
        }
      })

      return (
        <ModalForm
          key={ModalName.CAMPAIGN_EDITING}
          submitText={trans('Update', language)}
          hasScrollBlock
          fields={currentFields as (IField | IAccordion)[]}
          initialValues={currentValues}
          projectId={modalData[ModalName.CAMPAIGN_EDITING]?.projectId}
          campaignId={modalData[ModalName.CAMPAIGN_EDITING]?.campaignId}
          cb={(data: JSON) => {
            let currentData;
            if(!('start_date' in JSON.parse(data.toString()))) {
              currentData = JSON.parse(data.toString());
              currentData = JSON.stringify(currentData);
            }

            dispatch(updateCampaignById({
              data: currentData ? currentData as unknown as JSON : data,
              userId: modalData[ModalName.CAMPAIGN_EDITING]?.userId,
              projectId: modalData[ModalName.CAMPAIGN_EDITING]?.projectId,
              campaignId: modalData[ModalName.CAMPAIGN_EDITING]?.campaignId
            }));
          }}
        />
      );
    },
    [ModalName.CAMPAIGN_BASED]: () => {
      const currentValues = { ...modalData[ModalName.CAMPAIGN_BASED]?.campaign };
      delete currentValues.id;
      currentValues.name = '';
      currentValues.campaign_adv_id = '';
      delete currentValues.project_id;
      delete currentValues.product;

      currentValues.start_date = currentValues.start_date ?
        formatDate(parseISO(currentValues.start_date)) : undefined;
      currentValues.end_date = currentValues.end_date ?
        formatDate(parseISO(currentValues.end_date)) : undefined;

      const currentFields = updateSources(
        updateCampaignForm(language),
        reduxSources,
        newSource
      );

      currentFields.map((item)=> {
        if (item && item.tag === 'accordion') {
          item.content.map(field => {
            if (field.name === 'event_id') {
              if (reduxEvents && reduxEvents.length) {
                const temp: any = [{
                  'value': '',
                  'label': trans('All event targets', language)
                }];
                reduxEvents.map((item: any) => {
                  temp.push({
                    'value': item.id,
                    'label': item.event_target_name
                  })
                });
                field.options = temp;
              }
            }
          })
        }
      })

      return (
        <ModalForm
          key={ModalName.CAMPAIGN_BASED}
          submitText={trans('Create', language)}
          hasScrollBlock
          fields={currentFields as (IField | IAccordion)[]}
          initialValues={currentValues}
          projectId={modalData[ModalName.CAMPAIGN_BASED]?.projectId}
          campaignId={modalData[ModalName.CAMPAIGN_BASED]?.campaignId}
          cb={(data: JSON) => {
            let currentData;
            if(!('start_date' in JSON.parse(data.toString()))) {
              currentData = JSON.parse(data.toString());
              currentData = JSON.stringify(currentData);
            }
            dispatch(createCampaign({
              data: currentData ? currentData as unknown as JSON : data,
              userId: modalData[ModalName.CAMPAIGN_BASED]?.userId,
              projectId: modalData[ModalName.CAMPAIGN_BASED]?.projectId
            }));
          }}
        />
      );
    },
    [ModalName.PROJECT_EDITING]: () =>
      <ModalForm
        key={ModalName.PROJECT_EDITING}
        submitText={trans('Edit', language)}
        fields={projectEditing(language)}
        initialValues={{
          name: modalData[ModalName.PROJECT_EDITING]?.name,
        }}
        cb={(data: JSON ) => {
          dispatch(updateProjectById({
            data,
            // userId: modalData.creatorId,
            projectId: modalData[ModalName.PROJECT_EDITING]?.projectId
          }));
        }}
      />,
    [ModalName.ADD_ACCESS]: () => {
      return (
        <AddAccessModal
          key={ModalName.ADD_ACCESS}
          userId={modalData[ModalName.ADD_ACCESS]?.userId}
          projectId={modalData[ModalName.ADD_ACCESS]?.projectId}
        />
      );
    },
    [ModalName.ADD_EVENT]: () => {
      return (
        <AddEventChartModal
          key={ModalName.ADD_EVENT}
          projectId={modalData[ModalName.ADD_EVENT]?.projectId}
          periodStart={modalData[ModalName.ADD_EVENT]?.periodStart}
          periodEnd={modalData[ModalName.ADD_EVENT]?.periodEnd}
          categorize={modalData[ModalName.ADD_EVENT]?.categorize}
          windowAttributionValue={modalData[ModalName.ADD_EVENT]?.windowAttributionValue}
          model={modalData[ModalName.ADD_EVENT]?.model}
        />
      );
    },
    [ModalName.ADD_EMAIL]: () => {
      return (
        <AddEmailModal
          key={ModalName.ADD_EMAIL}
          userId={modalData[ModalName.ADD_EMAIL]?.userId}
          projectId={modalData[ModalName.ADD_EMAIL]?.projectId}
          onClose={handleModalClose}
        />
      );
    },
    [ModalName.LIMIT_TRAFFIC]: () => {
      return (
        <LimitTrafficModal
          key={ModalName.LIMIT_TRAFFIC}
          userId={modalData[ModalName.LIMIT_TRAFFIC]?.userId}
          projectId={modalData[ModalName.LIMIT_TRAFFIC]?.projectId}
          trafficType={modalData[ModalName.LIMIT_TRAFFIC]?.trafficType}
          trafficChannel={modalData[ModalName.LIMIT_TRAFFIC]?.trafficChannel}
          trafficName={modalData[ModalName.LIMIT_TRAFFIC]?.trafficName}
          email={modalData[ModalName.LIMIT_TRAFFIC]?.email}
          onChange={modalData[ModalName.LIMIT_TRAFFIC]?.onChange}
          onClose={handleModalClose}
          indexList={modalData[ModalName.LIMIT_TRAFFIC]?.indexList}
        />
      );
    },
    [ModalName.DELETE_USER]: () => {
      return (
        <DeleteAccessModal
          key={ModalName.DELETE_USER}
          email={modalData[ModalName.DELETE_USER]?.email}
          userId={modalData[ModalName.DELETE_USER]?.userId}
          projectId={modalData[ModalName.DELETE_USER]?.projectId}
          onClose={handleModalClose}
        />
      );
    },
    [ModalName.EDIT_USER]: () => {
      return (
        <EditUserModal
          key={ModalName.EDIT_USER}
          userId={modalData[ModalName.EDIT_USER]?.userId}
          projectId={modalData[ModalName.EDIT_USER]?.projectId}
        />
      );
    },
    [ModalName.ACCESS_TIME]: () => {
      return (
        <AccessTimeModal
          key={ModalName.ACCESS_TIME}
          userId={modalData[ModalName.ACCESS_TIME]?.userId}
          projectId={modalData[ModalName.ACCESS_TIME]?.projectId}
          expires={modalData[ModalName.ACCESS_TIME]?.expires}
          email={modalData[ModalName.ACCESS_TIME]?.email}
          onChange={modalData[ModalName.ACCESS_TIME]?.onChange}
          indexList={modalData[ModalName.ACCESS_TIME]?.indexList}
          onClose={handleModalClose}
        />
      );
    },
    [ModalName.CONFIRM_EXIT]: () => {
      if(modalData[ModalName.CONFIRM_EXIT]?.title)
        options[ModalName.CONFIRM_EXIT].title = modalData[ModalName.CONFIRM_EXIT].title;
      return (
        <ConfirmExitModal
          key={ModalName.CONFIRM_EXIT}
          onClose={handleModalClose}
          confirm={modalData[ModalName.CONFIRM_EXIT]?.confirm}
          confirmText={modalData[ModalName.CONFIRM_EXIT]?.confirmText}
          resetText={modalData[ModalName.CONFIRM_EXIT]?.resetText}
          message={modalData[ModalName.CONFIRM_EXIT]?.message}
          cancel={modalData[ModalName.CONFIRM_EXIT]?.cancel}
        />
      );
    },
    [ModalName.LAST_ACTIVITY]: () => {
      return (
        <LastActivityModal
          key={ModalName.LAST_ACTIVITY}
          userId={modalData[ModalName.LAST_ACTIVITY]?.userId}
          projectId={modalData[ModalName.LAST_ACTIVITY]?.projectId}
          userActivity={modalData[ModalName.LAST_ACTIVITY]?.activity}
        />
      );
    },
    [ModalName.ADD_INTEGRATION]: () => {
      return (
        <AddIntegrationModal
          key={ModalName.ADD_INTEGRATION}
          projectId={modalData[ModalName.ADD_INTEGRATION]?.projectId}
          dataIntegration={modalData[ModalName.ADD_INTEGRATION]?.data}
          fields={modalData[ModalName.ADD_INTEGRATION]?.fields}
          onReset={() => handleModalClose(ModalName.ADD_INTEGRATION)}
          cb={modalData[ModalName.ADD_INTEGRATION]?.cb}
        />
      );
    },
    [ModalName.UPDATE_INTEGRATION]: () => {
      return (
        <UpdateIntegrationModal
          key={ModalName.UPDATE_INTEGRATION}
          projectId={modalData[ModalName.UPDATE_INTEGRATION]?.projectId}
          dataIntegration={modalData[ModalName.UPDATE_INTEGRATION]?.data}
          fields={modalData[ModalName.UPDATE_INTEGRATION]?.fields}
          initialValues={modalData[ModalName.UPDATE_INTEGRATION]?.initialValues}
          onReset={() => handleModalClose(ModalName.UPDATE_INTEGRATION)}
          cb={modalData[ModalName.UPDATE_INTEGRATION]?.cb}
        />
      );
    },
    [ModalName.CONFIRM_DELETE]: () => {
      if(modalData[ModalName.CONFIRM_DELETE]?.title)
        options[ModalName.CONFIRM_DELETE].title = modalData[ModalName.CONFIRM_DELETE].title;
      return (
        <ConfirmDeleteModal
          key={ModalName.CONFIRM_DELETE}
          text={modalData[ModalName.CONFIRM_DELETE]?.text}
          textConfirm={modalData[ModalName.CONFIRM_DELETE]?.textConfirm}
          onReset={() => handleModalClose(ModalName.CONFIRM_DELETE)}
          onConfirm={modalData[ModalName.CONFIRM_DELETE]?.onConfirm}
        />
      );
    },
    [ModalName.MESSAGE]: () => {
      const message = modalData[ModalName.MESSAGE]?.message;
      const buttonsOptions = modalData[ModalName.MESSAGE]?.buttons

      options[ModalName.MESSAGE].title = modalData[ModalName.MESSAGE]?.title;

      return (
        <MessageModal
          key={ModalName.MESSAGE}
          message={message}
          cancelText={buttonsOptions?.cancel && buttonsOptions?.cancel.text}
          onCancelClick={buttonsOptions?.cancel && buttonsOptions?.cancel.onClick}
          confirmationText={buttonsOptions.confirmation.text}
          onConfirmationClick={buttonsOptions.confirmation.onClick}
        />
      );
    },
    [ModalName.INFORMATION]: () => {
      const title = modalData[ModalName.INFORMATION]?.title;
      const description = modalData[ModalName.INFORMATION]?.description;
      const icon = modalData[ModalName.INFORMATION]?.icon;
      const subBtn = modalData[ModalName.INFORMATION]?.subBtn;

      options[ModalName.INFORMATION].className =
        `${options[ModalName.INFORMATION].className} ${modalData[ModalName.INFORMATION]?.mod ? `modal--${modalData[ModalName.INFORMATION].mod}` : ''}`;

      return (
        <InfoModal
          key={ModalName.INFORMATION}
          title={title}
          description={description}
          icon={icon}
          subBtn={subBtn}
        />
      );
    },
    [ModalName.NAME_EDITING]: () =>
      <ModalForm
        key={ModalName.NAME_EDITING}
        submitText={trans('Save', language)}
        resetText={trans('Cancel', language)}
        fields={nameEditingFields(language)}
        initialValues={{
          first_name: modalData[ModalName.NAME_EDITING]?.userFirstName,
          last_name: modalData[ModalName.NAME_EDITING]?.userLastName,
        }}
        cb={modalData[ModalName.NAME_EDITING]?.onChange}
      />,
    [ModalName.EMAIL_EDITING]: () =>
      <ModalForm
        key={ModalName.EMAIL_EDITING}
        submitText={trans('Save', language)}
        resetText={trans('Clear', language)}
        fields={emailEditingFields(language)}
        initialValues={{
          email: modalData[ModalName.EMAIL_EDITING]?.userEmail,
          password: ''
        }}
        cb={modalData[ModalName.EMAIL_EDITING]?.onChange}
      />,
    [ModalName.PASSWORD_EDITING]: () =>
      <ModalForm
        key={ModalName.PASSWORD_EDITING}
        submitText={trans('Save', language)}
        resetText={trans('Clear', language)}
        fields={passwordEditingFields(language)}
        cb={modalData[ModalName.PASSWORD_EDITING]?.onChange}
      />,
    [ModalName.CONNECT_ADRIVER]: () =>
      <ModalForm
        key={ModalName.CONNECT_ADRIVER}
        submitText={trans('Save', language)}
        resetText={trans('Cancel', language)}
        fields={passwordEditingFields(language)}
        cb={(/*data: FormData | JSON*/) => {
          // dispatch(creatProject({data, userId: reduxUserId}));
        }}
      />,
    [ModalName.TOKEN_CREATE]: () =>
      <ModalForm
        key={ModalName.TOKEN_CREATE}
        submitText={trans('Create', language)}
        resetText={trans('Clear', language)}
        fields={tokenFields(language, modalData[ModalName.TOKEN_CREATE]?.roles)}
        initialValues={{
          name: modalData[ModalName.TOKEN_CREATE]?.name,
          role: modalData[ModalName.TOKEN_CREATE]?.role
        }}
        cb={(data:JSON)=> {
          modalData[ModalName.TOKEN_CREATE]?.onChange(data);
          dispatch(closeModal(ModalName.TOKEN_CREATE))
        }}
      />,
    [ModalName.TOKEN_EDIT]: () =>
      <ModalForm
        key={ModalName.TOKEN_EDIT}
        submitText={trans('Save', language)}
        resetText={trans('Clear', language)}
        fields={tokenFields(language, modalData[ModalName.TOKEN_EDIT]?.roles)}
        initialValues={{
          name: modalData[ModalName.TOKEN_EDIT]?.name,
          role: modalData[ModalName.TOKEN_EDIT]?.role
        }}
        cb={(data:JSON)=> {
          modalData[ModalName.TOKEN_EDIT]?.onChange(data);
          dispatch(closeModal(ModalName.TOKEN_EDIT))
        }}
      />,
    [ModalName.FILTER]: () =>
      <FilterDashboards
        key={ModalName.FILTER}
        onSubmit={modalData[ModalName.FILTER]?.onSubmit}
        projectId={modalData[ModalName.FILTER]?.projectId}
        page={modalData[ModalName.FILTER]?.page}
      />,
    [ModalName.EDIT_SELECT]: () => {
      options[ModalName.EDIT_SELECT].title = modalData[ModalName.EDIT_SELECT]?.title;
      return(
        <EditSelectModal
          key={ModalName.EDIT_SELECT}
          onSubmit={modalData[ModalName.EDIT_SELECT]?.onSubmit}
          active={modalData[ModalName.EDIT_SELECT]?.active}
          dataset={modalData[ModalName.EDIT_SELECT]?.dataset}
          onClose={() => handleModalClose(ModalName.EDIT_SELECT)}
        />
      )
    },
    [ModalName.EDIT_MULTI_CAMPAIGNS]: () => {
      return (
          <EditMultipleActions
              key={ModalName.EDIT_MULTI_CAMPAIGNS}
              projectId={modalData[ModalName.EDIT_MULTI_CAMPAIGNS]?.projectId}
              action={'campaigns'}
              ids={modalData[ModalName.EDIT_MULTI_CAMPAIGNS]?.ids}
              cb={(data: JSON) => {
                dispatch(updateCampaignsMulti({
                  data: data,
                  projectId: modalData[ModalName.EDIT_MULTI_CAMPAIGNS]?.projectId,
                  campaignIds: modalData[ModalName.EDIT_MULTI_CAMPAIGNS]?.ids
                }));
                handleModalClose(ModalName.EDIT_MULTI_CAMPAIGNS)
              }}
              initialValues={modalData[ModalName.EDIT_MULTI_CAMPAIGNS]?.initialValues}
          />
      )
    },
    [ModalName.EDIT_MULTI_PLACEMENTS]: () => {
      return (
          <EditMultipleActions
              key={ModalName.EDIT_MULTI_PLACEMENTS}
              projectId={modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.projectId}
              action={'placements'}
              ids={modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.ids}
              cb={(data: JSON) => {
                dispatch(updatePlacementsMulti({
                  data: data,
                  projectId: modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.projectId,
                  campaignId: modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.campaignId,
                  placementIds: modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.ids
                }));
                handleModalClose(ModalName.EDIT_MULTI_PLACEMENTS)
              }}
              initialValues={modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.initialValues}
              geoList={modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.geoList}
              targetingList={modalData[ModalName.EDIT_MULTI_PLACEMENTS]?.targetingList}
          />
      )
    },
    [ModalName.DOCS_CREATE_PROJECT]: () => {
      return (
          <DocsCreateProject
              key={ModalName.DOCS_CREATE_PROJECT}
          />
      )
    },
    [ModalName.DOCS_CREATE_CAMPAIGN_FULL]: () => {
      return (
          <DocsCreateCampaignFull
              key={ModalName.DOCS_CREATE_CAMPAIGN_FULL}
          />
      )
    },
    [ModalName.DOCS_CREATE_CUSTOM_DASHBOARD]: () => {
      return (
          <DocsCreateCustomDashboard
              key={ModalName.DOCS_CREATE_CUSTOM_DASHBOARD}
          />
      )
    },
    [ModalName.DASHBOARD_FULLSCREEN]: (filters?:IModalWrapper['filters']) => {
      options[ModalName.DASHBOARD_FULLSCREEN].title = 'Медийный отчет';
      let periodStart = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.periodStart,
          periodEnd = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.periodEnd,
          model = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.model,
          window = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.window,
          categorize = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.categorize,
          eventTarget = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.eventTarget,
          targetEvents = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.targetEvents,
          selectDate = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.selectDate,
          filter = modalData[ModalName.DASHBOARD_FULLSCREEN]?.data.filter;

      if(filters) {
        periodStart = filters.periodStart;
        periodEnd = filters.periodEnd;
        model = filters.model;
        window = filters.window;
        categorize = filters.categorize;
        eventTarget = filters.eventTarget;
        targetEvents = filters.targetEvents;
        selectDate = filters.selectDate;
        filter = filters.filter;
      }
      const temp  = {
        periodStart: periodStart,
        periodEnd: periodEnd,
        model: model,
        window: window,
        categorize: categorize,
        eventTarget: eventTarget,
        targetEvents: targetEvents,
        selectDate: selectDate,
        filter: filter
      };
      if(temp.targetEvents && temp.targetEvents.event_target_name) temp.eventTarget = temp.targetEvents.event_target_name;
      options[ModalName.DASHBOARD_FULLSCREEN].filters = temp;
      options[ModalName.DASHBOARD_FULLSCREEN].projectId = modalData[ModalName.DASHBOARD_FULLSCREEN]?.projectId
      return (
          <DashboardFullscreen
              key={ModalName.DASHBOARD_FULLSCREEN}
              onClose={()=>handleModalClose(ModalName.DASHBOARD_FULLSCREEN)}
              data={modalData[ModalName.DASHBOARD_FULLSCREEN]?.data}
              filters={temp}
              type={modalData[ModalName.DASHBOARD_FULLSCREEN]?.type}
          />
      )
    },
    [ModalName.CONFIRM_DELETE_SOURCES]: () => {
      const message = modalData[ModalName.CONFIRM_DELETE_SOURCES]?.message;
      const buttonsOptions = modalData[ModalName.CONFIRM_DELETE_SOURCES]?.buttons

      return (
        <ConfirmDeleteSources
          key={ModalName.MESSAGE}
          message={message}
          cancelText={buttonsOptions?.cancel && buttonsOptions?.cancel.text}
          onCancelClick={buttonsOptions?.cancel && buttonsOptions?.cancel.onClick}
          confirmationText={buttonsOptions.confirmation.text}
          onConfirmationClick={buttonsOptions.confirmation.onClick}
        />
      );
    },
    [ModalName.SETTINGS_ROLE_CREATE]: () => {
      return (
          <AddSettingsRole
              key={ModalName.SETTINGS_ROLE_CREATE}
              projectId={modalData[ModalName.SETTINGS_ROLE_CREATE]?.projectId}
              onSubmit={modalData[ModalName.SETTINGS_ROLE_CREATE]?.onSubmit}
              type={'create'}
          />
      )
    },
    [ModalName.SETTINGS_ROLE_EDIT]: () => {
      return (
          <AddSettingsRole
              key={ModalName.SETTINGS_ROLE_EDIT}
              projectId={modalData[ModalName.SETTINGS_ROLE_EDIT]?.projectId}
              onSubmit={modalData[ModalName.SETTINGS_ROLE_EDIT]?.onSubmit}
              defaultData={modalData[ModalName.SETTINGS_ROLE_EDIT]?.defaultData}
              type={'edit'}
              defaultName={modalData[ModalName.SETTINGS_ROLE_EDIT]?.name}
          />
      )
    },
  } as const

  const [arg, setArg] = useState<any>(undefined);
  return (
    <>
      {
        modals.length > 0 && modals.map((modal, i) => {
          const component = arg ? modalComponents[modal](arg) : modalComponents[modal]();
          const isLoader = options[modal].className.includes('modal--loader');

          return (
            <Modal
              key={`${modal}-${i}`}
              className={`${options[modal].className}${animModal === modal ? ' is-close' : ''}`}
              title={options[modal].title}
              isLast={modals.length - 1 === i  && !isLoader}
              onlyOne={modals.length === 1 && !isLoader}
              onClose={() => {
                options[modal].cb();
                setArg(undefined);
                handleModalClose(modal);
              }}
              filters={options[modal].filters}
              onChangeFilters={(value:any)=>{
                setArg(value)
              }}
              projectId={options[modal]?.projectId}
            >
              {component}
            </Modal>
          )
        })
      }
    </>
  );
};

export default Modals;
