import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {
  getProjectAttributions,
  updateProjectAttributions
} from "../../../../../actions/project";
import {useEffect, useState} from "react";
import cls from './Attributions.module.scss';
import {projectAttributions, projectGeneral} from "../../../../../redux/slices/project";
import {clearUnSaveData, setUnSaveData} from "../../../../../redux/slices/warnings";
import {useCallbackPrompt} from "../../../../../hooks/useCallbackPrompt";
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import Select from "../../../../../components/ui/Select";

import './style.scss';
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {trans} from "../../../../../_locales";
import {Models, Windows} from "../../../../../data";
import {getScreenResolution, sendSdk} from "../../../../../utils";
import UploadFile from "../../../../../components/ui/UploadFile";
import {IProjectGeneral} from "../../../../../interfaces/common.d";
import ChangeByBtn from "../../../../../components/ChangeByBtn";
import Accordion from "../../../../../ui/Accordion";

const Attributions = () => {

  const dispatch = useAppDispatch();
  const {projectId} = useParams();
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const currentProject = useAppSelector(projectGeneral);
  const currentAttr = useAppSelector(projectAttributions);
  const language = useAppSelector(currentUserLang);
  const [dataProject, setDataProject] = useState<IProjectGeneral | null>(currentAttr);
  const [exitCounter, setExitCounter] = useState<boolean>(false);
  const navigate = useNavigate();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_attributions') || currentProject && currentProject.owner_id === reduxUserId;

  useEffect(() => {
    if(!currentAttr) {
      setDataProject({} as IProjectGeneral);
      return;
    }
    setDataProject(currentAttr);
  }, [currentAttr]);

  useEffect(()=> {
    if (projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'settings_attributions',
          'resolution': getScreenResolution()
        }
      })
    }
    if(permissions && !isView) {
      navigate(`/projects/${projectId}/settings/`)
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)}));
  }, [projectId, reduxUser.id])

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(exitCounter)
  if(showPrompt) {
    dispatch(openModal({
      name:ModalName.CONFIRM_EXIT,
      data: {
        confirm: confirmNavigation,
        cancel: cancelNavigation,
      }
    }))
  }


  const windows:any = Windows(language);
  const onChangeWindowsValue = (response:object) => {
    for(const key in response) {
      changeWindow(key)
    }
  }
  function changeWindow(model: string): void  {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, attribution_window: Number(model)});
    }
  }

  const models:any = Models(language);
  const onChangeModelsValue = (response:object) => {
    for(const key in response) {
      changeModel(key)
    }
  }
  function changeModel(model: string): void  {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, attribution_model: model});
    }
  }


  const reportPeriodList:any = {
    'month': trans('This month', language),
    'previous_month': trans('Previous month', language),
    'week': trans('This week', language),
    'previous_week': trans('Previous week', language),
  }
  const onChangeReportsValue = (response:object) => {
    for(const key in response) {
      changeReportPeriod(key)
    }
  }
  function changeReportPeriod(model: string): void  {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, attribution_period: model});
    }
  }

  const handleFieldChange = (name: string, value?: string|number) => {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentAttr);
    }
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      setDataProject({...dataProject, ...{[name]: value}});
    }
  }

  const onSubmit = () => {
    setExitCounter(false)
    dispatch(clearUnSaveData());
    const formData = new FormData();
    Object.entries((dataProject as IProjectGeneral)).forEach(([name, value]) => {
        if(value && name !== 'format') {
            formData.append(name, value)
        }
    });
    dispatch(updateProjectAttributions({data: formData, projectId: Number(projectId)}));
    sendSdk('event', {
        'event_type': 'edit',
        'event_category': 'settings_attributions',
        'event_name': 'active',
        'event_value': '10',
        'event_params': {
            'project_id': projectId,
            'user_id': reduxUserId
        }
    });
  }

  if(isEdit || isDelete) {
    return (
      <>
      <div className={`${cls.content} setting-attributions`}>
        <div className={cls.form}>
            <div className={cls.block}>
              <div className={cls.title}>{trans('Attribution', language)}</div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default attribution window', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        (windows as any)[`${dataProject.attribution_window}`] :
                        (windows as any)[`${currentAttr?.attribution_window}`]}
                  </span>
                </div>
                <div className={`${cls.select_wrapper} attributions__select-wrapper`}>
                  <Select
                      selectActive={dataProject && dataProject.attribution_window ? (windows as any)[`${dataProject.attribution_window}`] : currentAttr && currentAttr.attribution_window ?
                          (windows as any)[`${currentAttr?.attribution_window}`] : trans('Select the attribution window', language)}
                      dataset={windows} onChangeActive={onChangeWindowsValue}/>
                </div>
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default attribution model', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        (models as any)[`${dataProject.attribution_model}`] :
                        (models as any)[`${currentAttr?.attribution_model}`]}
                  </span>
                </div>
                <div className={`${cls.select_wrapper} attributions__select-wrapper`}>
                  <Select selectActive={dataProject && dataProject.attribution_model ?
                      (models as any)[`${dataProject.attribution_model}`] : currentAttr && currentAttr.attribution_model ?
                          (models as any)[`${currentAttr?.attribution_model}`] : trans('Select the attribution model', language)}
                          dataset={models} onChangeActive={onChangeModelsValue}/>
                </div>
              </div>
            </div>
            <div className={cls.block}>
              <div className={cls.title}>{trans('Reports', language)}</div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default relative time interval', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        (reportPeriodList as any)[`${dataProject.attribution_period}`] :
                        (reportPeriodList as any)[`${currentAttr?.attribution_period}`]}
                  </span>
                </div>
                <div className={`${cls.select_wrapper} attributions__select-wrapper`}>
                  <Select selectActive={dataProject ?
                      (reportPeriodList as any)[`${dataProject.attribution_period}`] : currentAttr && currentAttr.attribution_period ?
                          (reportPeriodList as any)[`${currentAttr?.attribution_period}`] : trans('Select the attribution period', language)}
                          dataset={reportPeriodList} onChangeActive={onChangeReportsValue}/>
                </div>
              </div>
            </div>
          <Accordion title={trans('Advertiser', language)} className={'setting-attributions__accordion'}>
            <div className={cls.block}>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='adv_name'>{trans('Advertiser name', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_name ? dataProject.adv_name : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.adv_name?currentAttr.adv_name: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'string'}
                    name={'adv_name'}
                    value={dataProject ?
                        `${dataProject.adv_name ? dataProject.adv_name : ''}` :
                        `${currentAttr&&currentAttr.adv_name?currentAttr.adv_name: ''}`}
                    onChange={(value)=> handleFieldChange('adv_name', value)}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='adv_address'>{trans('Address', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_address ? dataProject.adv_address : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.adv_address?currentAttr.adv_address: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'string'}
                    name={'adv_address'}
                    value={dataProject ?
                        `${dataProject.adv_address ? dataProject.adv_address : ''}` :
                        `${currentAttr&&currentAttr.adv_address?currentAttr.adv_address: ''}`}
                    onChange={(value)=> handleFieldChange('adv_address', value)}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='adv_inn'>{trans('INN', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_inn ? dataProject.adv_inn : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.adv_inn?currentAttr.adv_inn: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'string'}
                    name={'adv_inn'}
                    value={dataProject ?
                        `${dataProject.adv_inn ? dataProject.adv_inn : ''}` :
                        `${currentAttr&&currentAttr.adv_inn?currentAttr.adv_inn: ''}`}
                    onChange={(value)=> handleFieldChange('adv_inn', value)}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='adv_ogrn'>{trans('OGRN', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_ogrn ? dataProject.adv_ogrn : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.adv_ogrn?currentAttr.adv_ogrn: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'string'}
                    name={'adv_ogrn'}
                    value={dataProject ?
                        `${dataProject.adv_ogrn ? dataProject.adv_ogrn : ''}` :
                        `${currentAttr&&currentAttr.adv_ogrn?currentAttr.adv_ogrn: ''}`}
                    onChange={(value)=> handleFieldChange('adv_ogrn', value)}
                />
              </div>
            </div>
          </Accordion>

          <Accordion title={trans('Custom viewability', language)} className={'setting-attributions__accordion'}>
            <div className={cls.block}>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='cv_delay'>{trans('CV time', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_delay ? dataProject.cv_delay : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.cv_delay?currentAttr.cv_delay: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'number'}
                    name={'cv_delay'}
                    value={dataProject ?
                        `${dataProject.cv_delay ? dataProject.cv_delay : ''}` :
                        `${currentAttr&&currentAttr.cv_delay?currentAttr.cv_delay: ''}`}
                    onChange={(value)=> handleFieldChange('cv_delay', value)}
                    limitNumber={60}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='cv_per'>{trans('CV area', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_per ? dataProject.cv_per : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.cv_per?currentAttr.cv_per: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'number'}
                    name={'cv_per'}
                    value={dataProject ?
                        `${dataProject.cv_per ? dataProject.cv_per : ''}` :
                        `${currentAttr&&currentAttr.cv_per?currentAttr.cv_per: ''}`}
                    onChange={(value)=> handleFieldChange('cv_per', value)}
                    limitNumber={100}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='cv_psc'>{trans('CV psc', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_psc ? dataProject.cv_psc : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.cv_psc?currentAttr.cv_psc: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'number'}
                    name={'cv_psc'}
                    value={dataProject ?
                        `${dataProject.cv_psc ? dataProject.cv_psc : ''}` :
                        `${currentAttr&&currentAttr.cv_psc?currentAttr.cv_psc: ''}`}
                    onChange={(value)=> handleFieldChange('cv_psc', value)}
                    limitNumber={100}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='cv_pcsv'>{trans('CV pcsv', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_pcsv ? dataProject.cv_pcsv : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.cv_pcsv?currentAttr.cv_pcsv: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'number'}
                    name={'cv_pcsv'}
                    value={dataProject ?
                        `${dataProject.cv_pcsv ? dataProject.cv_pcsv : ''}` :
                        `${currentAttr&&currentAttr.cv_pcsv?currentAttr.cv_pcsv: ''}`}
                    onChange={(value)=> handleFieldChange('cv_pcsv', value)}
                    limitNumber={100}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='cv_cht'>{trans('CV cht', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_cht ? dataProject.cv_cht : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.cv_cht?currentAttr.cv_cht: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'number'}
                    name={'cv_cht'}
                    value={dataProject ?
                        `${dataProject.cv_cht ? dataProject.cv_cht : ''}` :
                        `${currentAttr&&currentAttr.cv_cht?currentAttr.cv_cht: ''}`}
                    onChange={(value)=> handleFieldChange('cv_cht', value)}
                    limitNumber={60}
                />
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='cv_ums'>{trans('CV ums', language)}</label>
                  <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_ums ? dataProject.cv_ums : 'Не установлено'}` :
                        `${currentAttr&&currentAttr.cv_ums?currentAttr.cv_ums: ''}`}
                  </span>
                </div>
                <ChangeByBtn
                    classNameBtn={cls.hide_button}
                    classNameInput={`${cls.show_input} setting-attributions-item-change__input`}
                    classNameSubmit={`${cls.btn_ok} setting-attributions-item-change__submit`}
                    format={'input'}
                    type={'number'}
                    name={'cv_ums'}
                    value={dataProject ?
                        `${dataProject.cv_ums ? dataProject.cv_ums : ''}` :
                        `${currentAttr&&currentAttr.cv_ums?currentAttr.cv_ums: ''}`}
                    onChange={(value)=> handleFieldChange('cv_ums', value)}
                    limitNumber={60}
                />
              </div>
            </div>
          </Accordion>
          <Accordion title={trans('Black list', language)} className={'setting-attributions__accordion'}>
            <div className={cls.block}>
              <div className={cls.wrapper}>
                <label htmlFor='black_list_domain'>{trans('Domains', language)}</label>
                <UploadFile
                    text={trans('Upload your blacklist of domains', language)}
                    uploadedFilename={dataProject && (dataProject['black_list_domain'] || dataProject['black_list_domain_url']) ? 'domains.txt' : undefined}
                    fileType={'txt'}
                    onChange={(data: any) => {
                      if (data && data.file) {
                        handleFieldChange('black_list_domain', data.file)
                      }
                    }}
                    onRemove={() => {
                      handleFieldChange('black_list_domain', undefined);
                      handleFieldChange('black_list_domain_url', undefined);
                    }}
                    isInvalid={false}
                />
                {dataProject && dataProject['black_list_domain_url'] &&
                    <a className='upload-content__link' href={dataProject['black_list_domain_url']}
                       target={'_blank'} rel={'noreferrer'}>{trans('Open the uploaded file', language)}</a>
                }
              </div>
            </div>
          </Accordion>


        </div>
        <div className={`setting-attributions__bottom`}>
          <button className={`setting-attributions__submit ${!exitCounter ? 'disabled' : ''}`} onClick={onSubmit}>
            {trans('Save changes', language)}
          </button>
        </div>

      </div>
      </>
    )
  } else {
    return (
        <div className={cls.content}>
          <div className={cls.form}>
            <div className={cls.block}>
              <div className={cls.title}>{trans('Attribution', language)}</div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_window'>{trans('Default attribution window', language)}</label>
                  <span
                      className={cls.data}>{dataProject ? dataProject.attribution_window : currentAttr?.attribution_window} {trans('days', language)}</span>
                </div>
              </div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default attribution model', language)}</label>
                  <span className={cls.data}>{dataProject && dataProject.attribution_model ?
                      (models as any)[`${dataProject.attribution_model}`] : currentAttr && currentAttr.attribution_model &&
                      (models as any)[`${currentAttr?.attribution_model}`]}</span>
                </div>
              </div>
            </div>
            <div className={cls.block}>
              <div className={cls.title}>{trans('Reports', language)}</div>
              <div className={cls.input_entity}>
                <div className={cls.info_wrapper}>
                  <label htmlFor='project_name'>{trans('Default relative time interval', language)}</label>
                  <span className={cls.data}>{dataProject ?
                      (reportPeriodList as any)[`${dataProject.attribution_period}`] : currentAttr && currentAttr.attribution_period &&
                      (reportPeriodList as any)[`${currentAttr?.attribution_period}`]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
  // }

};

export default Attributions;